import React, { useState } from 'react';
import { Images } from '../config';
import { QTADT } from './index';
import {isMobile} from 'react-device-detect';

const QT01 = props => {
    return (
        <>
            <div className="mapPointer" onClick={()=>{ if(isMobile){ window.scroll(0,0)}}}>
                <img src={Images.largepin} alt="" className="" />
                <h4 className="title">Tap on the map to drop a pin</h4>
                <p className="desc">Don’t worry about being precise just the general area!</p>
            </div>

            <QTADT adtEnabled={props.data.questionAdditionalThoughtsEnabled} questionId={props.data.questionId} onSubmit={(res) => {
                if (res.length !== '') {
                    props.onSubmit({ "additional_thoughts": res });

                }
            }} additional_thoughts={props.data.hasOwnProperty('answer') ? props.data.answer.additional_thoughts : null}/>
        </>);
};

export default QT01;