import React, { useState, useEffect } from "react";
import _ from "lodash";
import { QTADT } from "./index";
import { Images } from "../config";

const QT05 = (props) => {
  let list = [];
  const [answers, setAnswers] = useState("");
  const [adtData, setAdtData] = useState("");
  let addition_thought = "";

  useEffect(() => {
    if (answers !== "") {
      props.onSubmit({
        answer: answers,
        questionId: props.data.questionId,
        additional_thoughts: adtData,
      });
    } else {
      // if(props.data.hasOwnProperty('answer')){
      //     props.onSubmit({ "answer": props.data.answer.answer, "questionId": props.data.answer.questionId, "additional_thoughts": props.data.answer.additional_thoughts });
      // }
    }
  }, [answers, adtData]);

  _.each(props.data.options, (item) => {
    // if(item.questionId === props.answer.questionId){
    //     addition_thought = props.answer.additional_thoughts
    // }

    list.push(
      <div key={item.optionId} className="chk-image">
        <label>
          <div className="imageControl">
            <input
              type="radio"
              name={"radio_" + props.data.questionId}
              value={item.optionId}
              className="mr-2"
              onChange={() => {
                setAnswers(item.optionId);
              }}
              checked={
                props.data.hasOwnProperty("answer")
                  ? props.data.answer.answer === item.optionId
                  : false
              }
            />
            <div
              style={{
                backgroundImage: `url(${item.option})`,
              }}
              className="checkmark"
            >
              {/* <img src={Images.tspacer} alt="" style={{ width: "100%" }} /> */}
              {item.optionCaption !== "" && (
                <p className="imageText">{item.optionCaption}</p>
              )}
            </div>
          </div>
        </label>
      </div>
    );
  });

  return (
    <>
      <div className=" pt-4 imageQuestion">{list}</div>
      <div>
        <QTADT
          adtEnabled={props.data.questionAdditionalThoughtsEnabled}
          questionId={props.data.questionId}
          onSubmit={(res) => {
            setAdtData(res);
          }}
          additional_thoughts={
            props.data.hasOwnProperty("answer")
              ? props.data.answer.additional_thoughts
              : ""
          }
        />
      </div>
    </>
  );
};

export default QT05;
