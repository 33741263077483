import React from 'react';
import { ListWrapper, ShareVia } from '.'
import { Images } from '../config';

const SupportResultLayout = (props) => {
    return (<ListWrapper>
        <div className="p-4 m-2 listing">
            <div className="d-flex share-via-wrap-mobile">
                <div className="flex-grow-1">
                    <h3 className="title">{props.title}</h3>
                </div>
                {props.isShare &&
                    <ShareVia
                    url={window.location.origin + '/support-survey'}
                        title={"Share Your Views…"}
                        white={false}
                    />
                }
            </div>
            {props.children}
        </div>

        <div className="d-flex px-4 mx-2 py-4 my-2 listing doyou-support-display">
            <div className="flex-grow-1 d-flex">
                <div className="pr-5 pt-1">
                    {props.callToActionTip && 
                        <p className="mb-0 font-weight-bold d-flex justify-content-center ">
                            <img src={Images.greensurveyicon} alt="" className="pr-3 " />
                            {props.callToActionTip}
                        </p> 
                        }
                </div>
            </div>
            <div className="start-now-button">
                <button
                    className="btn btn-outline-primary btn-lg start-now d-flex justify-content-between align-items-center p-3 px-4 greenchevron"
                    onClick={() => props.callToAction()}
                >
                    {props.callToActionTitle}
                    <span className="icon"></span>
                </button>   
            </div>
        </div>
    </ListWrapper>)
}

export default SupportResultLayout;