import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import Lightbox from "lightbox-react";
import { isFirefox, isMobile } from 'react-device-detect';

// redux
import {
    archives_action,
    reset_archives_action,
} from "../../stores/actions";

import {
    Filters,
    ListWrapper,
    ShareVia,
    Pagination,
    Animi,
    Loader,
    ReactangleLoaderView,
} from "../../components";
import { daysAgo } from "../../utils/utility";
import { Images, StringConstants } from "../../config";
import CommonFooter from "../Legals/CommonFooter";

const Archive = (props) => {
    const dispatch = useDispatch();

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);

    const [filters, setFilters] = useState({
        q: "",
        catId: "",
        page: 1,
    });

    const archiveData = useSelector((state) => state.archives);
    const projectConfigData = useSelector((state) => state.projectconfig);

    useEffect(() => {
        window.scroll(0, 0);
        try {
            if ((archiveData.isFetching === false) & (archiveData.data === null)) {
                dispatch(archives_action(filters));
            }
        } catch (e) {
            console.log("archives error", e);
        }
    }, []);

    useEffect(() => {
        if (projectConfigData.data !== null) {
            setCategoriesData(projectConfigData.data.data.categories.archive);
        }
    }, [projectConfigData]);
    const videoFrame = (item, id) => {
        let width = window.innerWidth < 480 ? window.innerWidth - 10 : 640;
        let height = width / 1.5;

        if (photoIndex !== id) {
            return <div></div>
        }


        return (
            <div
                className="videoFrameWpr"
            >
                <div style={{
                    marginLeft: `-${width / 2}px`,
                    marginTop: `-${height / 2}px`,
                }}
                    className="videoFrame"
                    title="videoFrame">
                    <video
                        width={width}
                        height={height}
                        controls
                    >
                        <source src={item} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        );
    };

    const __renderPDFFrame = (item) => {
        
        let height = window.innerHeight - (isMobile ? 100 : 60);
        let width = window.innerHeight / (isMobile ? 2.4 : 1.4);



        let noPDFSupport = 300;
        if (isFirefox && !navigator.pdfViewerEnabled) {
            return <div
                className="videoFrame"
                style={{
                    background: "#fff",
                    width: noPDFSupport + "px",
                    height: noPDFSupport + "px",
                    marginLeft: `-${noPDFSupport / 2}px`,
                    marginTop: `-${noPDFSupport / 2}px`,
                }}><p>Opps, unfortunatly we are not able to open PDF on your browser, please <a href={item} target="_blank" rel="noopener">click here</a> to download PDF.</p></div>
        }
        
        return (<iframe
            width={width}
            height={height}
            src={`https://docs.google.com/viewer?url=${item}&embedded=true`}
            className="videoFrame"
            title="videoFrame"
            style={{
                marginLeft: `-${width / 2}px`,
                marginTop: `-${height / 2}px`,
            }}
        />)
    }


    const pdfFrame = (item, id) => {
        return (<div
            className="videoFrameWpr"
            onClick={() => {
                setIsOpen(!isOpen);
            }}>
            {__renderPDFFrame(item)}
        </div>);
    };
    const __renderGallery = () => {
        let imageDt = [];
        if (archiveData.data === null) {
            return;
        }

        _.each(archiveData.data.archives, (item, id) => {
            if (item.archiveMediaFileType === "Image") {
                imageDt.push(item.archiveMediaFile);
            } else if (item.archiveMediaFileType === "Video") {
                imageDt.push(videoFrame(item.archiveMediaFile, id));
            } else if (item.archiveMediaFileType === "PDF") {
                imageDt.push(pdfFrame(item.archiveMediaFile, id));
            }
        });

        return (
            <>
                {isOpen && (
                    <Lightbox
                        mainSrc={imageDt[photoIndex]}
                        nextSrc={imageDt[(photoIndex + 1) % imageDt.length]}
                        prevSrc={
                            imageDt[(photoIndex + imageDt.length - 1) % imageDt.length]
                        }
                        onCloseRequest={() => setIsOpen(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + imageDt.length - 1) % imageDt.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % imageDt.length)
                        }
                        reactModalStyle={{
                            zIndex: 1100,
                        }}
                    />
                )}
            </>
        );
    };

    const __listItem = () => {
        if (archiveData.data === null) {
            return;
        }

        if (archiveData.data.archives.length === 0) {
            // return <NoResults noDocument={true} />;
            return (StringConstants.no_document)
        }

        let list = [];
        _.each(archiveData.data.archives, (item, id) => {

            list.push(
                <Animi.FadeInAnimationSecDiv delay={(id + 1) * 100} key={id}>
                    <ListWrapper>
                        <div className="listing">
                            <div className="p-4 archive-padding-main-sec">
                                <div className="d-flex flex-mobile-column">
                                    <div className="flex-grow-1">
                                        <button
                                            className="btn p-0"
                                            onClick={() => {
                                                setPhotoIndex(id);
                                                setIsOpen(true);
                                            }}
                                        ><h4 className="mb-1 title">{item.archiveTitle}</h4>
                                        </button>
                                    </div>
                                    <ShareVia
                                        url={window.location.href}
                                        title={item.archiveTitle}
                                    />
                                </div>
                                <p>
                                    <b>{item.archiveMediaFileType}</b>
                                </p>
                                <p>{item.archiveDescription}</p>
                            </div>
                            <div
                                className="d-flex flex-mobile-column"
                                style={{ borderTop: "1px solid #DFDFDF" }}
                            >
                                <div className="flex-grow-1 pl-4 pt-1 flex-mobile-column d-flex archive-padding">
                                    <div className="pr-5 pt-1">
                                        <p className="mb-0 bold d-flex mob-left justify-content-center">
                                            <img src={Images.calendar} alt="" className="pr-3 " />{" "}
                                            {StringConstants.posted}{" "}
                                            {daysAgo(item.archivePublishDate)}
                                        </p>
                                    </div>
                                    <div className="pr-4 pt-1">
                                        <p className="mb-0 bold d-flex  mob-left justify-content-center">
                                            <img src={Images.info} alt="" className="pr-3 " />
                                            {item.archiveMediaFileSize}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-primary pt-2 pb-2 pr-4 pl-4 mr-1 view-mobie"
                                        onClick={(e) => {
                                            // if (item.archiveMediaFileType === "PDF") {
                                            //     window.open(item.archiveMediaFile, "_blank");
                                            // } else {
                                                setPhotoIndex(id);
                                                setIsOpen(true);
                                            // }
                                        }}
                                    >
                                        View&nbsp;
                                        <img src={Images.whitechevron} alt="" className="pl-3" />
                                    </button>

                                    <button
                                        className="btn btn-primary pt-2 pb-2 pr-4 pl-4 download-mobie"
                                        onClick={(e) => window.open(item.archiveDownloadMediaFile)}
                                    >{StringConstants.download} &nbsp;
                                        <img src={Images.whitechevron} alt="" className="pl-3" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ListWrapper>
                </Animi.FadeInAnimationSecDiv>
            );
        });

        return list;
    };

    const __pagination = () => {
        if (archiveData.data === null) {
            return;
        }

        return (
            <Pagination
                data={archiveData.data.archives_pagination}
                onSubmit={(res) => {
                    __applyPage(res);
                }}
            />
        );
    };

    const __applyPage = (res) => {
        let filterTp = JSON.parse(JSON.stringify(filters));
        filterTp.page = res;

        setFilters(filterTp);

        dispatch(reset_archives_action());
        setTimeout(() => {
            dispatch(archives_action(filterTp));
        }, 100);
    };

    const __renderProposalLoader = (val) => {
        let list = [];
        let list_all = [1, 2, 3, 4, 5];
        _.each(list_all, (item, id) => {
            list.push(
                <div className="pt-3 pb-2 project-config loading-div loading-div-praposal" key={id}>
                    <div style={{ boxShadow: "0 2px 64px 0 rgba(0, 0, 0, 0.1)" }}>
                        <div className="main-div" style={{ boxShadow: "none" }}>
                            <div className="second-div width-70" style={{ paddingLeft: 0 }}>
                                <Loader type={"view"} row={1} />
                            </div>
                            <div className="first-div-new">
                                <Loader type={"view"} row={1} />
                            </div>
                        </div>
                        <div className="archiv-div-main">
                            <div>
                                <Loader type={"text"} row={1} />
                            </div>
                        </div>
                        <div className="archiv-div">
                            <div className="f11">
                                <ReactangleLoaderView
                                    imageheight={16}
                                    imagewidth={20}
                                />
                                <div style={{ width: "100%" }}>
                                    <Loader type={"view"} row={1} />
                                </div>
                            </div>
                            <div className="f12" style={{ marginTop: 4 }}>
                                <ReactangleLoaderView
                                    imageheight={16}
                                    imagewidth={20}
                                />
                                <div style={{ width: "100%" }}>
                                    <Loader type={"view"} row={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return list;
    };

    return (
        <>
            <div className="container container-top">
                <Filters
                    categories={categoriesData}
                    //placeholder="Search Engagements"
                    placeholder="Search Archive"
                    onSubmit={(res) => {
                        let filterTp = JSON.parse(JSON.stringify(filters));
                        filterTp.q = res.q;
                        filterTp.catId = res.id;

                        setFilters(filterTp);

                        dispatch(reset_archives_action());
                        setTimeout(() => {
                            dispatch(archives_action(filterTp));
                        }, 100);
                    }}
                />

                <div>
                    {!archiveData.isSuccess ? __renderProposalLoader() : __listItem()}

                    {/* {__listItem()} */}
                    {__pagination()}
                </div>
                {__renderGallery()}
            </div>

            <div className="text-center postion-center ">
                <CommonFooter />
            </div>
        </>
    );
};

export default Archive;
