import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// redux
import {
  ListWrapper,
  ShareVia,
  SupportEmailModal,
  SupportResultLayout,
} from "../../components";
import { Images, StringConstants, Config } from "../../config";
import CommonFooter from "../Legals/CommonFooter";

const SupportSurveyThankyou = (props) => {
  let history = useHistory();
  const data = props.location.state;
  const token = props.location.state.token;
  const result = props.location.state.result;
  const resultId = "";
  const slug = props.match.params.proposalId;

  const [support, setSupport] = useState(0);
  const [CTAText, setCTAText] = useState();
  const [CTASubText, setCTASubText] = useState();
  const [CTATip, setCTATip] = useState();
  const [toggleEmailModal, setToggleEmailModal] = useState(false);
  const [supportEmail, setSupportEmail] = useState("");
  const [callbackEmail, setCallbackEmail] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [isEmailTemplateCopied, setIsEmailTemplateCopied] = useState(false);
  const [isNotWorking, setIsNotworking] = useState(false);
  const [isEmailBodyTruncate, setIsEmailBodyTruncate] = useState(true);
  const projectConfigData = useSelector((state) => state.projectconfig);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (projectConfigData.data !== null) {
      let data = projectConfigData.data.data.projectConfig;
      setSupportEmail(data.projectSupportEmail);
      setCallbackEmail(data.projectRequestCallbackEmail);
      setEmailSubject(
        projectConfigData.data.data?.support_mobilisation?.emailSubject
      );

      if (data?.projectDevelopmentSupportModuleThreshold) {
        let min = parseFloat(
          data?.projectDevelopmentSupportModuleThreshold.min
        );
        let max = parseFloat(
          data?.projectDevelopmentSupportModuleThreshold.max
        );

        if (result <= min) {
          setSupport(1);
        } else if (result > min && result <= max) {
          setSupport(2);
        } else if (result > max) {
          setSupport(3);
        }
      } else {
        if (result <= 40) {
          setSupport(1);
        } else if (result > 40 && result <= 60) {
          setSupport(2);
        } else if (result > 60) {
          setSupport(3);
        }
      }
    }
  }, [projectConfigData]);

  const __toggleEmailModal = () => {
    setToggleEmailModal(!toggleEmailModal);
  };

  const __afterTemplateCopy = () => {
    setIsEmailTemplateCopied(!isEmailTemplateCopied);
  };

  const __renderEmailModel = () => {
    return (
      <SupportEmailModal
        isShow={toggleEmailModal}
        onModalClose={() => __toggleEmailModal()}
        emailTemplate={data.emailTemplate}
        isEmailTemplateCopied={isEmailTemplateCopied}
        afterTemplateCopy={() => __afterTemplateCopy()}
        isEmailBodyTruncate={isEmailBodyTruncate}
        onEmailBodyTruncate={() => setIsEmailBodyTruncate(!isEmailBodyTruncate)}
        supportEmail={supportEmail}
        callbackEmail={callbackEmail}
        emailSubject={emailSubject}
        isNotWorking={isNotWorking}
        onIsNotWorking={() => setIsNotworking(!isNotWorking)}
      />
    );
  };

  const neutralThankyou = () => {
    return (
      <SupportResultLayout
        title={StringConstants.thankyou_text}
        isShare={true}
        callToActionTitle={StringConstants.request_call_back}
        callToAction={() => {
          window.open(
            `mailto:${supportEmail}?subject=Request a call back &body=Hello!%0D%0A 
            %0D%0AI would like to request a call back from a member of the project team.%0D%0A
            %0D%0AYou can contact me on:%0D%0A 
            %0D%0A(please insert your contact details here)%0D%0A
            %0D%0AThank you!`
          );
        }}
        callToActionTip={StringConstants.mini_thankyou_text}
      >
        <>
          <p className="mb-2">{StringConstants.thankyou_subtext}</p>
          <p className="mb-2">{StringConstants.survay_summary_thankyou_text}</p>
        </>
      </SupportResultLayout>
    );
  };

  const neutralLetterAssist = () => {
    return (
      <SupportResultLayout
        title={StringConstants.letterassist_text}
        isShare={false}
        callToActionTitle={StringConstants.email_your_opinion}
        callToAction={() => {
          window.open(
            `mailto:${callbackEmail}?&subject=${emailSubject} &body=Dear Sir / Madam,%0D%0A             
            %0D%0AI am writing regarding the aforementioned project.%0D%0A %0D%0A %0D%0A %0D%0A
            %0D%0AKind Regards,%0D%0A`
          );
        }}
        callToActionTip={""}
      >
        <p className="mb-2">{StringConstants.letterassist_subtext}</p>
      </SupportResultLayout>
    );
  };

  const negativeThankyou = () => {
    return (
      <SupportResultLayout
        title={StringConstants.thankyounegative_text}
        isShare={true}
        callToActionTitle={StringConstants.request_call_back}
        callToAction={() => {
          window.open(
            `mailto:${supportEmail}?subject=Request a call back &body=Hello!%0D%0A 
            %0D%0AI would like to request a call back from a member of the project team.%0D%0A 
            %0D%0AYou can contact me on:%0D%0A 
            %0D%0A(please insert your contact details here)%0D%0A 
            %0D%0AThank you!%0D%0A`
          );
        }}
        callToActionTip={StringConstants.negative_thankyou_text}
      >
        <>
          <p className="mb-2">{StringConstants.thankyounegative_subtext}</p>
          <p className="mb-2">
            {StringConstants.negative_summary_thankyou_text}
          </p>
        </>
      </SupportResultLayout>
    );
  };

  const negativeLetterAssist = () => {
    return (
      <SupportResultLayout
        title={StringConstants.letterassistnegative_text}
        isShare={false}
        callToActionTitle={StringConstants.email_your_opinion}
        callToAction={() => {
          window.open(
            `mailto:${callbackEmail}?bcc=${supportEmail}&subject=${emailSubject} &body=Dear Sir / Madam,%0D%0A             
            %0D%0AI am writing regarding the aforementioned project.%0D%0A %0D%0A %0D%0A %0D%0A 
            %0D%0AKind Regards,`
          );
        }}
        callToActionTip={""}
      >
        <p className="mb-0">{StringConstants.letterassistnegative_subtext}</p>
      </SupportResultLayout>
    );
  };

  const positiveLetterAssist = () => {
    return (
      <SupportResultLayout
        title={StringConstants.letterassistpositive_text}
        isShare={true}
        callToActionTitle={StringConstants.email_your_support}
        callToAction={() => __toggleEmailModal()}
        callToActionTip={StringConstants.letterassistpositive_summary2}
      >
        <>
          <p className="mb-2">{StringConstants.letterassistpositive_subtext}</p>
          <p className="mb-2">{StringConstants.letterassistpositive_summary}</p>
        </>
      </SupportResultLayout>
    );
  };

  const positiveRequestCallback = () => {
    return (
      <SupportResultLayout
        title={StringConstants.thankyounegative_text}
        isShare={false}
        callToActionTitle={StringConstants.request_call_back}
        callToAction={() => {
          window.open(
            `mailto:${supportEmail}?subject=Request a call back &body=Hello!%0D%0A
            %0D%0AI would like to request a call back from a member of the project team.%0D%0A 
            %0D%0AYou can contact me on:%0D%0A 
            %0D%0A(please insert your contact details here)%0D%0A 
            %0D%0AThank you!`
          );
        }}
        callToActionTip={""}
      >
        <>
          <p className="mb-2">{StringConstants.request_callback_sub}</p>
          <p className="mb-2">
            {StringConstants.positive_summary_thankyou_text}
          </p>
        </>
      </SupportResultLayout>
    );
  };

  const __renderResult = () => {
    if (support === 1) {
      return (
        <>
          {negativeThankyou()}
          {negativeLetterAssist()}
        </>
      );
    } else if (support === 2) {
      return (
        <>
          {neutralThankyou()}
          {neutralLetterAssist()}
        </>
      );
    } else if (support === 3) {
      return (
        <>
          {positiveLetterAssist()}
          {positiveRequestCallback()}
        </>
      );
    }
  };

  return (
    <>
      <div className="container p-5 pt-4 padding-mobile">
        {__renderResult()}

        {resultId && (
          <ListWrapper>
            <div className="p-4 m-2 mb-0 listing">
              <h3 className="title">{StringConstants.what_community_said}</h3>
              <p className="mb-0">{StringConstants.survay_final_result_text}</p>
            </div>

            <div className="d-flex" style={{ borderTop: "1px solid #DFDFDF" }}>
              <div className="flex-grow-1 pl-4 pt-1 d-flex">
                <div className="pr-5 pt-2">
                  <p className="mb-0 bold d-flex justify-content-center">
                    <img src={Images.info} alt="" className="pr-3 " />
                    {StringConstants.want_to_see_full_filled_survey}
                  </p>
                </div>
              </div>

              <div>
                <Link
                  className="btn btn-primary py-3 px-4"
                  to={{
                    pathname: "/support-summary/",
                    state: { slug: slug, resultId: resultId, token: token },
                  }}
                >
                  {StringConstants.view_results}
                  <img src={Images.whitechevron} alt="" className="pl-5" />
                </Link>
              </div>
            </div>
          </ListWrapper>
        )}
      </div>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
      {toggleEmailModal && __renderEmailModel()}
    </>
  );
};

export default SupportSurveyThankyou;
