import React from "react";
import { Link } from "react-router-dom";
import { ListWrapper, Animi } from "../components";
import { Images, StringConstants,NavigationConstants } from "../config";
import { daysToGo } from "../utils/utility";
import moment from "moment";
import { isMobile } from "react-device-detect";

const ProposalListing = (props) => {
  const item = props.details;
  const type = props.type;
  const backToPageTitle = props.backToPageTitle;

  let proposalImageClass = "";

  if (type === "large") {
    proposalImageClass = "proposal-img-large";
  } else if (type === "small") {
    proposalImageClass = "proposal-img-small";
  } else if (type === "landing") {
    proposalImageClass = "proposal-img-landing";
  }

  let Icon = Images.whitesurveyicon;

  if (item.proposalType === "map") {
    Icon = Images.whitemapsurvey;
  } else if (item.proposalType === "interactive") {
    Icon = Images.whiteimagesurvey;
  } else {
    Icon = Images.whitesurveyicon;
  }

  const __renderInfo = () => {
    return (
      <div
        className={
          type === "small" ? "d-flex flex-column" : "d-lg-flex listing"
        }
        style={{ borderTop: "1px solid #DFDFDF" }}
      >
        <div className="flex-grow-1 pl-4 pt-1 pb-2 d-flex">
          {item.proposalAlwaysLive === 0 ? (
            <div className="pr-5 pt-1">
              <p className="mb-0 bold d-flex justify-content-center">
                <img src={Images.clock} alt="" className="pr-3 " />{" "}
                {daysToGo(item.proposalDeadline)}{" "}
              </p>
            </div>
          ) : null}

          {item.proposalTotalResponses > 5 && (
            <div className="pr-4 pt-1">
              <p className="mb-0 bold d-flex justify-content-center">
                <img src={Images.response} alt="" className="pr-3 " />
                {item.proposalTotalResponses} responses
              </p>
            </div>
          )}
        </div>
        <div>
          <button className="btn btn-primary btn-block py-2 px-5 d-flex justify-content-between praposal-hsb-mobile align-items-center">
            {StringConstants.have_your_say}{" "}
            <img
              src={Images.whitechevron}
              alt=""
              className={type !== "small" ? "pl-4" : ""}
            />
          </button>
        </div>
      </div>
    );
  };

  return (
    <ListWrapper>
      {/* todo: needs to be changed to button tag */}
      <Link
        to={{
          pathname: NavigationConstants.share_your_views.url+"/" + item.proposalSlug,
          state: { proposalDetail: item, backToPageTitle: backToPageTitle },
        }}
        className="listingLink"
        onClick={() => {
          window.scroll(0, 0);
        }}
      >
        <div className="d-md-flex listing card-listingHeight">
          <div>
            <div className="mt-3 ml-3 survey-icon">
              <img src={Icon} alt="" />
            </div>
            <Animi.ZoomInOutAnimationImage>
              <div
                className={proposalImageClass}
                style={{ backgroundImage: `url(${item.proposalImage})` }}
              />
            </Animi.ZoomInOutAnimationImage>
          </div>
          <div className="flex-grow-1 d-flex flex-column">
            <div className="p-4 flex-grow-1">
              <div className="new-content  flex-grow-1">
                <h4 className="title mb-0">{item.proposalTitle}</h4>
                <p className="mb-1">
                  <span className="bold pr-2 capitalize">
                    {StringConstants.survey}
                  </span>
                  {item.proposalCategoryName === "Events" && " | "}
                  <span className="bold pl-2">
                    {item.proposalCategoryName === "Events" &&
                      moment(item.proposalPublishDate).format("DD MMM YYYY")}
                  </span>
                </p>
                <p className="mb-0">
                  {isMobile
                    ? item.proposalDesc.substring(0, 120)
                    : item.proposalDesc}
                </p>
              </div>
            </div>
            {type !== "landing" && __renderInfo()}
          </div>
        </div>
        {type === "landing" && __renderInfo()}
      </Link>
    </ListWrapper>
  );
};

export default ProposalListing;
