import React from 'react';
import { useHistory } from 'react-router-dom';
import { Images } from '../config';

const BackButton = (props) => {
    const history = useHistory();
    return (
        <button className="btn btn-default text-primary back-button-gen p-2 pl-4 pr-3 bold" onClick={() => { history.goBack() }}><img src={Images.paginationback} alt="" className="mr-3 pb-1" />Back</button>
    )
}

export default BackButton;
