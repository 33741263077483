import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function archives_action(filters) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/archive/all?category_id=${filters.catId}&q=${filters.q}&page=${filters.page}`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.ARCHIVES_REQUEST,
                types.ARCHIVES_RECEIVE, {
                    type: types.ARCHIVES_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reset_archives_action = () => {
    return {
        type: types.RESET_ARCHIVES,
        payload: null
    }
};


export function archives_categories_action() {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/archive/categories`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.ARCHIVES_CAT_REQUEST,
                types.ARCHIVES_CAT_RECEIVE, {
                    type: types.ARCHIVES_CAT_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reset_archives_categories_Action = () => {
    return {
        type: types.RESET_ARCHIVES_CAT,
        payload: null
    }
};
