import React, { useEffect, useState } from 'react';

import { Link, useHistory } from "react-router-dom";
import CommonFooter from '../../screens/Legals/CommonFooter'

// redux
import { ListWrapper, ShareVia } from '../../components';
import { Images, StringConstants, Config ,NavigationConstants} from '../../config'



const SurveySummary = (props) => {
    let history = useHistory();

    const data = props.location.state.data;
    const baseProposal = props.location.state.baseProposal;
    const token = props.location.state.token;
    const resultId = data.proposalResultId;
    const slug = props.match.params.proposalId;
    const support = props.location.state.supportAnswer;

    const [ThankYouText, setThankYouText] = useState();
    const [ThankYouSubText, setThankYouSubText] = useState();
    const [CTAText, setCTAText] = useState();
    const [CTASubText, setCTASubText] = useState();
    const [CTATip, setCTATip] = useState();

    useEffect(() => { 
        if (support === 1) {
            setThankYouText(`${StringConstants.thankyou_text}`);
            setThankYouSubText(`${StringConstants.thankyou_subtext}`);
            setCTAText(`${StringConstants.thankyou_cta_text}`);
            setCTASubText(`${StringConstants.thankyou_cta_subtext}`);
            setCTATip(`${StringConstants.thankyou_cta_tips}`);
        } else if (support === 2) {
            setThankYouText(`${StringConstants.thankyou_text}`);
            setThankYouSubText(`${StringConstants.thankyou_subtext2}`);
            setCTAText(`${StringConstants.thankyou_cta_text2}`);
            setCTASubText(`${StringConstants.thankyou_cta_subtext2}`);
            setCTATip(`${StringConstants.thankyou_cta_tips}`);
        } else if (support === 3) {
            setThankYouText(`${StringConstants.thankyou_text3}`);
            setThankYouSubText(`${StringConstants.thankyou_subtext3}`);
            setCTAText(`${StringConstants.thankyou_cta_text3}`);
            setCTASubText(`${StringConstants.thankyou_cta_subtext3}`);
            setCTATip(`${StringConstants.thankyou_tips3}`);
        } else {
            setThankYouText(`${StringConstants.thankyou_text}`);
            setThankYouSubText(`${StringConstants.thankyou_subtext2}`);
            setCTAText(`${StringConstants.thankyou_cta_text3}`);
            setCTASubText(`${StringConstants.thankyou_cta_subtext3}`);
            setCTATip(`${StringConstants.thankyou_tips3}`);
        }
    }, [])

    const __NeedLittleGuidance = () => {
        return (<ListWrapper>
            <div className="p-4 m-2 listing">
                <h3 className="title">{StringConstants.need_little_guidance}</h3>
                <p className="font-weight-bold">{StringConstants.little_assitance}</p>
                <p className="mb-0">{StringConstants.your_survay_decs}</p>
            </div>
            <div className="view-result-display">
                <Link className="btn btn-primary btn-block py-3 px-5 d-flex justify-content-between align-items-center"
                    to={{
                        pathname: NavigationConstants.share_your_views.url + "/",
                    }}>{StringConstants.have_your_say} <img src={Images.whitechevron} alt="" className={"pl-4"} />
                </Link>
            </div>
        </ListWrapper>
        )
    }

    const __NotGoingToChangeMind = () => {
        return (
            <ListWrapper>
                <div className="p-4 m-2 listing">
                    <h3 className="title">{StringConstants.not_going_change_mind_heading}</h3>
                    <p className="font-weight-bold">{StringConstants.not_going_change_mind_decs1}</p>
                    <p className="mb-0">{StringConstants.not_going_change_mind_decs2}</p>
                </div>
                <div>
                    <button className="btn btn-primary btn-block py-3 px-5 d-flex justify-content-between align-items-center"
                        onClick={() => {
                            window.open(`mailto:${Config.support_email}`)
                        }}>{StringConstants.email_your_opinion} <img src={Images.whitechevron} alt="" className={"pl-4"} />
                    </button>
                </div>
            </ListWrapper>
        )
    }

    const __WantToHaveChatAboutIt = () => {
        return (
            <ListWrapper>
                <div className="p-4 m-2 listing">
                    <h3 className="title">{StringConstants.want_to_have_chat_about_heading}</h3>
                    <p className="font-weight-bold">{StringConstants.want_to_have_chat_about_decs1}</p>
                    <p className="mb-0">{StringConstants.want_to_have_chat_about_decs2}</p>
                </div>
                <div className="request-callback">
                    <button className="btn btn-primary btn-block py-3 px-5 d-flex justify-content-between pad align-items-center"
                        onClick={() => {
                            window.open(`mailto:${Config.support_email}`)
                        }}>{StringConstants.request_call_back}  <img src={Images.whitechevron} alt="" className={"pl-4"} />
                    </button>
                </div>
            </ListWrapper>
        )
    }

    const __GetYourOpinionHeard = () => {
        return (
            <ListWrapper>
                <div className="p-4 m-2 listing">
                    <h3 className="title"> {StringConstants.get_your_opinion_heard_heading} </h3>
                    <p className="font-weight-bold">{StringConstants.not_going_change_mind_decs1}</p>
                    <p className="mb-0">{StringConstants.not_going_change_mind_decs2}</p>
                </div>
                <div>
                    <button className="btn btn-primary btn-block py-3 px-5 d-flex justify-content-between align-items-center"
                        onClick={() => {
                            window.open(`mailto:${Config.support_email}`)
                        }}>{StringConstants.email_your_support} <img src={Images.whitechevron} alt="" className={"pl-4"} />
                    </button>
                </div>
            </ListWrapper>
        )
    }

    return (
        <>
            <div className="container p-5 pt-4 padding-mobile">
                <ListWrapper>
                    <div className="p-4 m-2 listing">
                        <div className="d-flex flex-mobile-column">
                            <div className="flex-grow-1">
                                <h3 className="title">{ThankYouText}</h3>
                            </div>
                            {slug && <ShareVia url={window.location.origin + '/surveys/' + slug} title={baseProposal.proposal.proposalTitle} white={false} /> }
                        </div>

                        <p className="font-weight-bold">{ThankYouSubText}</p>
                        <p className="mb-0">{StringConstants.survay_summary_thankyou_text}</p>
                    </div>

                    <div className="px-4 mx-2 listing">
                        <h3 className="title">{CTAText}</h3>
                        <p className="font-weight-bold mb-0">{CTASubText}</p>
                    </div>
                    <div className="d-flex px-4 mx-2 py-4 my-2 listing email-support">
                        <div className="flex-grow-1 d-flex email-support-sub">
                            <div className="pr-5 pt-1 pad">
                                {
                                    CTATip && 
                                <p className="mb-0 font-weight-bold d-flex justify-content-center ">
                                    <img src={Images.greensurveyicon} alt="" className="pr-3 " />
                                    {CTATip}
                                </p>
                                }
                            </div>
                        </div>
                        <div >
                            {support === 1 &&
                                <button className="btn btn-outline-primary btn-lg start-now d-flex justify-content-between align-items-center p-3 px-4 greenchevron email-sup-btn "
                                    onClick={() => {
                                        window.open(`mailto:${Config.support_email}`)
                                    }}
                                > {StringConstants.request_call_back} <span className="icon"></span>
                                </button>}
                            {support === 2 &&
                                <button className="btn btn-outline-primary btn-lg start-now d-flex justify-content-between align-items-center p-3 px-4 greenchevron email-sup-btn "
                                    onClick={() => {
                                        history.push(NavigationConstants.share_your_views.url)
                                    }}
                                >{StringConstants.have_your_say} <span className="icon"></span>
                                </button>}
                            {support === 3 &&
                                <button className="btn btn-outline-primary btn-lg start-now d-flex justify-content-between align-items-center p-3 px-4 greenchevron email-sup-btn "
                                    onClick={() => {
                                        window.open(`mailto:${Config.support_email}`)
                                    }}
                                >{StringConstants.email_your_support} <span className="icon"></span>
                                </button>}

                        </div>
                    </div>
                </ListWrapper>

                {
                    resultId &&
                    <ListWrapper>
                        <div className="p-4 m-2 mb-0 listing">
                            <h3 className="title">{StringConstants.what_community_said}</h3>
                            <p className="mb-0"> {StringConstants.who_have_filled_survey} </p>
                        </div>

                        <div className="d-flex view-result-display" style={{ borderTop: "1px solid #DFDFDF" }}>
                            <div className="flex-grow-1 pl-4 pt-1 d-flex padding-all">
                                <div className="pr-5 pt-2">
                                    <p className="mb-0 bold d-flex justify-content-center">
                                        <img src={Images.info} alt="" className="pr-3 " /> {StringConstants.want_to_see_full_filled_survey} </p></div>

                            </div>

                            <div>
                                <Link className="btn btn-primary minwdth py-3 px-2" to={{
                                    pathname: NavigationConstants.share_your_views.url + "/result/" + resultId,
                                    state: { slug: slug, resultId: resultId, token: token, data:data }
                                }}>
                                    {StringConstants.view_results} <img src={Images.whitechevron} alt="" className="pl-5" />
                                </Link>
                            </div>
                        </div>
                    </ListWrapper>
                }


                <div className="row support-cards">
                    <div className="col-md-6">
                        {support === 1 && __NeedLittleGuidance()}
                        {support === 2 && __WantToHaveChatAboutIt()}
                        {support === 3 && __NeedLittleGuidance()}
                    </div>
                    <div className="col-md-6">
                        {support === 1 && __NotGoingToChangeMind()}
                        {support === 2 && __GetYourOpinionHeard()}
                        {support === 3 && __WantToHaveChatAboutIt()}
                    </div>
                </div>
            </div>

            <div className="text-center postion-center">
                <CommonFooter />
            </div>
        </>
    );
}


export default SurveySummary;
