import React from "react";
import { Link } from "react-router-dom";
const CommonFooter = (props) => {
  const __scrolltop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="footer1 f-bottom">
        <div className="flex-grow-1">
          <div className="legal-box">
            <Link
              to="/privacy-policy"
              onClick={() => {
                __scrolltop();
              }}
              className="link-tc"
            >
              Privacy Policy
            </Link>
            <Link
              to="/cookies"
              onClick={() => {
                __scrolltop();
              }}
              className="link-tc"
            >
              Cookie Policy
            </Link>
            <Link
              to="/contact"
              onClick={() => {
                __scrolltop();
              }}
              className="link-tc"
            >
              Get in contact
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonFooter;
