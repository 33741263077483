import moment from 'moment';

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const UIAlert = (type, response) => {
    alert(JSON.stringify(response))
}


export const daysAgo = (dt) => {

    let date = moment(dt),
        now = moment(),
        days = now.diff(date, "days"),
        weeks = now.diff(date, "weeks"),
        months = now.diff(date, "months"),
        result = "";

    if (months) {
        result += months + (months === 1 ? " month ago" : " months ago");
        days = days % 30;
    } else if (weeks) {
        result += weeks + (weeks === 1 ? " week ago" : " weeks ago");
        days = days % 7;
    } else if (days >= 1 && weeks === 0) {
        result += days + (days === 1 ? " day ago" : " days ago");
    } else if (days === 0) {
        result += (days === 0 && "today");
    }
    return result;
}


export const daysToGo = (dt) => {

    let date = moment(dt),
        now = moment(),
        days = date.diff(now, "days"),
        weeks = date.diff(now, "weeks"),
        months = date.diff(now, "months"),
        result = "";

    if (months) {
        result += months + (months === 1 ? " month left" : " months left");
        days = days % 30;
    } else if (weeks) {
        result += weeks + (weeks === 1 ? " week left" : " weeks left");
        days = days % 7;
    } else if (days >= 1 && weeks === 0) {
        result += days + (days === 1 ? " day left" : " days left");
    } else if (days === 0) {
        result += (days === 0 && "Closing today");
    }
    return result;
}