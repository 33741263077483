import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Lightbox from "lightbox-react";
import * as _ from "lodash";

// redux
import { project_details_action } from "../../stores/actions";
import { PrimaryImageLayout, ListWrapper, Animi } from "../../components";
import { Colors, Images } from "../../config";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonFooter from "../Legals/CommonFooter";

const ProjectDetails = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // states
  const [activeTab, setActiveTab] = useState(0);
  const [ImageToDisplay, setImageToDisplay] = useState();
  const [ImageZoomAnimation, setImageZoomAnimation] = useState(false);
  const projectConfigData = useSelector((state) => state.projectconfig);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [imageWithName, setImageWithName] = useState([]);
  const [imageWithOutName, setImageWithOutName] = useState([]);
  const [lightboxName, setLightboxName] = useState();

  let backToPageTitle;
  
  

  useEffect(() => {
    if (projectConfigData.data !== null && projectConfigData.isSuccess) {
      if (projectConfigData.data.data.projectDetails[0]) {
        let initialItem = projectConfigData.data.data.projectDetails[0];

        setImageToDisplay(initialItem.tab_image);
        setImageZoomAnimation(projectConfigData.data.data.projectConfig.projectFeaturedImageZoomInHoverEffect)

        if (initialItem.additional_section_type === "imagewithname") {
          __setImageData(initialItem, 1);
        } else if (initialItem.additional_section_type === "imagewithoutname") {
          __setImageData(initialItem, 2);
        }
      }
    }
  }, [projectConfigData]);

  const __renderTab = () => {
    let tabs = [];

    _.each(projectConfigData.data.data.projectDetails, (item, id) => {
      // let width = item.tab_title.length * 10
      tabs.push(
        <div className={activeTab === id ? "nav-item active" : "nav-item "} key={"nav"+id}>
          <button
            className={"btn btn-default p-4"}
            onClick={() => {
              if (item.additional_section_type === "imagewithname") {
                __setImageData(item, 1);
              } else if (item.additional_section_type === "imagewithoutname") {
                __setImageData(item, 2);
              }

              setImageToDisplay(item.tab_image);
              setActiveTab(id);
            }}
          >
            {item.tab_title}
          </button>
        </div>
      );
    });

    const sliderSettings = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      centerMode: false,
      //slidesToShow: 1,
      //slidesToScroll: 1,
      variableWidth: true,
      swipeToSlide: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            //slidesToShow: 3,
            infinite: false,
            //slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            //slidesToShow: 2,
            infinite: false,
            //slidesToScroll: 1,
            arrows: true,
          },
        }, {
          breakpoint: 480,
          settings: {
            //slidesToShow: 2,
            //infinite: true,
            //slidesToScroll: 1,
            arrows: true,
          },
        },
      ],
    };
    return (
      <div
        className="tabs project-details"
        style={{ boxShadow: "0 2px 64px 0 rgba(0, 0, 0, 0.1)" }}
      >
        <Slider {...sliderSettings}>{tabs}</Slider>
      </div>
    );
  };

  const __setImageData = (res, state) => {
    let imageDt = [];

    if (res.additional_section_images === null) {
      return;
    }

    _.each(res.additional_section_images, (item, id) => {
      imageDt.push(item.image);
    });

    if (state === 1) {
      setImageWithName(imageDt);
    } else if (state === 2) {
      setImageWithOutName(imageDt);
    }
  };

  const __renderGallery = (res) => {
    let imageDt = [];

    if (lightboxName === 1) {
      imageDt = imageWithName;
    } else if (lightboxName === 2) {
      imageDt = imageWithOutName;
    }

    return (
      <>
        {isOpen && (
          <Lightbox
            mainSrc={imageDt[photoIndex]}
            nextSrc={imageDt[(photoIndex + 1) % imageDt.length]}
            prevSrc={
              imageDt[(photoIndex + imageDt.length - 1) % imageDt.length]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + imageDt.length - 1) % imageDt.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imageDt.length)
            }
          />
        )}
      </>
    );
  };

  const __renderAdditionalComponent = (item) => {
    let additional_items = [];

    let image = (item, classname) => {
      return (
        <img
          src={item.image}
          className={classname}
          alt={item.caption}
          title={item.caption}
        />
      );
    };

    if (item.additional_section_type === "imagewithoutname") {
      _.each(item.additional_section_images, (item, id) => {
        additional_items.push(
          <div className="p-2" key={"imagewithoutname" + id}>
            <div className="slide-preview-image">
              <button
                className="btn btn-default p-0 m-0"
                onClick={() => {
                  setLightboxName(2);
                  setPhotoIndex(id);
                  setIsOpen(true);
                }}
              >
                {image(item, "small-image")}
              </button>
            </div>
          </div>
        );
      });
    } else if (item.additional_section_type === "imagewithname") {
      _.each(item.additional_section_images, (item, id) => {
        additional_items.push(
          <div className="p-2" key={"imagewithname" + id}>
            <div className="slide-preview-image" >
              <button
                className="btn btn-default p-0 m-0"
                onClick={() => {
                  setLightboxName(1);
                  setPhotoIndex(id);
                  setIsOpen(true);
                }}
              >
                {image(item, "small-image")}
              </button>
              <div className="slide-name">{item.caption}</div>
            </div>
          </div>
        );
      });
    } else if (item.additional_section_type === "iconwithname") {
      _.each(item.additional_section_images, (item, id) => {
        additional_items.push(
          <li key={"img" + id} className="pb-4 pr-4">
            <div className="d-flex align-items-center">
              <div className="icon-wpr">{image(item, "icon-image")}</div>
              <div className="text-wpr">{item.caption}</div>
            </div>
          </li>
        );
      });
    }

    let settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1281,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    let isPositionTop = item.additional_section_images_position === "top";
    let borderClass = isPositionTop ? "border-bottom" : "border-top";

    switch (item.additional_section_type) {
      
      case "imagewithoutname":
        return (
          <>
            <div className={"py-2 pl-5 pr-5 pb-4 align-items-center padding-left pad-des " + borderClass}>
              {item.additional_section_text.length > 0 && (
                <h5 className="inpartnership-text proj-det-pad-botm">
                  {item.additional_section_text}
                </h5>
              )}
              <div className="imagewithoutname pb-3">
                {additional_items.length === 1 ? (
                  <div className="slide-preview-image-one">
                    {additional_items}
                  </div>
                ) : (
                  <Slider {...settings}>{additional_items}</Slider>
                )}
              </div>
            </div>
          </>
        );
        break;

      case "imagewithname":
        return (
          <>
            <div className={"py-2 pl-5 pr-5 pb-4 align-items-center padding-left pad-des " + borderClass}>
              {item.additional_section_text.length > 0 && (
                <h5 className="inpartnership-text proj-det-pad-botm">
                  {item.additional_section_text}
                </h5>
              )}

              <div className="imagewithoutname pb-3">
                {additional_items.length === 1 ? (
                  <div className="slide-preview-image-one">
                    {additional_items}
                  </div>
                ) : (
                  <Slider {...settings}>{additional_items}</Slider>
                )}
              </div>
            </div>
          </>
        );
        break;

      case "iconwithname":
        return (
          <div className={"iconwithname pb-3 pl-5 pr-2 " + (isPositionTop && "pt-4")}>
            {item.additional_section_text.length > 0 && (
                <h5 className="inpartnership-text proj-det-pad-botm">
                  {item.additional_section_text}
                </h5>
              )}
            <ul>{additional_items}</ul>
          </div>
        );
        break;
    }

    return null;
  };

  const __renderContent = () => {
    let content = [];

    _.each(projectConfigData.data.data.projectDetails, (item, id) => {
      content.push(
        <div
          key={"content" + id}
          id={"cnt" + id}
          className={activeTab === id ? "d-block" : "d-none"}
        >
          <ListWrapper prime={true}>
            {item.additional_section_type !== "" && item.additional_section_images_position === "top" &&
              __renderAdditionalComponent(item)}
            <div
              className="py-4 px-5 project-details-cnt pad-one"
              dangerouslySetInnerHTML={{ __html: item.tab_content }}
            ></div>
            {item.additional_section_type !== "" && item.additional_section_images_position === "bottom" &&
              __renderAdditionalComponent(item)}
          </ListWrapper>
        </div>
      );
    });

    return (
      <div className="content">
        <Animi.FadeInAnimationDiv>{content}</Animi.FadeInAnimationDiv>
      </div>
    );
  };

  if (backToPageTitle === undefined) {
    backToPageTitle = "homepage";
  } else {
    backToPageTitle = props.location.state.backToPageTitle;
  }
  return (
    <>
      <div className="fluid-container">
        {/* <button
          className="btn btn-default text-primary back-button-gen p-2 pl-4 pr-3 bold"
          onClick={() => {
            history.push("/");
          }}
        >
          <img src={Images.paginationback} alt="" className="mr-3 pb-1" />
          Back
        </button> */}

        <PrimaryImageLayout isAnimated={ImageZoomAnimation}  image={ImageToDisplay}>
          {projectConfigData.data && (
            <>
              {__renderTab()}
              <div className="py-4 px-5 hub-mobile">
                {__renderContent()}
                {__renderGallery()}
              </div>


            </>
          )}
        </PrimaryImageLayout>
      </div>
      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default ProjectDetails;
