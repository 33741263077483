import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { QTADT } from './index';

import { Images } from '../config';

const QT06 = props => {

   
    let list = [];
    const [answers, setAnswers] = useState([]);
    const [adtData, setAdtData] = useState('');

    let ans_list;
    if(props.data.hasOwnProperty('answer')){
        if(props.data.answer !== ''){
            ans_list = props.data.answer.answer.split(',');
        }
    } 
    var css_apply = 'gray_scale'
    var indexing = 0;

    useEffect(() => {
        if (answers.length > 0) {
            props.onSubmit({ "answer": answers.join(','), "questionId": props.data.questionId, "additional_thoughts": adtData });
        }
    }, [answers, adtData]);



    const __Answers = (res, event) => {
        let tempArray = JSON.parse(JSON.stringify(answers));

        let index = tempArray.indexOf(res);
        indexing = index;
        if (index === -1) {
            css_apply = 'gray_scale_out';
            tempArray.push(res);

        } else {
            _.remove(tempArray, (n) => {
                css_apply = 'gray_scale';

                return n === res;
            });
        }
        setAnswers(tempArray);
    };
    _.each(props.data.options, (item, index) => {

        let check = false;

        let checkIndex =  _.findIndex(ans_list, function(o) { return o === item.optionId; });
        if(checkIndex >= 0){
            check = true;
        }

        list.push(<div key={item.optionId} className="chk-image">
            <label>
                <div className="imageControl">
                    <input type="checkbox" name={props.data.questionId} value={item.optionId} className="mr-2"
                        onClick={() => {
                            __Answers(item.optionId)
                        }}
                        //checked={ans_list !== undefined && ans_list.length ? (ans_list[index] === item.optionId) : false} 
                        checked={check}
                    />
                    <div style={{
                        backgroundImage: `url(${item.option})`,
                    }} className="checkmark">
                        {/* <img src={Images.tspacer} alt="" style={{ width: "100%" }} /> */}
                     {
                         item.optionCaption !== '' && <p className="imageText">{item.optionCaption}</p>
                     }
                    </div>
                   
                </div>
            </label>
        </div>)
    });

    return (<>
        <div className="imageQuestion pt-4">
            {list}
        </div>

        <QTADT adtEnabled={props.data.questionAdditionalThoughtsEnabled} questionId={props.data.questionId} onSubmit={(res) => {
            setAdtData(res);
        }} additional_thoughts={props.data.hasOwnProperty('answer') ? props.data.answer.additional_thoughts : null} />
    </>);
};

export default QT06;