import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as _ from "lodash";
import Slider from "react-slick";

// redux
import {
  ListWrapper,
  HeroBannerLayout,
  ProposalListing,
  SupportMobalisationList,
} from "../../components";
import { daysAgo } from "../../utils/utility";
import { Images, StringConstants, NavigationConstants } from "../../config";
import CommonFooter from "../Legals/CommonFooter";
import { news_detail_action, reset_news_detail } from "../../stores/actions";
import { Animi } from "../../components";
import { Helmet } from "react-helmet";

const NewsDetails = (props) => {
  const dispatch = useDispatch();
  let backToPageTitle;
  const [details, setDetails] = useState([]);

  const [relatedProposal, setRelatedProposal] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [projectData, setProjectData] = useState();

  const [supportMobalisationData, setSupportMobalisationData] = useState({
    title: "Do you support this project?",
    description:
      "Take this short survey to help us gauge your opinion on the project.",
  });

  const newsDetail = useSelector((state) => state.news_detail);
  const projectConfigData = useSelector((state) => state.projectconfig);

  useEffect(() => {
    try {
      let slug = props.location.pathname.replace(NavigationConstants.news_events.url + "/", "");
      if ((newsDetail.isFetching === false) & (newsDetail.data === null)) {
        dispatch(news_detail_action(slug));
      }
    } catch (e) {
      console.log("news error", e);
    }
  }, []);

  useEffect(() => {
    if (projectConfigData.data !== null) {
      setProjectData(projectConfigData.data.data);

      if (projectConfigData.data.data.support_mobilisation) {
        setSupportMobalisationData(
          projectConfigData.data.data?.support_mobilisation
        );
      }
    }
  }, [projectConfigData]);

  useEffect(() => {
    if (newsDetail.data !== null && newsDetail.isSuccess === true) {
      setDetails(newsDetail.data.data.newsDetails);

      if (newsDetail.data.data.newsDetails.relatedProposals.length > 0) {
        setRelatedProposal(newsDetail.data.data.newsDetails.relatedProposals);
      }
      dispatch(reset_news_detail());
    }
  }, [newsDetail]);

  const __renderTab = () => {
    let tabs = [];

    _.each(details.articleSeries, (item, id) => {
      tabs.push(
        <div className={activeTab === id ? "nav-item active" : "nav-item "}>
          <button
            className={"btn btn-default p-4"}
            onClick={() => setActiveTab(id)}
          >
            {item.title}
          </button>
        </div>
      );
    });

    const sliderSettings = {
      className: "slider variable-width",
      dots: false,
      infinite: false,
      centerMode: false,
      variableWidth: true,
      swipeToSlide: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            infinite: false,
            arrows: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            infinite: false,
            arrows: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            variableWidth: true,
          },
        },
      ],
    };
    return (
      <div
        className="tabs news-details"
        style={{ boxShadow: "0 2px 64px 0 rgba(0, 0, 0, 0.05)" }}
      >
        <Slider {...sliderSettings}>{tabs}</Slider>
      </div>
    );
  };

  const __renderProposalsArticleSeries = (data) => {
    let proposals = [];
    _.each(data, (item, id) => {
      proposals.push(
        <ProposalListing key={"apr_" + 0} details={item} type={'landing'} backToPageTitle="Homepage" />
      );
    })

    return proposals
  }

  const __renderContent = () => {
    let content = [];

    _.each(details.articleSeries, (item, id) => {
      content.push(
        <div
          key={"content" + id}
          id={"cnt" + id}
          className={
            activeTab === id ? "project-details-cnt d-block" : "d-none"
          }
        >
          <div
            className="p-4 pad-one"
            dangerouslySetInnerHTML={{ __html: item.description }}
          ></div>
          {__renderProposalsArticleSeries(item.relatedProposals)}
        </div>
      );
    });

    return (
      <div className="content">
        <Animi.FadeInAnimationDiv>{content}</Animi.FadeInAnimationDiv>
      </div>
    );
  };
  if (newsDetail.isFetching) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            paddingTop: "50px",
          }}
        >
          Loading...
        </div>
      </>
    );
  }

  if (backToPageTitle === undefined) {
    backToPageTitle = "news";
  } else {
    backToPageTitle = props.location.state.backToPageTitle;
  }

  return (
    <>
      <Helmet>
        {/* <meta property="og:url" content={window.location.href} /> */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={details.newsTitle} />
        {/* <meta property="og:description" content={ details.newsDescription } /> */}
        {/* <meta property="og:image:secure_url" content={details.newsHeaderImage} /> */}
      </Helmet>
      <HeroBannerLayout
        basepage={backToPageTitle}
        details={details}
        image={details.newsHeaderImage}
        imageTitle={details.newsTitle}
        url={window.location.href}
      >
        <ListWrapper>
          <div className="p-4">
            <h3 className="title">{details.newsTitle}</h3>
            <div className="d-md-flex">
              <div className="flex-grow-1">
                <p className="mb-1 post-details">
                  <span className="bold pr-3">{details.newsCategoryName}</span>
                  {details.newsIsEvent === 1 && " | "}
                  <span className="bold pl-3">
                    {details.newsIsEvent === 1 ?
                      details.newsEventDateType === 1 ?
                        `${moment(details.newsEventStartDate).format("DD MMM YYYY")}  - ${moment(details.newsEventEndDate).format("DD MMM YYYY")}` :
                        details.newsEventDates.join(", ")
                      : null
                    }
                  </span>
                </p>
              </div>
              <div>
                <p className="mb-0 post-details bold d-flex">
                  <img src={Images.calendar} alt="" className="pr-3 " />
                  {StringConstants.posted} {daysAgo(details.newsPublishDate)}
                </p>
              </div>
            </div>
            {details.newsDescription !== '' && (
              <div
                dangerouslySetInnerHTML={{ __html: details.newsDescription }}
                className="mt-4 project-details-cnt li-fontSize pad-one"
              />
            )}
          </div>
          <div className="article-series">
            {__renderTab()}
            <div className="px-4">{__renderContent()}</div>
          </div>
        </ListWrapper>
        {projectData &&
          projectData.projectConfig.projectDevelopmentSupportEnabled === true &&
          details?.supportMobilisationEnabled === 1 && (
            <div className="d-flex">
              <div className="flex-grow-1 have-your-say-text">
                <SupportMobalisationList data={supportMobalisationData} />
              </div>
            </div>
          )}

        {relatedProposal && (
          <div className="px-1 pb-2 pt-3">
            <div className="d-flex">
              <div className="flex-grow-1 have-say-text">
                <h5 className="pb-2">{StringConstants.news_related}</h5>
              </div>
            </div>

            <Animi.FadeInAnimationDiv>
              <ProposalListing
                details={relatedProposal[0]}
                type={"large"}
                key={"pn-0"}
              />
            </Animi.FadeInAnimationDiv>
          </div>
        )}
      </HeroBannerLayout>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default NewsDetails;
