import React from 'react';

const TextInput = (props) => {
    return (
        <div className="form-group demographics-form">
            <label htmlFor={props.id}>{props.label}</label>
            <input type={props.type} className="form-control" id={props.id} onChange={(event) => {
                props.onSubmit(event.target.value);
            }} />
        </div>);
};

export default TextInput;