export const SURVEY_START_REQUEST = "SURVEY_START_REQUEST";
export const SURVEY_START_RECEIVE = "SURVEY_START_RECEIVE";
export const SURVEY_START_FAILURE = "SURVEY_START_FAILURE";
export const RESET_SURVEY_START = "RESET_SURVEY_START";

export const SURVEY_END_REQUEST = "SURVEY_END_REQUEST";
export const SURVEY_END_RECEIVE = "SURVEY_END_RECEIVE";
export const SURVEY_END_FAILURE = "SURVEY_END_FAILURE";
export const RESET_SURVEY_END = "RESET_SURVEY_END";

export const SURVEY_RESULT_REQUEST = "SURVEY_RESULT_REQUEST";
export const SURVEY_RESULT_RECEIVE = "SURVEY_RESULT_RECEIVE";
export const SURVEY_RESULT_FAILURE = "SURVEY_RESULT_FAILURE";
export const RESET_SURVEY_RESULT = "RESET_SURVEY_RESULT";

export const ANSWERS_REQUEST = "ANSWERS_REQUEST";
export const ANSWERS_RECEIVE = "ANSWERS_RECEIVE";
export const ANSWERS_FAILURE = "ANSWERS_FAILURE";
export const RESET_ANSWERS = "RESET_ANSWERS";

export const SURVEY_SUPPORT_REQUEST = "SURVEY_SUPPORT_REQUEST";
export const SURVEY_SUPPORT_RECEIVE = "SURVEY_SUPPORT_RECEIVE";
export const SURVEY_SUPPORT_FAILURE = "SURVEY_SUPPORT_FAILURE";
export const RESET_SURVEY_SUPPORT = "RESET_SURVEY_SUPPORT";