import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { PrimaryImageLayout } from '../../components'
import { Images, StringConstants } from '../../config';

// redux
import { hub_action } from '../../stores/actions'
import CommonFooter from '../Legals/CommonFooter';

const Contact = () => {
  const dispatch = useDispatch();
  const hubData = useSelector(state => state.hub);

  // states
  const [ImageToDisplay, setImageToDisplay] = useState();
  const [ImageZoomAnimation, setImageZoomAnimation] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
    if (hubData.data === null && hubData.isFetching === false) {
      try {
        dispatch(hub_action());
      } catch (error) {
        console.error(" profile ::  profile() :: Exception ==", error);
      }
    }
  });

  useEffect(() => {
    if (hubData.data !== null && hubData.isSuccess) {
      setImageToDisplay(hubData.data.data.projectConfig.projectFeaturedImage)
      setImageZoomAnimation(hubData.data.data.projectConfig.projectFeaturedImageZoomInHoverEffect)
    }
  }, [hubData]);

  return (
    <>
      <PrimaryImageLayout isAnimated={ImageZoomAnimation} image={ImageToDisplay}>
        <div className="px-4 flex-grow-1 k-contact">
          <h3 className="pb-1 pt-5 title mb-2">{StringConstants.contact_title}</h3>
          <p className="mb-5">{StringConstants.Contact_subtext}</p>
          {
            process.env.REACT_APP_EMAIL !== '' &&
            <>
              <div className="d-flex kanda-contact align-items-center pb-3">
                <div className="survey-icon mr-3 text-center">
                  <img src={Images.Cemail} alt="" className="icon-circle" />
                </div>
                <div className="flex-grow-1">
                  <p className="pb-0 pt-0 mb-0 d-flex project-info-text" > <b>{StringConstants.contact_Email} </b> </p>
                  <a href={"mailto:" + process.env.REACT_APP_EMAIL}><span>{process.env.REACT_APP_EMAIL} </span></a>
                </div>
              </div>
            </>
          }

          {
            process.env.REACT_APP_MOBILE !== '' &&
            <>
              <div className="d-flex kanda-contact align-items-center pb-3">
                <div className="survey-icon mr-3 text-center" >
                  <img src={Images.Cphone} alt="" className="icon-circle" />
                </div>
                <div className="flex-grow-1">
                  <p className="pb-0 pt-0 mb-0 d-flex project-info-text" > <b>{StringConstants.contact_phone}</b> </p>
                  <span>{process.env.REACT_APP_MOBILE}</span>
                </div>
              </div>
            </>
          }
          <p className="mt-5">{StringConstants.disclaimer_text}</p>
        </div>
      </PrimaryImageLayout>


      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  )
}

export default Contact
