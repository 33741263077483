import React from 'react';
import { ShareVia } from '../components'
import { Images } from '../config';
import { useHistory } from "react-router-dom";

const HeroBannerLayout = (props) => {
    let history = useHistory();

    let ShowShareVia = props.socialShare === undefined ? true : props.socialShare;

    return (
        <div className="itemDetails pb-4 hero-banner">
            <div className="newIage" >
                <div style={{ width: "100%", height: window.innerHeight * 0.4, overflow: "hidden", backgroundImage: `url(${props.image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
                <div style={{ marginTop: 20, position: "absolute", top: 0, left: 0, right: 0 }}>
                    <button className="btn btn-default text-primary back-to-news p-2 pl-4 pr-3 bold" onClick={() => {
                        history.goBack();
                    }}><img src={Images.paginationback} alt="" className="mr-3 pb-1" />Back</button>

                    {ShowShareVia &&
                        <div className="container pt-2">
                            <ShareVia url={props.url} title={props.imageTitle} white={true} image={props.image} />
                        </div>
                    }
                </div>
            </div>
            <div className="container" style={{ marginTop: -120 }}>
                {props.children}
            </div>
        </div>
    )
}

export default HeroBannerLayout;