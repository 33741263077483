import React, { useEffect, useState } from "react";
import initReactFastclick from "react-fastclick";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { Header, Footer, TextInput } from "./components";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga4";

import { Config, StringConstants } from "./config";
import { validate } from "./stores/actions";

import "react-toastify/dist/ReactToastify.css";

import "./assets/css/scss/bootstrap.scss";
import "./assets/css/scss/theme.scss";
import "./assets/css/scss/theme-responsive.scss";
import "./assets/css/scss/theme-project-summary.scss";

import "lightbox-react/style.css"; // This only needs to be imported once in your app

const App = (props) => {
  const dispatch = useDispatch();
  const loc = useLocation();
  const history = useHistory();
  const isGA = process.env.REACT_APP_GOOGLEANALYTICS !== "";
  const cookieyesid = process.env.REACT_APP_COOKIEYES_ID;

  const [password, setPassword] = useState();

  const secure_site = useSelector((state) => state.secure_site);
  const isProtected = process.env.REACT_APP_ISPROTECTED === "true";

  useEffect(() => {
    // initialization of fast click
    initReactFastclick();

    if (isGA) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLEANALYTICS);
    }

    if (cookieyesid!==''){
      let script = document.createElement('script');
      script.setAttribute('src', `https://cdn-cookieyes.com/client_data/${cookieyesid}/script.js`);
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (isGA) {
      ReactGA.send({ hitType: "pageview", page: window.location.href.pathname});
    }
  }, [loc, props]);

  const __renderBody = () => {
    return (
      <>
        <Header />
        {props.children}
        <Footer />
        <ToastContainer
          position="bottom-right"
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
        />
      </>
    );

  };

  const __validatePassword = () => {
    try {
      dispatch(validate(password));
    } catch (e) {
      console.log(e);
    }
  };

  const __renderLogin = () => {
    return (
      <div className="proteck_password">
        <TextInput
          label="Password"
          id="fullname"
          type="password"
          onSubmit={(res) => {
            setPassword(res);
          }}
        />

        {secure_site.isError && (
          <p className={"text-danger"}>{StringConstants.invalid_key}</p>
        )}

        <button
          className="btn btn-outline-secondary mr-2 d-flex graychevron"
          type="reset"
          onClick={__validatePassword}
        >
          {StringConstants.submit} <span className="icon ml-3 mt-2"></span>
        </button>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Config.projectName}</title>
      </Helmet>

      {isProtected === true
        ? secure_site.isValid === true
          ? __renderBody()
          : __renderLogin()
        : __renderBody()}
    </>
  );
};

export default App;
