

import { UIAlert } from "./utility";

export const NOTIFICATIONSID = "NOTIFICATIONSID";

export const getData = async (key) => {
    if (sessionStorage.getItem(key) !== null) {
        try {
            const storedValue = await sessionStorage.getItem(key);;
            if (typeof storedValue === 'undefined') {
                return {};
            } else {
                //  const bytes = CryptoJS.AES.decrypt(storedValue.toString(),REACT_APP_X_AUTHORIZATION);
                const plaintext = storedValue.toString();
                return JSON.parse(plaintext);
            }
        } catch (error) {
            console.log(error);
            //TODO
            UIAlert("error", "There was error while loading the data");
        }
    }
};

export const setData = async (key, data, callback) => {
    try {
        // const encrypted_data = CryptoJS.AES.encrypt(JSON.stringify(data),REACT_APP_X_AUTHORIZATION);
        await sessionStorage.setItem(key, JSON.stringify(data));
        if (callback !== null && typeof callback === "function") {
            callback();
        }
    } catch (error) {
        console.log(error)
        UIAlert("error", "There was error while loading the data");
    }
};

export const clearStorage = async (key) => {
    try {
        await sessionStorage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
}



export const getDataLocalStorage = async (key) => {
    if (localStorage.getItem(key) !== null) {
        try {
            const storedValue = await localStorage.getItem(key);
            if (typeof storedValue === 'undefined') {
                return {};
            } else {
                //  const bytes = CryptoJS.AES.decrypt(storedValue.toString(),REACT_APP_X_AUTHORIZATION);
                const plaintext = storedValue.toString();
                return JSON.parse(plaintext);
            }
        } catch (error) {
            console.log(error);
            //TODO
            UIAlert("error", "There was error while loading the data");
        }
    }
};

export const setDataLocalStorage = async (key, data, callback) => {
    try {
        // const encrypted_data = CryptoJS.AES.encrypt(JSON.stringify(data),REACT_APP_X_AUTHORIZATION);
        await localStorage.setItem(key, JSON.stringify(data));
        if (callback !== null && typeof callback === "function") {
            callback();
        }
    } catch (error) {
        console.log(error)
        UIAlert("error", "There was error while loading the data");
    }
};

export const clearStorageLocalStorage = async (key) => {
    try {
        await localStorage.removeItem(key);
        return true;
    } catch (error) {
        return false;
    }
}
