import React from 'react';
import { Images } from '../config';
import { toast } from 'react-toastify';


const ShareVia = props => {
    let pageURL = props.url || window.location;
    let imageURL = props.image || ''

    const __openMail = () => {
        window.open(`mailto:?subject=${props.title}&body=${props.url}`)
    }

    const __copy = () => {

        try {
            let copyText = pageURL;

            if (typeof (navigator.clipboard) === "object") {
                navigator.clipboard.writeText(copyText)
                /* Alert the copied text */
                toast.success("Link Copied")
            } else {
                /* Select the text field */
                copyText.select();
                copyText.setSelectionRange(0, 99999); /*For mobile devices*/

                /* Copy the text inside the text field */
                document.execCommand("copy");

                /* Alert the copied text */
                toast.success("Link Copied")
            }

        } catch (exception) {
            console.log(exception)
        }

    }

    const __openWindow = (url) => {
        window.open(url, "_blank", "width=600,height=400")
    }

    return (
        <div className={props.white ? "share-via white" : "share-via"}>
            <p className="m-0 share-via-text text-right">
                <span className="bold inline-block pt-1 pr-2">Share via</span>
                <button className="btn p-0 pl-1 pb-1 " onClick={() => {
                    __openWindow(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(props.url)}`)
                }}><img src={props.white ? Images.whitefacebook : Images.facebook} alt="Facebook" /></button>
                <button className="btn p-0 pl-1 pb-1 " onClick={() => {
                    __openWindow(`https://twitter.com/intent/tweet?url=${encodeURIComponent(props.url)}&text=${props.title}`)
                }}><img src={props.white ? Images.whitetwitter : Images.twitter} alt="Twitter" /></button>
                <button className="btn p-0 pl-1 pb-1 " onClick={() => {
                    __openMail()
                }}><img src={props.white ? Images.whitemails : Images.mails} alt="Mail" /></button>
                <button className="btn p-0 pl-1 pb-1 " onClick={() => {
                    __copy();
                }}><img src={props.white ? Images.whitecopy : Images.copy} alt="Copy path" /></button>
            </p>
        </div>
    );
};

export default ShareVia;