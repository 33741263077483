import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { QTADT } from './index';

const QT03 = props => {

    const [data, setData] = useState(props.data.options)
    const [answers, setAnswers] = useState('');
    const [adtData, setAdtData] = useState('');

    const [trueCondition, setTrueCondition] = useState(false);

    useEffect(() => {
        if (answers !== '') {
            props.onSubmit({ "answer": answers, "questionId": props.data.questionId, "additional_thoughts": adtData });
        }
    }, [answers, adtData]);

    let list = [];
    _.each(data, (item) => {
        let id = props.data.questionId + '_' + item.optionId;

        list.push(<div key={item.optionId} className="py-2 col-md-6">

            <div className="container-one">
                <div className="customeRadio-one"
                >
                    <input id={id} type="radio" name={"radio_" + props.data.questionId} value={item.optionId} className="mr-2"
                        onChange={() => {
                            setAnswers(item.optionId);
                        }}
                        checked={props.data.hasOwnProperty('answer') ? props.data.answer.answer === item.optionId : false}
                    />
                    <span className="checkmark"></span>
                </div>
                {item.option}
            </div>
        </div>)
    });

    return (<div>
        <div className="row">
            {list}
        </div>

        <QTADT adtEnabled={props.data.questionAdditionalThoughtsEnabled} questionId={props.data.questionId} onSubmit={(res) => {
            setAdtData(res);
        }}
            additional_thoughts={props.data.hasOwnProperty('answer') ? props.data.answer.additional_thoughts : null}
        />
    </div>);
};

export default QT03;
