export const PROPOSALS_REQUEST = "PROPOSALS_REQUEST";
export const PROPOSALS_RECEIVE = "PROPOSALS_RECEIVE";
export const PROPOSALS_FAILURE = "PROPOSALS_FAILURE";
export const RESET_PROPOSALS = "RESET_PROPOSALS";

export const PROPOSALS_CAT_REQUEST = "PROPOSALS_CAT_REQUEST";
export const PROPOSALS_CAT_RECEIVE = "PROPOSALS_CAT_RECEIVE";
export const PROPOSALS_CAT_FAILURE = "PROPOSALS_CAT_FAILURE";
export const RESET_PROPOSALS_CAT = "RESET_PROPOSALS_CAT";

export const PROPOSAL_DETAILS_REQUEST = "PROPOSAL_DETAILS_REQUEST";
export const PROPOSAL_DETAILS_RECEIVE = "PROPOSAL_DETAILS_RECEIVE";
export const PROPOSAL_DETAILS_FAILURE = "PROPOSAL_DETAILS_FAILURE";
export const RESET_PROPOSAL_DETAILS = "RESET_PROPOSAL_DETAILS";
