import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// redux
import {
  proposals_action,
  reset_proposal_action,
} from "../../stores/actions";
import {
  Filters,
  NoResults,
  SortByFilter,
  Pagination,
  ProposalListing,
  Animi,
  ReactangleLoaderView,
  Loader,
  SupportMobalisationList
} from "../../components";
import * as _ from "lodash";
import { StringConstants } from "../../config";
import CommonFooter from "../Legals/CommonFooter";

const Proposals = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [categoriesData, setCategoriesData] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [supportMobalisationData, setSupportMobalisationData] = useState({
    title: "Do you support this project?",
    description: "Take this short survey to help us gauge your opinion on the project."
  });
  const [filters, setFilters] = useState({
    q: "",
    catId: "",
    orderByCol: "deadline",
    orderByDir: "desc",
    page: 1,
  });

  const proposalData = useSelector((state) => state.proposals);
  const projectConfigData = useSelector((state) => state.projectconfig);

  useEffect(() => {
    window.scroll(0, 0);

    return () => {
      try {
        dispatch(reset_proposal_action());
      } catch (e) {
        console.log(e);
      }
    };
  }, []);

  useEffect(() => {
    try {
      if (proposalData.isFetching === false && proposalData.data === null) {
        dispatch(proposals_action(filters));
      }
    } catch (e) {
      console.log("proposals error", e);
    }
  }, [location]);

  useEffect(() => {
    if (projectConfigData.isSuccess && projectConfigData.data !== null && projectData === null) {
      setCategoriesData(projectConfigData.data.data.categories.proposal);
      setProjectData(projectConfigData.data.data);

      if (projectConfigData.data.data.support_mobilisation) {
        setSupportMobalisationData(projectConfigData.data.data?.support_mobilisation)
      }
    }
  }, [projectConfigData]);

  const __pagination = () => {
    if (proposalData.data === null) {
      return;
    }
    return (
      <Pagination
        data={proposalData.data.data.proposals_pagination}
        onSubmit={(res) => {
          __applyPage(res);
        }}
      />
    );
  };
  const __applyPage = (res) => {
    let filterTp = JSON.parse(JSON.stringify(filters));
    filterTp.page = res;

    setFilters(filterTp);

    dispatch(reset_proposal_action());
    setTimeout(() => {
      dispatch(proposals_action(filterTp));
    }, 100);
  };

  const __featuredListItem = () => {
    if (proposalData.data === null) {
      return;
    }

    if (proposalData.data.data.proposals.length === 0) {
      return (
        <div className="p-4">
          <NoResults isSurvey={true} />
        </div>
      );
    }

    const data = proposalData.data.data.proposals;
    let list = [];
    _.each(data, (item, id) => {
      // temporery fix
      if (id > 0) {
        return;
      }
      list.push(<ProposalListing details={item} type={"large"} key={id} />);
    });
    return <Animi.FadeInAnimationDiv>{list}</Animi.FadeInAnimationDiv>;
  };

  const __listItem = () => {
    if (proposalData.data === null) {
      return;
    }

    const data = proposalData.data.data.proposals;
    let list = [];
    _.each(data, (item, id) => {
      if (id === 0) {
        return;
      }
      list.push(
        <div className="col-12 col-lg-6" key={id}>
          <ProposalListing details={item} type={"small"} key={"pr-" + id} />
        </div>
      );
    });

    if (list.length === 0) {
      return null;
    }

    return (
      <>
        <Animi.FadeInAnimationDiv>
          <div className="row">{list}</div>
        </Animi.FadeInAnimationDiv>
      </>
    );
  };

  const __applySortByFilter = (res) => {
    let filterTp = JSON.parse(JSON.stringify(filters));
    filterTp.page = 1;

    if (res === "alphaDesc") {
      filterTp.orderByCol = "title";
      filterTp.orderByDir = "desc";
    } else if (res === "alphaAsc") {
      filterTp.orderByCol = "title";
      filterTp.orderByDir = "asc";
    } else if (res === "dateDesc") {
      filterTp.orderByCol = "deadline";
      filterTp.orderByDir = "desc";
    } else if (res === "dateAsc") {
      filterTp.orderByCol = "deadline";
      filterTp.orderByDir = "asc";
    } else if (res === "responsesDesc") {
      filterTp.orderByCol = "responses";
      filterTp.orderByDir = "desc";
    } else if (res === "responsesAsc") {
      filterTp.orderByCol = "responses";
      filterTp.orderByDir = "asc";
    }

    setFilters(filterTp);

    dispatch(reset_proposal_action());
    setTimeout(() => {
      dispatch(proposals_action(filterTp));
    }, 100);
  };

  const __search = (res) => {
    let filterTp = JSON.parse(JSON.stringify(filters));
    filterTp.q = res.q;
    filterTp.catId = res.id;
    filterTp.page = 1;
    setFilters(filterTp);

    dispatch(reset_proposal_action());
    setTimeout(() => {
      dispatch(proposals_action(filterTp));
    }, 100);
  }

  const __renderProposalLoader = (val) => {
    return (
      <div className="px-4 pt-3 pb-2 project-config loading-div">
        <div className="main-div">
          <div className="first-div width-30">
            <ReactangleLoaderView
              imageheight={150}
              imagewidth={"100%"}
            ></ReactangleLoaderView>
          </div>
          <div className="second-div width-70">
            <Loader type={"view"} row={6} />
            <div className="main-div-new">
              <div className="f1">
                <Loader type={"text"} row={1} />
              </div>
              <div className="f2">
                <Loader type={"text"} row={1} />
              </div>
            </div>
          </div>
        </div>
        {val === "1" ? (
          <div style={{ padding: 10, display: "flex" }}>
            <div style={{ width: "30%", marginRight: 10 }}>
              <Loader type={"text"} row={1} />
            </div>
            <div style={{ width: "30%" }}>
              <Loader type={"text"} row={1} />
            </div>
          </div>
        ) : null}
      </div>
    );
  };


  return (
    <>
      <div className="container container-top">
        <Filters
          categories={categoriesData}
          //placeholder="Search engagements"
          placeholder="Search Surveys"
          onSubmit={(res) => __search(res)}
        />

        <div className="d-flex">
          <div className="flex-grow-1 have-your-say-text">
            <h5 className="pb-2">{StringConstants.have_your_say}</h5>
          </div>
          <div className="sortBy">
            <SortByFilter
              responses={true}
              onSubmit={(res) => __applySortByFilter(res)}
            />
          </div>
        </div>

        {
          projectData &&
          projectData.projectConfig.projectDevelopmentSupportEnabled === true && (
            <div className="d-flex">
              <div className="flex-grow-1 have-your-say-text">
              <SupportMobalisationList 
                data={supportMobalisationData}/>
              </div>
            </div>)
        }



        {proposalData.isSuccess
          ? __featuredListItem()
          : __renderProposalLoader()}

        <div className="row pt-1">
          <div className="col-md-12">
            {proposalData.isSuccess ? (
              __listItem()
            ) : (
              <div className="praposal-display">
                <div className="col-12 col-lg-6">
                  {__renderProposalLoader()}
                </div>
                <div className="col-12 col-lg-6">
                  {__renderProposalLoader()}
                </div>
              </div>
            )}

            {/* {__featuredListItem()}
                { __listItem()  } */}
            {__pagination()}
          </div>
        </div>
      </div>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default Proposals;
