import React from "react";
import CommonFooter from "./CommonFooter";

const Cookies = (props) => {
  return (
    <>
      <div className="common_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 m-auto">
              <div className="card mt-3 mb-3 kanda-card">
                <div className="legal-content p-4">
                  <h3 className="title text-center pt-4 pb-4">
                    Cookie Policy: Project Websites
                  </h3>
                  <p>
                    This website is operated by Kanda Consulting. All references
                    to ‘our’, ‘us’, ‘we’ or ‘Company’ within this Cookie Policy
                    are deemed to refer to Kanda Consulting (a private limited
                    company with registration number OC417059).
                  </p>
                  <p>
                    We respect the privacy of visitors using the website located
                    at{" "}
                    <a href="https://www.kandaconsulting.co.uk">
                      www.kandaconsulting.co.uk{" "}
                    </a>{" "}
                    (our “Website”)and associated sites or pages that we own or
                    create (our “Related Sites”).
                  </p>

                  <p>
                    Please read this Cookie Policy carefully before using our
                    Website or any of our Related Sites. This Cookie Policy
                    should be read together with our Privacy Policy and any
                    other policy that may be posted on our Website from time to
                    time.
                  </p>
                  <p>
                    By using the Company’s Website or any of our Related Sites,
                    you are consenting to the Company’s use of Cookies in
                    accordance with this Cookie Policy. You are free to amend
                    the use of Cookies whilst using our Website or Related sites
                    using your browser settings at any time.
                  </p>

                  <h3 className="sub-heading pt-4 ">1. What are Cookies?</h3>
                  <p>
                    Cookies are small packets of information stored by your web
                    browser when you visit websites. Cookies are generally used
                    by websites to improve your user experience by enabling that
                    website to ‘remember’ you, either for a single visit (using
                    a “Session” Cookie which is erased when you close your
                    browser) or for repeat visits (using a Persistent Cookie).{" "}
                  </p>
                  <br />
                  <h3 className="sub-heading ">2. Why do we use Cookies?</h3>
                  <p>
                    We use Cookies for several reasons. Some are needed for
                    technical reasons (security purposes) and some enable a more
                    personalised experience for visitors.{" "}
                  </p>
                  <br />
                  <h3 className="sub-heading ">Strictly Necessary Cookies </h3>
                  <p>
                    Our Website and Related Sites use Strictly Necessary Cookies
                    amongst other types. The Strictly necessary Cookies we use
                    let you move around the Website and Related Sites and use
                    essential features. Necessary cookies are crucial for the
                    basic functions of the website and the website will not work
                    in its intended way without them. These cookies do not store
                    any personally identifiable data.
                  </p>
                  <br />
                  <h3 className="sub-heading ">3. Managing Cookies </h3>
                  <p>
                    You have control over some of the Cookies set on your
                    device. You have the option to refuse all non-essential
                    Cookies when you first visit our Website or Related Sites
                    and you can also amend your settings at any time using your
                    browser settings.
                  </p>
                  <p>
                    To find out more on how to manage and delete cookies, visit
                    aboutcookies.org.
                  </p>
                  <p>
                    We reserve the right to amend this Cookie Policy from time
                    to time. Any changes will be posted on this page and shall
                    become effective as soon as they are posted.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default Cookies;
