import React from "react";
import { Link } from "react-router-dom";
import { ListWrapper, ShareVia, Animi } from "../components";
import { Images, StringConstants, NavigationConstants } from "../config";
import { daysAgo } from "../utils/utility";
import moment from "moment";

const NewsListing = (props) => {

  const item = props.details;

  const type = props.type;
  const backToPageTitle = props.backToPageTitle;
  const id = props.id;

  let newsImageClass = "";
  if (type === "small") {
    newsImageClass = "news-img-small";
  } else {
    newsImageClass = "news-img-large";
  }

  return (
    <ListWrapper>
      <Link
        to={{
          pathname: NavigationConstants.news_events.url +"/"+ item.newsSlug,
          state: { newsDetails: item, backToPageTitle: backToPageTitle },
        }}
        className="listingLink"
        onClick={() => {
          window.scroll(0, 0);
        }}
      >
        <div className="d-sm-flex listing card-listingHeight">
          <div>
            <Animi.ZoomInOutAnimationImage>
              <div
                className={newsImageClass}
                style={{ backgroundImage: `url(${item.newsImage})` }}
              />
            </Animi.ZoomInOutAnimationImage>
          </div>
          <div className="p-3 flex-grow-1 d-flex flex-column">
            <div className="new-content  flex-grow-1">
              <h4 className="title mb-0">{item.newsTitle}</h4>
              {item.newsCategoryName !== "" ? (
                <p className="mb-1">
                  <span className="bold pr-2">{item.newsCategoryName}</span>
                  {item.newsIsEvent === 1  &&  (
                    <span className="font-weight-bold px-2">|</span>
                  )}
                  <span className="bold pl-2">
                    {item.newsIsEvent === 1 ? 
                      item.newsEventDateType === 1 ? 
                      
                       `${moment(item.newsEventStartDate).format("DD MMM YYYY")}  - ${moment(item.newsEventEndDate).format("DD MMM YYYY")}`: 
                       item.newsEventDates.join(", ")
                        : null
                  }
                    
                    
                  </span>
                </p>
              ) : null}

              <p className="pt-1">{item.newsSummary}</p>
            </div>

            <div className="d-flex flex-mob-column">
              <div className="pr-4 pt-1 flex-grow-1">
                <p className="mb-0 d-flex font-weight-bold">
                  <img src={Images.calendar} alt="" className="pr-2 " /> {StringConstants.posted} {daysAgo(item.newsPublishDate)}
                </p>
              </div>
              <ShareVia url={window.location.origin + NavigationConstants.news_events.url + "/" + item.newsSlug} title={item.newsTitle} image={item.newsImage} />
            </div>
          </div>
        </div>
      </Link>
    </ListWrapper>
  );
};

export default NewsListing;
