import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function proposals_action(filters) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/proposal/list?q=${filters.q}&catId=${filters.catId}&orderByCol=${filters.orderByCol}&orderByDir=${filters.orderByDir}&page=${filters.page}`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            types: [
                types.PROPOSALS_REQUEST,
                types.PROPOSALS_RECEIVE, {
                    type: types.PROPOSALS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reset_proposal_action = () => {
    return {
        type: types.RESET_PROPOSALS,
        payload: null
    }
};



export function proposals_category_action() {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/proposal/categories`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.PROPOSALS_CAT_REQUEST,
                types.PROPOSALS_CAT_RECEIVE, {
                    type: types.PROPOSALS_CAT_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reset_proposal_category_action = () => {
    return {
        type: types.RESET_PROPOSALS_CAT,
        payload: null
    }
};

export function proposal_detail_by_slug_action(slug) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/proposal/${slug}/details`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.PROPOSAL_DETAILS_REQUEST,
                types.PROPOSAL_DETAILS_RECEIVE, {
                    type: types.PROPOSAL_DETAILS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reset_proposal_detail_by_slug_action = () => {
    return {
        type: types.RESET_PROPOSAL_DETAILS,
        payload: null
    }
};
