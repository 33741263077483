import React, { useState } from 'react';
import _ from 'lodash';

const SupportQuestion = props => {
    // const [adtEnabled, setAdtEnabled] = useState(props.adtEnabled);
    const [answers, setAnswers] = useState(0);
    const dt = [
        { id: 1, title: "Against" },
        { id: 2, title: "Undecided" },
        { id: 3, title: "Support" },
    ]

    const __saveAnswer = (answer) => {
        props.onSubmit(answer);
        setAnswers(answer)
    }

    const __renderButton = () => {
        const btn = [];

        _.each(dt, (item) => {
            let classes = `btn btn-primary btn-spt btn-${item.title.toLowerCase()}`;

            if (answers === item.id) {
                classes = classes + ' btn-lg'
            }
            btn.push(<button className={classes} key={item.id} onClick={() => {
                __saveAnswer(item.id)
            }}>{item.title}
            </button>)
        });

        return (btn);
    }

    return (<div className="btn-support-question">

        {__renderButton()}
    </div>);
};

export default SupportQuestion;