import * as types from "../types/index";
import { setInitialState } from "../InitialState";

const INITIAL_STATE = {
  isValid: false,
  isError: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.IS_VALID:
      return {
        ...state,
        isValid: true,
        isError: false,
      };
    case types.IS_INVALID:
      return {
        ...state,
        isValid: false,
        isError: true,
      };
    default:
      return {
        ...state,
      };
  }
}
