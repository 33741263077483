import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';

export function survey_start_action(data) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/proposal/survey/start`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS
            },
            body: JSON.stringify(data),
            types: [
                types.SURVEY_START_REQUEST,
                types.SURVEY_START_RECEIVE, {
                    type: types.SURVEY_START_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetSurvey_start_action = () => {
    return {
        type: types.RESET_SURVEY_START,
        payload: null
    }
};

export function survey_answers_action(token, answer) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/proposal/survey/submitanswer`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(answer),
            types: [
                types.ANSWERS_REQUEST,
                types.ANSWERS_RECEIVE, {
                    type: types.ANSWERS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetSurvey_answers_action = () => {
    return {
        type: types.RESET_ANSWERS,
        payload: null
    }
};

export function survey_end_action(token, answer) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/proposal/survey/end`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(answer),
            types: [
                types.SURVEY_END_REQUEST,
                types.SURVEY_END_RECEIVE, {
                    type: types.SURVEY_END_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetSurvey_end_action = () => {
    return {
        type: types.RESET_SURVEY_END,
        payload: null
    }
};

export function survey_result_action(token, answer) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/proposal/survey/result`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(answer),
            types: [
                types.SURVEY_RESULT_REQUEST,
                types.SURVEY_RESULT_RECEIVE, {
                    type: types.SURVEY_RESULT_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetSurvey_result_action = () => {
    return {
        type: types.RESET_SURVEY_RESULT,
        payload: null
    }
};


export function survey_support_action(token, answer) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/proposal/survey/sharethoughts`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(answer),
            types: [
                types.SURVEY_SUPPORT_REQUEST,
                types.SURVEY_SUPPORT_RECEIVE, {
                    type: types.SURVEY_SUPPORT_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const resetSurvey_support_action = () => {
    return {
        type: types.RESET_SURVEY_SUPPORT,
        payload: null
    }
};