import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as _ from "lodash";
import moment from "moment";
import {
  ListWrapper,
  Animi,
  Loader,
  ReactangleLoaderView,
} from "../../components";
import { Images, StringConstants } from "../../config";

// redux
import { timeline_action } from "../../stores/actions";
import CommonFooter from "../Legals/CommonFooter";

const Timeline = (props) => {
  const dispatch = useDispatch();

  const timelineData = useSelector((state) => state.timeline);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [scrollIndex, setScrollIndex] = useState(null);

  useEffect(() => {
    window.scroll(0, 0);
    try {
      if ((timelineData.isFetching === false) & (timelineData.data === null)) {
        dispatch(timeline_action());
      }
    } catch (e) {
      console.log("news error", e);
    }
  }, []);

  useEffect(() => {
    if (timelineData.isSuccess === true && timelineData.data !== null) {
      let date = moment().format("MMMM YYYY");

      _.each(timelineData.data.data.timeline, (item, id) => {
        if (item.timelineCurrent === true) {
          setCurrentIndex(id);
          setScrollIndex(id);
        }
      });
    }
  }, [timelineData]);

  useEffect(() => {
    let Timer = setTimeout(() => {
      if (scrollIndex !== null) {
        let ele = document.getElementById("timeline_" + scrollIndex);
        if (ele) ele.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 2500);

    return () => {
      clearTimeout(Timer);
    };
  }, [scrollIndex]);

  const __renderFork = (item, key, current) => {
    let approve = [],
      rejected = [];

    let active = "";
    _.each(item, (item, id) => {
      let clNames = "p-4 listing ";

      if (item.forkCompleted && current) {
        clNames = "p-4 listing active";
        if (item.forkCompleted && item.forkType === "approved") {
          active = item.forkType;
        } else if (item.forkCompleted && item.forkType === "rejected") {
          active = item.forkType;
        }
      } else {
        if (item.forkCompleted) {
          active = item.forkType;
          clNames = "p-4 listing active";
        }
      }

      let obj = (
        <ListWrapper key={"fk" + key + "_s" + id}>
          <div className={clNames}>
            <h6 className="forkTitle">{item.forkTitle}</h6>
            <p className="bold mb-0 forkDate">{item.forkDate}</p>
          </div>
        </ListWrapper>
      );

      if (item.forkType === "approved") {
        approve.push(obj);
      } else {
        rejected.push(obj);
      }
    });

    return (
      <div className="container ">
        <div className="timeline-fork pt-3 pb-3">
          <div className="fork-wpr fork-wpr-mobile">
            <div
              className={
                active === "approved"
                  ? "fork-cnt active-state"
                  : "fork-cnt rejected-state"
              }
            >
              <div className="d-flex">
                <div className="pb-1">
                  <h4 className="title approve">{StringConstants.approved}</h4>
                </div>
                <div className="or_ui text-center flex-grow-1 hide-mobile">
                  <span className="d-inline-block text">
                    {StringConstants.or}
                  </span>
                </div>
              </div>

              <div>{approve}</div>
            </div>
            {/* {
                            rejected .length !== 0 ? */}
            <div className="or_ui text-center flex-grow-1 hide-desktop">
              <span className="d-inline-block text">{StringConstants.or}</span>
            </div>
            {/* : null
                         } */}

            {/* {
                            rejected .length !== 0 ? */}
            <div
              className={
                active === "rejected"
                  ? "fork-cnt active-state"
                  : "fork-cnt rejected-state"
              }
            >
              <div className="pb-1">
                <h4 className="title">{StringConstants.rejected}</h4>
              </div>
              <div>{rejected}</div>
            </div>
            {/* : null
                        } */}
          </div>
        </div>
      </div>
    );
  };

  const __renderTimeline = () => {
    if (timelineData.data === null) {
      return;
    }

    let arr = [];
    let tDt = timelineData.data.data.timeline;

    let currentIsFalse = false;

    _.each(tDt, (item, id) => {
      if (item.timelineCurrent === true) {
        currentIsFalse = true;
      }

      var className = "timeline-cnt timeline-pad-right";

      if (item.timelineCurrent === true) {
        className = className + " current";
      } else if (id > currentIndex) {
        // || id < currentIndex
        className = className + " disable";
      }

      arr.push(
        <Animi.FadeInAnimationSecDiv delay={(id + 1) * 300} key={id}>
          <div className={(id % 2 === 0) ? "container " : "container striped"} key={"tl" + id}>
            {item.timelineCurrent === true && (
              <div className="circleIcon-wpr">
                <div className="circleIcon" />
              </div>
            )}
            <div className={className}>
              <div id={"timeline_" + id} style={{ width: 10, height: 10, background: "transparent", position: "absolute", top: -120 }}></div>
              <ListWrapper>
                <div className="p-4 listing pad-right">
                  <h4 className="title">{item.timelineTitle}</h4>
                  <p className="date bold  pb-0 mb-0">{item.timelineDate}</p>
                  {item.timelineDescription !== null && (
                    <p className="desc mt-3  pb-0 mb-0">
                      {item.timelineDescription}
                    </p>
                  )}
                </div>
                {item.timelineForks.length > 0 && (
                  <div
                    className="pt-3 pb-4 pl-4 pr-4"
                    style={{ borderTop: "1px solid #cccccc" }}
                  >
                    <p
                      className=" pb-0 mb-0 d-flex font-weight-bold note"
                      style={{ fontWeight: "bold", fontSize: "0.9rem" }}
                    >
                      <img src={Images.info} alt="" className="pr-3 " />
                      {StringConstants.timeline_next_stage}
                    </p>
                  </div>
                )}
              </ListWrapper>
            </div>
            <div className={className}>
              {item.timelineForks.length > 0 &&
                __renderFork(item.timelineForks, id, item.timelineCurrent)}
            </div>
          </div>
        </Animi.FadeInAnimationSecDiv>
      );
    });

    return arr;
  };
  const __renderProposalLoader = (val) => {
    let list = [];
    let list_all = [1, 2, 3, 4, 5];
    _.each(list_all, (item, id) => {
      list.push(
        <div className="px-4 pt-3 pb-2 project-config loading-div" key={id}>
          <div className="main-div">
            <div className="second-div width-70">
              <Loader type={"view"} row={4}>
                {" "}
              </Loader>
              <div className="main-div-new">
                <div className="f1">
                  <Loader type={"text"} row={1}>
                    {" "}
                  </Loader>
                </div>
                <div className="f2">
                  <Loader type={"text"} row={1}>
                    {" "}
                  </Loader>
                </div>
              </div>
            </div>
            <div className="first-div-new">
              <Loader type={"view"} row={1}>
                {" "}
              </Loader>
            </div>
          </div>
        </div>
      );
    });
    return list;
  };

  return (
    <>
      <div>
        {timelineData.isSuccess ? (
          <div className="timeline-wpr container-top1">
            {__renderTimeline()}
          </div>
        ) : (
          __renderProposalLoader()
        )}
      </div>

      <div className="text-center postion-center bottom50">
        <CommonFooter />
      </div>
    </>
  );
};

export default Timeline;
