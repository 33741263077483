import React from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import App from "../App";
import {
  Hub,
  ProjectDetails,
  OurProposalsDetails,
  Proposals,
  ProposalDetails,
  News,
  NewsDetails,
  Archive,
  Timeline,
  Survey,
  SurveySummary,
  SurveyResult,
  SupportSurvey,
  SupportSurveySummary,
  SupportSurveyThankyou,
  Tnc,
  PrivacyPolicy,
  Cookies,
  Contact,
} from "../screens";
import { Config,NavigationConstants } from "../config";

const AppNavigation = (props) => {
  return (
    <Switch>
      <App>
        <>
          <Route path="/" exact component={Hub} />
          <Route path={NavigationConstants.background.url} exact component={ProjectDetails} />
          <Route path={NavigationConstants.our_proposals.url} exact component={OurProposalsDetails} />
          <Route path="/support-survey" exact component={SupportSurvey} />
          <Route path="/support-summary" exact component={SupportSurveySummary} />
          <Route path="/support-thankyou" exact component={SupportSurveyThankyou} />
          <Route path="/term-and-condition" exact component={Tnc} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/cookies" exact component={Cookies} />
        </>
        {Config.menu_proposals && (
          <>
            <Route path="/yoursay">
              <Redirect to={NavigationConstants.share_your_views.url} />
            </Route>
            <Route path={NavigationConstants.share_your_views.url} exact component={Proposals} />
            <Route
              path={NavigationConstants.share_your_views.url +"/:proposalSlug"}
              exact
              component={ProposalDetails}
            />
            <Route path={NavigationConstants.share_your_views.url + "/start/:proposalId"} exact component={Survey} />
            <Route
              path={NavigationConstants.share_your_views.url +  "/summary/:proposalId"} 
              exact
              component={SurveySummary}
            />
            <Route
              path={NavigationConstants.share_your_views.url + "/result/:proposalId"}
              exact
              component={SurveyResult}
            />
          </>
        )}
        {Config.menu_timeline && (
          <Route path={NavigationConstants.timeline.url} exact component={Timeline} />
        )}
        {Config.menu_news && (
          <>
            <Route path={NavigationConstants.news_events.url} exact component={News} />
            <Route path={NavigationConstants.news_events.url+"/:newsSlug"} exact component={NewsDetails} />
          </>
        )}
        {Config.menu_archive && (
          <Route path={NavigationConstants.archive.url} exact component={Archive} />
        )}
      </App>
    </Switch>
  );
};

export default AppNavigation;
