import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


// redux
import { survey_result_action, resetSurvey_result_action } from '../../stores/actions'
import { ListWrapper } from '../../components';
import * as _ from 'lodash';
import { StringConstants } from '../../config';
import CommonFooter from '../Legals/CommonFooter';

const SurveyResult = (props) => {
    const dispatch = useDispatch();
    const data = props.location.state;
    const token = props.location.state.token;

    const [resultDataState, setResultDataState] = useState(null)

    const resultData = useSelector(state => state.survey_result);

    useEffect(() => {
        try {
            dispatch(survey_result_action(token, { slug: data.slug, result_id: data.resultId }));
        } catch (e) {
            console.log(e)
        }
    }, []);

    useEffect(() => {

        if (resultData.isSuccess && resultData.data !== null) {
            setResultDataState(resultData.data.data)
            dispatch(resetSurvey_result_action())
        } else if (resultData.isError) {
            dispatch(resetSurvey_result_action())
        }
    }, [resultData]);





    const __renderAnswerType = (data, type) => {
        if (resultDataState === null) {
            return null
        }

        const arr = [];

        _.each(data, (res, id) => {
            if (type === "QT03" || type === "QT04") {
                arr.push(
                    <div className="col-6 d-flex pb-4" key={"key_" + id}>
                        <div className="d-flex align-items-center flex-fill answer pt-3" key={id}>
                            <div className="pr-3 percentage">{res.percentage}%</div>
                            <div className="answerTitle">{res.option}</div>
                        </div>
                    </div>
                )
            } else if (type === "QT07") {
                arr.push(
                    <div className="col-6 d-flex pb-4" key={"key_" + id}>
                        <div className={"d-flex align-items-center flex-fill answer pt-3 " + "ans" + res.optionNo} key={id}>
                            <div className="pr-3 percentage">{res.percentage}%</div>
                            <div className="answerTitle">{res.option}</div>
                        </div>
                    </div>
                )
            } else if (type === "QT05" || type === "QT06") {
                arr.push(
                    <div className="col-6 d-flex pb-4" key={"key_" + id}>
                        <div className="d-flex align-items-center flex-fill answer" key={id}>
                            <div className="">
                                <img src={res.option} alt="" style={{ width: 120, height: 120 }} />
                            </div>
                            <div className="pl-4 percentage">{res.percentage}%</div>
                            {/* <div className="pl-4 percentage">{res.percentage}%</div> */}
                        </div>
                    </div>
                )
            }

        });

        return (<div className="row row-block">
            {arr}
        </div>)
    }

    const __renderResult = () => {
        if (resultDataState === null) {
            return null
        }

        const array = [];

        _.each(resultDataState?.proposalResult, (res, id) => {
            if ((res.questionType === "QT01" || res.questionType === "QT02" || res.questionType === "QT08")) {
                return null
            } else {
                array.push(<div key={id} className="py-3 survey-result">
                    <div className="questionIndex">{id + 1}</div>
                    <h6 className="question">{res.questionTitle}</h6>
                    {/* {
                    (res.questionType === "QT01" || res.questionType === "QT02" || res.questionType === "QT08") &&

                    <div className="col-6 d-flex pb-4">
                        <div className="d-flex align-items-center  flex-fill answer" key={res.questionType}>
                            <div className="pl-0">Open ended</div>
                        </div>
                    </div>
                } */}
                    {__renderAnswerType(res.options, res.questionType)}
                </div >);
            }

        });

        return (array)
    }

    return (
        <>
            <div className="container p-3 pt-4 survey-final-result">
                <ListWrapper>
                    <div className="p-4 m-2 listing survey-result-wpr">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h3 className="title">{StringConstants.community_said}</h3>
                                <p className="font-weight-bold">{StringConstants.survay_final_result_text}</p>
                            </div>
                        </div>

                        { __renderResult() }
                    </div>
                </ListWrapper>
            </div>

            <div className="text-center postion-center">
                <CommonFooter />
            </div>

        </>);
}


export default SurveyResult;
