import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Images } from '../config'


const Filters = props => {

    // states
    const [category, setCategory] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const [query, setQuery] = useState('');

    // display loader if categories data not ready
    if (props.categories === null) {
        return (
            <div>Loading</div>
        )
    }



    const __categories = () => {
        let cat = [<button type="button" className={"btn btn-default searchButton"}
            onClick={() => {
                setShowSearch(!showSearch);
            }}

            key={101}
        ><img src={Images.search} alt="" /></button>,
        <button type="button" className={category === '' ? "btn btn-default active" : "btn btn-default"}
            onClick={() => {
                setCategory('');
                props.onSubmit({ id: '', q: query });
            }}
            key={100}
        >{"All"}</button>]
        _.each(props.categories, (item, id) => {
            cat.push(
                <button type="button" className={category.id === item.id ? "btn btn-default active" : "btn btn-default"}
                    key={item.id}
                    onClick={() => {
                        setCategory(item);
                        props.onSubmit({ id: item.id, q: query });
                    }}
                >{item.name}</button>
            )
        });
        return cat
    }


    return (
        <div className="d-md-flex pt-4 pb-3">
            <div className="flex-grow-1 filters pt-1">
                {__categories()}
            </div>

            <div className={showSearch ? "searchfield show search-mobile" : "searchfield"}>
                <input type="search" className="form-control serach" placeholder={props.placeholder || "enter keyword"} onChange={
                    (event) => {
                        setQuery(event.target.value)
                        props.onSubmit({ q: event.target.value, id: category === '' ? '' : category.id })
                    }
                }
                    style={{ backgroundImage: `url(${Images.search})` }}
                />
            </div>

        </div>
    );
};

export default Filters;