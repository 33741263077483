import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// redux
import {
  support_survey_start_action,
  support_survey_answers_action,
  reset_support_survey_answers_action,
  resetSurvey_start_action,
  support_survey_end_action,
  resetSurvey_end_action,
  survey_support_action,
  resetSurvey_support_action,
  reset_support_survey_start_action,
  reset_support_survey_end_action,
} from "../../stores/actions";
import {
  ListWrapper,
  ShareVia,
  TextInput,
  QT07,
  BackButton,
  SupportQuestion,
  ReactangleLoaderView,
  Loader,
} from "../../components";
import * as _ from "lodash";
import { Images, StringConstants } from "../../config";
import CommonFooter from "../Legals/CommonFooter";

const Survey = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const proposalId = props.match.params.proposalId;
  const [token, setToken] = useState("");

  const [baseProposal, setBaseProposal] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(1);
  const [finishSurvey, setFinishSurvey] = useState(0);
  const [answer, setAnswer] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [email, setEmail] = useState(null);
  const [isTNC, setIsTNC] = useState(null);
  const [isError, setIsError] = useState(null);
  const [nextButtonText, setNextButtonText] = useState("Next");
  const [noOfQustions, setNoOfQustions] = useState(0);
  const [supportModule, setSupportModule] = useState(0);
  const [supportAnswer, setSupportAnswer] = useState(null);
  const [backButton, setBackButton] = useState(true);

  const [isMendatory, setIsMendatory] = useState(false);
  const [oldAnswerKey, setOldAnswerKey] = useState("");

  const [activequestioncond, setActiveQuestionCond] = useState("");
  const [onlyonce, setonlyOnce] = useState(false);

  const answersData = useSelector((state) => state.support_answer);
  const supportStartData = useSelector((state) => state.support_start);
  const surveyEndData = useSelector((state) => state.support_end);
  const projectConfigData = useSelector((state) => state.projectconfig);

  const [supportMobalisationData, setSupportMobalisationData] = useState({
    title: "Do you support this project?",
    description:
      "Take this short survey to help us gauge your opinion on the project.",
  });

  const nextButtonRef = useRef();

  useEffect(() => {
    window.scroll(0, 0);
    reset_support_survey_answers_action();
    try {
      dispatch(support_survey_start_action());
    } catch (e) {
      console.log(e);
    }

    return () => {
      __resetSurvey();
    };
  }, []);

  useEffect(() => {
    if ((answersData.isSuccess || answersData.isError) && answer !== null) {
      if (!onlyonce) {
        setonlyOnce(true);
        reset_support_survey_answers_action();
        setTimeout(() => {
          __nextQuestion();
        }, 2000);
      }
    }

    if (surveyEndData.isSuccess && surveyEndData.data !== null) {
      window.scroll(0, 0);
      history.push("/support-summary/", {
        data: surveyEndData.data.data,
        token: token,
      });
      resetSurvey_end_action();
    }
  }, [answersData, surveyEndData]);

  useEffect(() => {
    if (
      supportStartData.data !== null &&
      supportStartData.data.data.supportQuestions.length > 0
    ) {
      setBaseProposal(supportStartData.data.data);
      setNoOfQustions(supportStartData.data.data.supportQuestions.length);
      setToken(supportStartData.data.data.projectSupportSessionToken);
    }
  }, [supportStartData]);

  useEffect(() => {
    if (
      projectConfigData.data !== null &&
      projectConfigData.data.data.support_mobilisation
    ) {
      setSupportMobalisationData(
        projectConfigData.data.data?.support_mobilisation
      );
    }
  }, [projectConfigData]);

  const __resetSurvey = () => {
    try {
      dispatch(reset_support_survey_start_action());
      dispatch(reset_support_survey_answers_action());
      dispatch(reset_support_survey_end_action());

      setActiveQuestion(0);
      setFinishSurvey(0);
      setSupportModule(0);
    } catch (e) {
      console.log(e);
    }
  };
  const __summary = () => {
    return (
      <ListWrapper prime={true}>
        <div className="p-4 m-2">
          <div className="d-flex flex-mobile-column">
            <div className="flex-grow-1">
              <h3 className="title">{supportMobalisationData.title}</h3>
            </div>
            <ShareVia
              url={window.location.href}
              title={"Share Your Views…"}
              white={false}
            />
          </div>
          <div className="mt-2">
            <p>{supportMobalisationData.description}</p>
          </div>
          <div className="mb-1 mt-2 post-details">
            <p className="mt-2">
              <span className="font-weight-bold">Disclaimer:</span> I agree that
              Kanda may anonymise my data and share my responses and/or such
              data, along with other respondents’ anonymised data, in the public
              domain.
            </p>
          </div>
        </div>
      </ListWrapper>
    );
  };

  const __renderAnswerType = (question) => {
    return (
      <QT07
        page={2}
        data={question}
        onSubmit={(res) => {
          setAnswer(res);
        }}
      />
    );
  };

  const __saveSupportAnswer = () => {
    if (supportAnswer !== null) {
      setNextButtonText("Saving...");
      setSupportModule(0);
      try {
        dispatch(survey_support_action(token, { thought: supportAnswer }));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const __saveAnswer = (question) => {
    if (answer !== null) {
      setNextButtonText("Saving...");
      setIsMendatory(false);
      try {
        if (answer.questionId === oldAnswerKey.questionId) {
          var post_data = {
            additional_thoughts: "",
            answer: question.options[2].optionId,
            questionId: question.questionId,
          };
          setOldAnswerKey(post_data);
          setAnswer(post_data);
          dispatch(support_survey_answers_action(token, post_data));
        } else {
          setOldAnswerKey(answer);
          dispatch(support_survey_answers_action(token, answer));
        }
      } catch (e) {
        console.log(e);
      }
    } else if (answer === null) {
      // __nextQuestion();
      setAnswer(question.options[2].optionId);
      setNextButtonText("Saving...");
      setIsMendatory(false);
      var post_data = {
        additional_thoughts: "",
        answer: question.options[2].optionId,
        questionId: question.questionId,
      };
      setOldAnswerKey(post_data);
      setAnswer(post_data);
      try {
        dispatch(support_survey_answers_action(token, post_data));
      } catch (e) {
        console.log(e);
      }
    }
    setonlyOnce(false);
  };

  const __setMapAnswers = (answer, type) => {
    setAnswer({
      answer: JSON.stringify(answer),
      questionId: baseProposal.supportQuestions[activeQuestion - 1].questionId,
    });
  };

  const __nextQuestion = () => {
    // if(activequestioncond !== activeQuestion){

    setActiveQuestionCond(activeQuestion);
    if (activeQuestion === noOfQustions) {
      setFinishSurvey(1);
    }

    if (activeQuestion === noOfQustions) {
      setNextButtonText("Saving...");
    } else {
      setNextButtonText("Next");
    }

    //setAnswer(null);
    setActiveQuestion(activeQuestion + 1);
    // }
  };

  const __questions = () => {
    let questions = [];

    _.each(baseProposal.supportQuestions, (item, id) => {
      let currentId = id + 1;
      if (activeQuestion === currentId) {
        questions.push(
          <div id={id} className={"survey-questions p-3 mb-3 active"} key={id}>
            <div className="questionIndex">{currentId}</div>

            <p className="text-primary font-weight-bold">
              {StringConstants.question} {currentId}/{noOfQustions}
            </p>
            <h6 className="question">{item.questionTitle}</h6>

            {__renderAnswerType(item)}

            {isMendatory && (
              <div className="alert alert-danger" role="alert">
                {StringConstants.please_select_appro_ans}
              </div>
            )}
            <div className="d-flex justify-content-end">
              <button
                ref={nextButtonRef}
                className="btn btn-outline-primary d-flex greenchevron do-support-btn"
                onClick={() => {
                  __saveAnswer(item);

                  if (
                    baseProposal.supportQuestions.length === activeQuestion
                  ) {
                    setBackButton(false);
                  }
                }}
              >
                {nextButtonText}
                <span className="icon ml-3 mt-2"></span>
              </button>
            </div>
          </div>
        );
      } else if (currentId < activeQuestion) {
        let classNm =
          currentId === activeQuestion - 1 ? "previous" : "previousNegative1";
        questions.push(
          <div
            id={id}
            className={`survey-questions ${classNm} p-3 mb-3`}
            key={id}
          >
            <div className="questionIndex">{currentId}</div>
            <h6 className="question">{item.questionTitle}</h6>
          </div>
        );
      } else {
        questions.push(
          <div id={id} className={"survey-questions p-3 mb-3"} key={id}>
            <div className="questionIndex">{currentId}</div>
            <h6 className="question">{item.questionTitle}</h6>
          </div>
        );
      }
    });
    return questions;
  };

  const __submitForm = () => {
    try {
      if (isTNC) {
        let body = {
          name: fullname,
          email: email,
          postcode: postcode,
          type: "submit",
        };

        dispatch(support_survey_end_action(token, body));
      } else {
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  const __skipSurveyForm = () => {
    try {
      let body = {
        name: "",
        email: "",
        postcode: "",
        type: "skip",
      };
      dispatch(support_survey_end_action(token, body));
    } catch (exception) {
      console.log(exception);
    }
  };

  const __userDemoGraphics = () => {
    return (
      <div className="thank-you p-2">
        <h3 className="thankyoutext">
          {StringConstants.user_demo_graphic_heading}
        </h3>
        <p>{StringConstants.user_demo_graphic_decs}</p>
        <form
          type="post"
          onSubmit={(e) => {
            e.preventDefault();

            if (isTNC === null) {
              setIsTNC(false);
            }

            if (
              (fullname !== null || email !== null || postcode !== null) &&
              isTNC === null
            ) {
              setIsTNC(false);
            }

            if (
              (fullname === null || email === null || postcode === null) &&
              isTNC
            ) {
              !surveyEndData.isFeching && __skipSurveyForm();
            }

            if (
              (fullname !== null || email !== null || postcode !== null) &&
              isTNC
            ) {
              !surveyEndData.isFeching && __submitForm();
            }
          }}
        >
          <div className="pt-3">
            <TextInput
              label="Full Name"
              id="fullname"
              type="text"
              required
              onSubmit={(res) => {
                setFullname(res);
              }}
            />

            <TextInput
              label="Post Code"
              id="postcode"
              type="text"
              required
              onSubmit={(res) => {
                setPostcode(res);
              }}
            />

            <TextInput
              label="Email Address"
              id="email"
              type="email"
              required
              onSubmit={(res) => {
                setEmail(res);
              }}
            />

            <p className={isError ? "text-danger" : "d-none"}>

              {StringConstants.all_fields_mandatory}
            </p>

            <div className="tnc container-one">
              <div className="customeRadio-one">
                <input
                  type="checkbox"
                  id="tnc"
                  className="mr-3"
                  onChange={(event) => {
                    setIsTNC(event.target.checked);
                  }}
                />
                <span className="checkmark"></span>
              </div>
              {StringConstants.survay_agree_tc}
              <Link to="/privacy-policy" target="_blank">
                {StringConstants.tc}
              </Link>
            </div>
            {isTNC !== null && (
              <p className={isTNC === false ? "text-danger" : "d-none"}>
                {StringConstants.survay_tc}
              </p>
            )}
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-outline-secondary mr-2 d-flex graychevron"
                type="reset"
                onClick={() => {
                  !surveyEndData.isFeching && __skipSurveyForm();
                }}
              >
                {StringConstants.skip} <span className="icon ml-3 mt-2"></span>
              </button>
              <button className="btn btn-outline-primary d-flex greenchevron">
                {StringConstants.done} <span className="icon ml-3 mt-2"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const __displayModules = () => {
    if (!finishSurvey) {
      return (
        <div className="pl-5 question-wpr">{baseProposal && __questions()}</div>
      );
    } else if (finishSurvey && supportModule) {
      return (
        <div className=" pb-3">
          <h4 className="pb-3">
            {baseProposal.proposal.proposalPromptSupportQuestion}
          </h4>
          <SupportQuestion
            onSubmit={(res) => {
              setSupportAnswer(res);
            }}
          />

          <div className="d-flex justify-content-end pt-4">
            <button
              className="btn btn-outline-primary d-flex greenchevron"
              onClickCapture={() => {
                __saveSupportAnswer();
              }}
            >
              {nextButtonText}
              <span className="icon ml-3 mt-2"></span>
            </button>
          </div>
        </div>
      );
    } else {
      return __userDemoGraphics();
    }
  };

  const __renderProposalLoader = (val) => {
    let list = [];
    let list_all = [1, 2, 3, 4, 5];
    _.each(list_all, (item, id) => {
      list.push(
        <div className="pt-3 pb-2 project-config loading-div loading-div-praposal">
          <div style={{ boxShadow: "0 2px 64px 0 rgba(0, 0, 0, 0.1)" }}>
            <div className="main-div" style={{ boxShadow: "none" }}>
              <div className="second-div width-70" style={{ paddingLeft: 0 }}>
                <Loader type={"view"} row={1} />
              </div>
              <div className="first-div-new">
                <Loader type={"view"} row={1} />
              </div>
            </div>
            <div className="archiv-div-main">
              <div>
                <Loader type={"text"} row={1} />
              </div>
            </div>
            <div className="archiv-div-main">
              <div>
                <Loader type={"text"} row={1} />
              </div>
            </div>
          </div>
        </div>
      );
    });

    return list;
  };

  const __backStep = () => {
    setIsMendatory(false);
    setActiveQuestion(activeQuestion - 1);
  };

  const __backButtonUI = () => {
    return activeQuestion > 1 ? (
      <button
        className="btn btn-outline-primary d-flex greenchevron rotate-left"
        onClick={() => {
          __backStep();
        }}
      >
        <span className="icon mr-2 mt-2"></span> Back
      </button>
    ) : null;
  };

  return (
    <>
      <div
        style={{ height: "100vh", width: "100%" }}
        className={"doyousupport-main"}
      >
        <BackButton basepage="Your Say" />
        {/* {baseProposal && __renderSurveyType()} */}

        <div className={"container p-5 pt-4"}>
          <div className="surveyDetails surveyDetails-support">
            {!supportStartData.isSuccess ? (
              __renderProposalLoader()
            ) : (
              <div>
                {__summary()}
                <ListWrapper>
                  <div className="p-4 p-d0">
                    {backButton ? __backButtonUI() : null}
                    {__displayModules()}
                  </div>
                </ListWrapper>
              </div>
            )}

            {/* {baseProposal && __summary()}

                        {baseProposal &&
                            <ListWrapper>
                                <div className="p-4">
                                    {__displayModules()}
                                </div>
                            </ListWrapper>
                        } */}
          </div>
        </div>
      </div>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default Survey;
