import React from 'react';
import {TextBlock, MediaBlock, TextRow, RectShape, RoundShape,RealComponent} from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

const ReactangleLoaderView = props => {
    return (
       
        // <RectShape showLoadingAnimation={true}  ready={false} color='red' style={{ width: props.imagewidth, height: props.imageheight }} />   
        <ReactPlaceholder showLoadingAnimation={true}  ready={false} type='rect' ready={false} color='#E0E0E0' style={{ width: props.imagewidth, height: props.imageheight }}>
            
        </ReactPlaceholder>

        );
};

export default ReactangleLoaderView;