export const ENVIRONMENT = process.env.REACT_APP_ENV;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const REACT_APP_X_AUTHORIZATION =
  process.env.REACT_APP_REACT_APP_X_AUTHORIZATION;
export const AUTH_HEADERS = {
  "X-Authorization": "7fa88a29503ff00b31359e83dc4540bb11e3360f",
  "Content-Type": "application/json",
};
export const APP_KEY = process.env.REACT_APP_SECURE_KEY;

export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR

export const GOOGLE_RECAPTCH_SITE_KEY = process.env.REACT_APP_RECAPTCH_SITE_KEY