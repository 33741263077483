import React from "react";
import { Images } from "../config";
import { useHistory } from "react-router-dom";


const PrimaryImageLayout = (props) => {
  let history = useHistory(); 

  return (
    <div className="prime-content">
      <div className="image-container">
        <div className="leftimg">
          <img className={`hoverimg ${props.isAnimated ? 'animation' : ''}`} src={props.image} alt="" />
        </div>

        {props.basepage && (
          <button
            className="btn btn-default text-primary back-button-gen p-2 pl-4 pr-3 bold"
            onClick={() => {
              history.goBack();
            }}
          >
            <img src={Images.paginationback} alt="" className="mr-3 pb-1" />
            Back to {props.basepage}
          </button>
        )}
      </div>
      <div className="content-wrapper">{props.children}</div>
    </div>
  );
};

export default PrimaryImageLayout;
