import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

// redux
import { ListWrapper, ShareVia } from "../../components";
import { StringConstants } from "../../config";
import { SupportMobalisationList } from "../../components";
import CommonFooter from "../Legals/CommonFooter";

const SupportSurveySummary = (props) => {
  const data = props.location.state.data;
  const result = data.result;
  const breakDown = data.supportSurveyBreakdown;
  const [categoriesData, setCategoriesData] = useState([]);
  const [projectData, setProjectData] = useState();
  const [supportMobalisationData, setSupportMobalisationData] = useState({
    title: "Do you support this project?",
    description:
      "Take this short survey to help us gauge your opinion on the project.",
  });
  const projectConfigData = useSelector((state) => state.projectconfig);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (projectConfigData.data !== null) {
      setCategoriesData(projectConfigData.data.data.categories.proposal);
      setProjectData(projectConfigData.data.data);

      if (projectConfigData.data.data.support_mobilisation) {
        setSupportMobalisationData(
          projectConfigData.data.data?.support_mobilisation
        );
      }
    }
  }, [projectConfigData]);

  const __reasultBreakDown = () => {
    let list = [];
    _.each(breakDown, (item) => {
      list.push(
        <div className="support-survey-result d-flex">
          <div className="your-answer p-3">
            <p className="text-primary text-center font-weight-bold">
              {item.answer}
            </p>
          </div>
          <div className="flex-grow-1 p-3">
            <p className="break-word">{item.question}</p>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className="calculation">
          <h5>{StringConstants.calculate_support_project}</h5>
          <p className="font-weight-bold">
            {" "}
            {StringConstants.break_down_ans_support_survay}{" "}
          </p>
        </div>
        {list}
      </>
    );
  };

  return (
    <>
      <div className="container p-5 pt-4 padding-mobile-new">
        <div className="supportSurvey">
          <ListWrapper>
            <div className="sp-header">
              <div className="p-4 m-2">
                <div className="d-flex flex-mobile-column">
                  <div className="flex-grow-1">
                    <h3 className="title">{supportMobalisationData.title}</h3>
                  </div>
                  <ShareVia
                    url={window.location.origin + '/support-survey'}
                    title={"Share Your Views…"}
                    white={false}
                  />
                </div>
                <div className="mt-2">
                  <p className="mb-0">{supportMobalisationData.description}</p>
                </div>
              </div>
            </div>

            <div className="px-4 pt-4 m-0 listing sup-sum-pad">
              {__reasultBreakDown()}
            </div>

            <div className="sp-footer">
              <div className="p-4 m-2 ">
                {/* pad-mob */}
                <div className="d-flex d-flex-mobile">
                  <div className="rslt pr-4 ">
                    {/* pad-mar */}
                    <div className="result-wpr">
                      <h4>
                        {result}
                        <span className="psign">%</span>
                      </h4>
                    </div>
                  </div>
                  <div className="flex-grow-1 ">
                    <h3 className="title">{StringConstants.your_results} </h3>
                    <p className="mb-1 post-details font-weight-bold">
                      {" "}
                      {StringConstants.calcu_current_proposal}{" "}
                    </p>
                  </div>
                  <div className="next mt-3">
                    <Link
                      className="btn btn-outline-primary d-flex greenchevron"
                      to={{
                        pathname: "/support-thankyou",
                        state: data,
                      }}
                    >
                      {StringConstants.next}{" "}
                      <span className="icon ml-3 mt-2"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </ListWrapper>
        </div>
      </div>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default SupportSurveySummary;
