import React from "react";

import styled, { keyframes } from "styled-components";
import {
  fadeIn,
  fadeInDown,
  zoomIn,
  fadeOutRight,
  flip,
  merge,
} from "react-animations";

const FadeInAnimationDiv = (props) => {
  const duration = props.duration || 0.5;
  const delay = props.delay || 0.3;

  const fadeInAnimation = keyframes`${fadeIn}`;

  const Animation = styled.div`
    animation: ${duration}s ${fadeInAnimation};
    animation-fill-mode: forwards;
  `;
  // animation-delay: ${delay}s;
  return <Animation>{props.children}</Animation>;
};

const FadeInAnimationDivSupportMobilisation = (props) => {
  const duration = props.duration || 0.5;
  const delay = props.delay || 0.3;

  const fadeInAnimation = keyframes`${fadeIn}`;

  const Animation = styled.div`
    animation: ${duration}s ${fadeInAnimation};
    animation-fill-mode: forwards;
  `;
  // animation-delay: ${delay}s;
  return <Animation>{props.children}</Animation>;
};
const FadeInAnimationSecDiv = (props) => {
  const duration = props.duration || 0.5;
  const delay = props.delay || 0.3;

  const fadeInAnimation = keyframes`{
    0% {opacity:0}
    100% {opacity:1}
  }`;

  const Animation = styled.div`
    opacity: 0;
    animation: ${duration}s ${fadeInAnimation};
    animation-fill-mode: forwards;
    animation-delay: ${delay}ms;
  `;

  return <Animation>{props.children}</Animation>;
};

const ZoomInOutAnimationImage = (props) => {
  const duration = props.duration || 0.3;

  const ZoomIn = keyframes`{
    0% {transform:scale(1)}
    100% {transform:scale(1.1)}
  }`;
  const ZoomOut = keyframes`{
    100% {transform:scale(1)}
    0% {transform:scale(1.1)}
  }`;

  const Animation = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;

    .news-img-small,
    .news-img-large,
    .proposal-img-small,
    .proposal-img-large,
    .proposal-img-landing {
      overflow: hidden;
      animation: ${duration}s ${ZoomOut} ease;
      animation-fill-mode: forwards;

      &:hover {
        animation: ${duration}s ${ZoomIn} ease;
        animation-fill-mode: forwards;
      }
    }
  `;

  return <Animation>{props.children}</Animation>;
};

const ArrowAnimationSpan = (props) => {
  const duration = props.duration || 0.3;

  const ZoomIn = keyframes`{
    0% {background-size:160%}
    100% {background-size:180%}
  }`;
  const ZoomOut = keyframes`{
    100% {background-size:160%}
    0% {background-size:180%}
  }`;

  const Animation = styled.div`
    .news-img-small,
    .news-img-large,
    .proposal-img-small,
    .proposal-img-large,
    .proposal-img-landing {
      overflow: hidden;
      animation: ${duration}s ${ZoomOut} ease;
      animation-fill-mode: forwards;

      &:hover {
        animation: ${duration}s ${ZoomIn} ease;
        animation-fill-mode: forwards;
      }
    }
  `;

  return <Animation>{props.children}</Animation>;
};

const fadeInDownAnimation = keyframes`${fadeInDown}`;

const FadeInDownAnimationDiv = styled.div`
  animation: 1s ${fadeInDownAnimation};
`;

const FadeInAnimationDivSMDiv = styled.div`
  animation: 1s ${FadeInAnimationDivSupportMobilisation};
`;

export {
  FadeInAnimationDiv,
  FadeInDownAnimationDiv,
  FadeInAnimationDivSMDiv,
  ZoomInOutAnimationImage,
  FadeInAnimationSecDiv,
};
