import React from 'react';

const QT08 = props => {
    return (<div key={props.questionId} className="pb-2">
        <textarea className="text-area" rows="4" placeholder="Type your comment here" onChange={(event) => {
            props.onSubmit({ answer: event.target.value, "questionId": props.data.questionId });
        }}
        //updated by veena
        value={props.data.hasOwnProperty('answer') ? props.data.answer.answer : null}
        ></textarea>
    </div>);
};

export default QT08;