export default {
  back: "back",
  next: "Next",
  posted: "Posted",
  ago: "ago",
  rejected: "Rejected",
  approved: "Approved",
  or: "or",
  download: "Download",
  question: "QUESTION",
  done: "Done",
  skip: "Skip",
  email_your_opinion: "Email your opinion",
  email_your_support: "Email your support",
  request_call_back: "Request call back",
  did_you_know: "Did you know",
  project_information: "Project Information",
  responses: "responses",
  start_now: "Start Now",
  view_all: "View All",
  view_results: "View Results",
  your_results: "Your result",
  have_your_say: "Share your views ",
  get_started: "Get started",
  news_events: "News & events",
  news_related: "Why not share your views?",
  t_and_c: "Privacy policy",
  no_document:
    "There are currently no documents available here. Please check back later.",
  no_result_found: "No result found",
  no_survey_result_found:
    "We do not currently have any live focused surveys however if you do have any thoughts that you would like to share on the project please email or call us.",
  please_select_appro_ans:
    "If you do not have an answer please input ‘NA’ as your answer.",
  have_your_say_now: "Share Your Views",
  calculate_support_project:
    "Now… let’s calculate your level of support for the proposals…",
  break_down_ans_support_survay:
    "Below is the breakdown of your answers to our survey.",
  calcu_current_proposal:
    "Based on the above answers, we have calculated your level of support for the project.",
  general_thoughts_project: "Do you support this project?",
  local_council:
    "Complete this survey to help us gauge your opinion on the project.",
  project_info_text: "Want to hear more about the project?",
  find_out_more: "Find out more",
  social: "Social",
  proposal_detail_heading: "Do you want to hear more about the project first?",
  want_to_hear: "Do you want to hear more about the project?",
  proposal_detail_decs:
    "Read more about the project, including the project’s location, the team working on the project, and our ideas before answering this survey.",
  proposal_detail_start_now_message:
    "We are going to ask you a series of questions. When you are ready click ‘Start Now’ to begin the survey. ",
  survay_tc: "Please tick this box to submit your responses",
  survay_agree_tc:
    "By ticking this box I agree that I have read the privacy policy and consent to the given information being processed and used to contact me. ",
  tc: "Privacy Policy",
  all_fields_mandatory: "All fields are mandatory",
  user_demo_graphic_heading:
    "Just one more thing… we’d like to know a little bit more about you.",
  user_demo_graphic_decs:
    "Please enter all or any of the below details to help us make full use of your answers. ",
  // select_answer: "Please select at least one answer",
  select_answer: "Please provide an answer",
  community_said: "What the community has said...",
  survay_final_result_text:
    "Below is the results of the survey so far for other users who have filled in the survey",
  need_little_guidance: "Not sure what to write?",
  little_assitance: "We can give you a little assistance.",
  your_survay_decs:
    "By clicking ‘Help’ below, you will be directed to a survey that can assist you with writing your letter of support.",
  not_going_change_mind_heading: "Not going to change your mind?",
  not_going_change_mind_decs1: "Your opinion is equally as important",
  not_going_change_mind_decs2:
    "We are all about transparency and openess and if you would like to email your thoughts to the council - feel free to use the below link to get to the right person",
  want_to_have_chat_about_heading: "Want to have a chat about it?",
  want_to_have_chat_about_decs1: "A member of the project team can assist",
  want_to_have_chat_about_decs2:
    "You can request a call back from a member of the project team to assist you with any questions you might have on the project. Please provide your contact details by clicking ‘Request call back’ below.",
  get_your_opinion_heard_heading: "Get your opinion heard",
  survay_summary_thankyou_text:
    "This website is updated regularly with the latest news and opportunities to provide further feedback on the project.",
  what_community_said: "What the community has said",
  who_have_filled_survey:
    "Below is the results of the survey so far for other users who have filled in the survey",
  want_to_see_full_filled_survey:
    "Want to see the full available results for this survey? ",
  timeline_next_stage:
    "Depending on the outcome of this consultation the next stage(s) will change",
  thankyou_text: "Thank you for taking the time to complete this survey",
  thankyou_subtext:
    "If we have calculated your opinion correctly, we understand that you take a neutral view of our project (in general)",
  thankyou_cta_text:
    "Would you like to have a call about it with one of our reps?",
  thankyou_cta_subtext:
    "Your opinion means a lot to us and we would love to hear first hand and chat through your project concerns.",
  thankyou_cta_tips: "",
  thankyou_subtext2:
    "We value the local community’s feedback throughout this process. ",
  thankyou_cta_text2: "Maybe we can help with your decision on the project",
  thankyou_cta_subtext2:
    "Find out more about our project and Share Your Views on the current project plans which may help make your opinion",
  thankyou_text3: "Thank you for your support. ",
  thankyou_subtext3:
    "We are happy to hear that you are looking forward to our development. ",
  thankyou_cta_text3: "",
  thankyou_cta_subtext3:
    "Please consider sharing this survey further as we aim to engage with as many local people as possible. ",
  thankyou_tips3: "",
  contact_title: "Contact",
  contact_Email: "Email:",
  contact_phone: "Telephone:",
  Contact_subtext:
    "If you have any questions or would like to speak to a member of the project team you can contact us via phone or email:",
  submit: "Submit",
  invalid_key: "Invalid access key",
  NoResultsText: "There are currently no updates. Please check back here soon.",
  letterassist_text:
    "Do you want to contact your local authority regarding our project?",
  letterassist_subtext:
    "If you would like to email your thoughts directly to the council, feel free to click here to send a direct email to the case officer assigned to our planning application.",
  mini_thankyou_text:
    "Your opinion means a lot to us, and we would love to hear first-hand and chat through any thoughts or concerns you may have regarding our project.",
  thankyounegative_text: "Thank you for taking time to complete this survey",
  thankyounegative_subtext:
    "We are sorry to hear that you are generally unhappy with our project.",
  negative_summary_thankyou_text:
    "This website is updated regularly with the latest news and opportunities to provide further feedback on the project.",
  negative_thankyou_text:
    "Your opinion means a lot to us, and we would love to hear first-hand and chat through any thoughts or concerns you may have regarding our project.",
  letterassistnegative_text:
    "Do you want to contact your local authority regarding our project?",
  letterassistnegative_subtext:
    "If you would like to email your thoughts directly to the local authority, please click here.",
  thankyoupositive_text: "Thank you for taking time to complete this survey",
  thankyoupositive_subtext:
    "Would you like to speak to a member of the project team?",
  positive_summary_thankyou_text:
    "Your opinion means a lot to us, and we would love to hear first-hand and chat through any thoughts or concerns you may have regarding our project.",
  letterassistpositive_text: "Thank you for your support.",
  letterassistpositive_subtext:
    "We are very happy to hear that you are looking forward to our project.",
  letterassistpositive_summary:
    "If you don’t mind sparing the time, you can help us by registering your support directly with your local authority. Would you consider writing a few words of support?",
  letterassistpositive_summary2:
    "We will use what you have said to prepare an email that will be sent directly to your local authority.",
  positiveletter_end_thankyou_text:
    "We will use what you have said to prepare an email that will be sent directly to your local authority.",
  request_callback_sub:
    "Would you like to speak to a member of the project team?",
  survey: "Survey",
  disclaimer_text:
    "All information you give us will be stored safely, treated confidentially, and only used in connection with this project. However, these details are not required, should you wish not to provide them.",
};
