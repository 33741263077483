import * as types from "../types/index";
import { setInitialState } from "../InitialState";


const INITIAL_STATE = setInitialState({
    supportThisProject: null,
    keepMeUpdatedModal: false,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.PROJECT_SUPPORT_SHOW:
            return {
                ...state,
                supportThisProject: true,
            };
        case types.PROJECT_SUPPORT_HIDE:
            return {
                ...state,
                supportThisProject: false,
            };
        case types.KEEP_ME_UPDATED:
            return {
                ...state,
                keepMeUpdatedModal: action.payload.status,
            }; 
        default:
            return {
                ...state
            }
    }
}