import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';

export function support_survey_start_action(data) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/project/support-session/start`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS
            },
            body: JSON.stringify(data),
            types: [
                types.SUPPORT_SURVEY_START_REQUEST,
                types.SUPPORT_SURVEY_START_RECEIVE, {
                    type: types.SUPPORT_SURVEY_START_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const reset_support_survey_start_action = () => {
    return {
        type: types.RESET_SUPPORT_SURVEY_START,
        payload: null
    }
};

export function support_survey_answers_action(token, answer) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/project/support-session/submitanswer`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(answer),
            types: [
                types.SUPPORT_ANSWERS_REQUEST,
                types.SUPPORT_ANSWERS_RECEIVE, {
                    type: types.SUPPORT_ANSWERS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const reset_support_survey_answers_action = () => {
    return {
        type: types.RESET_SUPPORT_ANSWERS,
        payload: null
    }
};

export function support_survey_end_action(token, answer) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/project/support-session/end`,
            method: 'POST',
            headers: {
                ...AUTH_HEADERS,
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(answer),
            types: [
                types.SUPPORT_SURVEY_END_REQUEST,
                types.SUPPORT_SURVEY_END_RECEIVE, {
                    type: types.SUPPORT_SURVEY_END_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const reset_support_survey_end_action = () => {
    return {
        type: types.RESET_SUPPORT_SURVEY_END,
        payload: null
    }
};
