import React from "react";
import { Link } from "react-router-dom";
import CommonFooter from "./CommonFooter";

const PrivacyPolicy = (props) => {
  return (
    <>
      <div className="common_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 m-auto">
              <div className="card mt-3 mb-3 kanda-card">
                <div className="legal-content p-4">
                  <h3 className="title text-center pt-4">
                    Notice to website users
                  </h3>

                  <div className="content-policy pb-5">
                    <p className="pb-4">
                      In line with our ongoing commitment to compliance with
                      data protection legislation, we are in the process of
                      updating the way we tell you about the cookies we use.
                      Over the coming weeks, we will be making changes to the
                      websites across our estate where websites are owned or
                      created by us. Please bare with us whilst these changes
                      are made and if you have any queries with regards to
                      cookie notifications whilst using any of our sites, please
                      get in contact with our DPO using the contact details
                      below.
                      <br />
                      <br />
                      <b>DPO:</b> Alexandra Sodimu-Jones
                      <br />
                      <b>Email:</b>{" "}
                      <a href="mailto:alexandra.jones@kanrlegal.co.uk">
                        {" "}
                        alexandra.jones@kanrlegal.co.uk
                      </a>
                    </p>

                    <h3 className="title text-center">Privacy Policy</h3>
                    <p>
                      This website is operated by Kanda Consulting. All
                      references to ‘our’, ‘us’, ‘we’ or ‘Company’ within this
                      Privacy Policy are deemed to refer to Kanda Consulting (a
                      Limited Liability Partnership with registration number
                      OC417059).
                    </p>
                    <p>
                      We are committed to protecting and respecting your privacy
                      and the security of your personal information (“Personal
                      Information” or “Personal Data”) is important to us. We
                      work hard to safeguard your Personal Information in
                      accordance with all relevant data privacy laws.
                    </p>
                    <p>
                      This Privacy Policy (“Privacy Policy” or “Policy”)
                      (together with any other documents referred to in it) sets
                      out the basis on which we use any Personal Information we
                      collect from you, or that you provide to us. We recommend
                      you read this policy and its related documents in their
                      entirety to understand our principles and practices
                      regarding your Personal Information.
                    </p>
                    <p>
                      This website and any associated websites owned or created
                      by Kanda (“Related Sites”) may contain links to other
                      third-party websites for your convenience. This Privacy
                      Policy does not apply to those third-party websites and we
                      suggest contacting any third parties directly to
                      understand their data privacy procedures and policies.
                    </p>
                    <p>
                      By using our Website or any of our Related Sites, you
                      agree to the use of your Personal Information in
                      accordance with this Privacy Policy.
                    </p>
                    <p>
                      The rules we follow on processing of Personal Information
                      are set out in the UK General Data Protection Regulation
                      (the “UK GDPR”).
                    </p>

                    <h3 className="sub-heading pt-4">
                      1. Lawful basis for processing your Personal Information
                    </h3>
                    <p>
                      Kanda Consulting will only process your Personal Data,
                      such as your name, address, email address or telephone
                      number where we have a lawful basis for doing so. The
                      lawful basis we rely on for processing Personal Data will
                      be one of the following:
                    </p>

                    <div className="px-2">
                      <ul>
                        <li>
                          The processing is necessary for the performance for
                          the performance of a contract;
                        </li>
                        <li>
                          The processing is necessary in order for us to comply
                          with our legal obligations;
                        </li>
                        <li>
                          You have consented to us holding such data for
                          purposes agreed with you; or
                        </li>
                        <li>
                          The processing is necessary for the pursuit of our
                          legitimate business interests.
                        </li>
                      </ul>
                    </div>
                    <p>
                      More information on lawful processing can be found on the{" "}
                      <a
                        href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/"
                        target="_blank"
                      >
                        ICO website.
                      </a>
                      .
                    </p>

                    <h3 className="sub-heading pt-4">
                      2. Sharing your Personal Information
                    </h3>
                    <p>
                      Your Personal Data will be treated with integrity and
                      confidentiality, and will be shared only with the
                      individuals or groups of people for which it is necessary
                      to complete the purpose for which your Data was collected.
                    </p>
                    <p>
                      We have put technologies, policies and procedures in place
                      with the objective of protecting your Data from
                      unauthorised access and improper use and will update these
                      measures as appropriate as new technologies and best
                      practice guidelines are introduced.
                    </p>
                    <p>
                      Your Personal Data may be shared where we are required or
                      authorised by law.
                    </p>
                    <h3 className="sub-heading pt-4">
                      3. How long do we keep your Personal Information?
                    </h3>
                    <p>
                      We keep your Personal Data for no longer than reasonably
                      necessary in order to complete the purpose for which it
                      was collected. We may keep some data for a longer period
                      in order to comply with any legal or financial reporting
                      obligations or for safeguarding purposes.
                    </p>
                    <p>
                      We maintain safeguards which include technical and
                      organisational security measures to protect your Data from
                      loss, misuse, unauthorised access, alteration and
                      destruction. We require the third parties we contract with
                      to employ reasonable security measures as well.
                    </p>
                    <p>
                      We also have procedures in place to deal with any
                      suspected data security breaches. We will notify you and
                      any applicable regulator of a suspected data security
                      breach where we are legally required to do so.
                    </p>

                    <h3 className="sub-heading pt-4">
                      4. Your rights and your personal data
                    </h3>
                    <p>
                      Unless subject to an exemption under the UK GDPR, you have
                      the following rights with respect to your personal data
                    </p>
                    <ul>
                      <li>
                        You can ask for copies of your Personal Information;
                      </li>
                      <li>
                        You can ask us to rectify inaccurate or incomplete
                        Personal Information;
                      </li>
                      <li>
                        You can ask us to erase your Personal Information;
                      </li>
                      <li>
                        You can ask us not to use your Personal Information for
                        marketing;
                      </li>
                      <li>
                        You can ask us to restrict the processing of your
                        Personal Information;
                      </li>
                      <li>
                        You can object to the processing of your Personal
                        Information;
                      </li>
                      <li>
                        You can ask that we transfer your Personal Information
                        to another organisation or to you
                      </li>
                      <li>
                        You can complain about our processing of your Personal
                        Information; and
                      </li>
                      <li>
                        To the extent that the legal basis of our processing of
                        your Personal Information is consented, you can withdraw
                        that consent.
                      </li>
                    </ul>
                    <p>
                      Please contact us using the contact information below if
                      you wish you discuss or exercise any of your rights
                      described above.
                    </p>

                    <h3 className="sub-heading pt-4">
                      {" "}
                      5. Your rights and your Personal Information
                    </h3>
                    <p>
                      You have the following rights with respect to your
                      Personal Information
                    </p>
                    <ul>
                      <li>
                        You can ask for copies of your Personal Information;
                      </li>
                      <li>
                        You can ask us to rectify inaccurate or incomplete
                        Personal Information;
                      </li>
                      <li>
                        You can ask us to erase your Personal Information;
                      </li>
                      <li>
                        You can ask us not to use your Personal Information for
                        marketing;
                      </li>
                      <li>
                        You can ask us to restrict the processing of your
                        Personal Information;
                      </li>
                      <li>
                        You can object to the processing of your Personal
                        Information;
                      </li>
                      <li>
                        You can ask that we transfer your Personal Information
                        to another organisation or to you
                      </li>
                      <li>
                        You can complain about our processing of your Personal
                        Information; and
                      </li>
                    </ul>
                    <p>
                      In addition, to the extent that the legal basis of our
                      processing of your Personal Information is consented, you
                      can withdraw that consent at any time.
                    </p>
                    <p>
                      Please contact us using the contact information below if
                      you wish you discuss or exercise any of your rights
                      described above.
                    </p>

                    <h3 className="sub-heading pt-4">6. Children’s Privacy</h3>
                    <p>
                      Neither our Website or any of our Related Sites are
                      intended for use by anyone under the age of 16. We do not
                      knowingly collect or maintain Personal Information from
                      children.
                    </p>
                    <h3 className="sub-heading pt-4">
                      7. Changes to our Privacy Policy
                    </h3>
                    <p>
                      Any changes we may make to our Privacy Policy in the
                      future will be posted on this page. Please check back
                      frequently to see any updates or changes to our Privacy
                      Policy.
                    </p>
                    <h3 className="sub-heading pt-4">
                      8. How to make a complaint
                    </h3>
                    <p>
                      To exercise all relevant rights, put forward any queries
                      or make a complaint please contact our Data Protection
                      Officer in the first instance using the details below.
                    </p>
                    <p>
                      If this does not resolve your complaint to your
                      satisfaction, you have the right to lodge a complaint with
                      the{" "}
                      <a
                        href="https://ico.org.uk/global/contact-us/"
                        target="_blank"
                      >
                        Information Commissioners Office
                      </a>{" "}
                      on 03031231113 or via{" "}
                      <a
                        href="https://ico.org.uk/global/contact-us/email/"
                        target="_blank"
                      >
                        email
                      </a>{" "}
                      or at the Information Commissioner’s Office , Wycliffe
                      House, Water Lane, Wilmslow, Cheshire, SK9 5AF, England.
                    </p>
                    <h3 className="sub-heading pt-4">9. Contact details</h3>

                    <p>Our contact details are:</p>
                    <div className="px-3">
                      <p>
                        Kanda Communications LLP <br />
                        44-48 Paul Street
                        <br />
                        London
                        <br />
                        EC2A 4LB
                      </p>
                    </div>
                    <p>
                      For all matters concerning your Personal Information or
                      this policy, please contact our Data Protection Officer:
                    </p>

                    <p>
                      <b>Data Protection Officer</b>: Alexandra Sodimu -Jones of
                      KANR Legal Services Ltd.{" "}
                    </p>
                    <p>
                      <b>Email</b>:{" "}
                      <a href="mailto:Alexandra.jones@kanrlegal.co.uk">
                        {" "}
                        Alexandra.jones@kanrlegal.co.uk
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default PrivacyPolicy;
