import { combineReducers } from 'redux';
import news from './news-reducer';
import news_detail from './news-detail-reducer';
import news_categories from './news-categories-reducer';
import archives from './archives-reducer';
import archives_categories from './archives-categories-reducer';
import timeline from './timeline-reducer';
import hub from './hub-reducer';
import projectconfig from './projectconfig-reducer';
import proposals from './proposals-reducer';
import proposals_categories from './proposals-categories-reducer';
import proposal_detail from './proposals-details-reducer';
import support_this_project from './display-survey-reducer';
import answers from './answers-reducer';

import survey_start from './survey-start-reducer';
import survey_end from './survey-end-reducer';
import survey_result from './survey-result-reducer';
import survey_support from './survey-support-reducer';

import email_subscribe from './email-subscribe-reducer';

import support_start from './support-survey-start-reducer';
import support_answer from './support-answers-reducer';
import support_end from './support-survey-end-reducer';
import secure_site from './securesite-reducer';
import our_proposal from './our-proposal-reducer';


const rootReducer = combineReducers({
    news: news,
    news_detail: news_detail,
    news_categories: news_categories,

    archives: archives,
    archives_categories: archives_categories,

    timeline: timeline,
    hub: hub,
    our_proposal: our_proposal,
    projectconfig: projectconfig,

    proposals: proposals,
    proposals_categories: proposals_categories,
    proposal_detail: proposal_detail,
    survey_start: survey_start,
    survey_end: survey_end,
    survey_result: survey_result,
    support_this_project: support_this_project,
    answers: answers,
    survey_support: survey_support,
    email_subscribe: email_subscribe,

    support_start: support_start,
    support_answer: support_answer,
    support_end: support_end,
    secure_site: secure_site
});

export default rootReducer;