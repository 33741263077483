import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

// redux
import {
  news_action,
  reset_news,
} from "../../stores/actions";
import {
  Filters,
  ListWrapper,
  SortByFilter,
  Pagination,
  NewsListing,
  Animi,
  ReactangleLoaderView,
  Loader,
} from "../../components";
// import { Images } from '../../config'
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { isMobile } from "react-device-detect";
import { StringConstants, SocialHandles } from "../../config";
import CommonFooter from "../Legals/CommonFooter";

const News = (props) => {
  const dispatch = useDispatch();

  const [categoriesData, setCategoriesData] = useState([]);
  const [projectData, setProjectData] = useState();

  const [filters, setFilters] = useState({
    q: "",
    catId: "",
    orderByCol: "deadline",
    orderByDir: "desc",
    page: 1,
  });

  const newsData = useSelector((state) => state.news);
  const projectConfigData = useSelector((state) => state.projectconfig);

  useEffect(() => {
    window.scroll(0, 0);
    try {
      if ((newsData.isFetching === false) & (newsData.data === null)) {
        dispatch(news_action(filters));
      }
    } catch (e) {
      console.log("news error", e);
    }
  }, []);

  useEffect(() => {
    if (projectConfigData.data !== null) {
      setCategoriesData(projectConfigData.data.data.categories.news);
      setProjectData(projectConfigData.data.data);
    }
  }, [projectConfigData]);

  const __featuredListItem = () => {
    if (newsData.data === null) {
      return;
    }

    if (newsData.data.data.news.length === 0) {
      // return <NoResults />
      return StringConstants.NoResultsText;
    }

    const data = newsData.data.data.news;
    let list = [];
    _.each(data, (item, id) => {
      // temporery fix
      if (
        (SocialHandles.facebook.length !== 0 ||
          SocialHandles.twitter.length !== 0) &&
        id > 0
      ) {
        return;
      }
      list.push(<NewsListing key={id} details={item} type={"large"} />);
    });
    return list;
  };
  const __listItem = () => {
    if (newsData.data === null) {
      return;
    }

    const data = newsData.data.data.news;
    let list = [];
    _.each(data, (item, id) => {
      if (id === 0) {
        return;
      }
      list.push(<NewsListing key={id} details={item} type={"small"} />);
    });

    if (list.length === 0) {
      return null;
    }

    return (
      <>
        <Animi.FadeInAnimationDiv>{list}</Animi.FadeInAnimationDiv>
      </>
    );
  };

  const __socialFeed = () => {
    return (
      <Animi.FadeInAnimationDiv delay={3}>
        {SocialHandles.twitter.length > 0 && (
          <ListWrapper key="twitter">
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName={SocialHandles.twitter}
              options={{ height: 200 }}
              borderColor={false}
              noHeader={true}
              noFooter={true}
              noBorders={true}
              noScrollbar={true}
              transparent={true}
            />
          </ListWrapper>
        )}
        {SocialHandles.facebook.length > 0 && (
          <ListWrapper key="fb">
            <iframe
              src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fusekanda%2F&tabs=timeline&width=260&height=200&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=${SocialHandles.facebook}`}
              width="100%"
              height="200"
              style={{ border: "none", overflow: "hidden", width: "100%" }}
              scrolling="no"
              frameborder="0"
              allowfullscreen="false"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </ListWrapper>
        )}
      </Animi.FadeInAnimationDiv>
    );
  };

  const __pagination = () => {
    if (newsData.data === null) {
      return;
    }
    return (
      <Pagination
        data={newsData.data.data.news_pagination}
        onSubmit={(res) => {
          __applyPage(res);
        }}
      />
    );
  };
  const __applyPage = (res) => {
    let filterTp = JSON.parse(JSON.stringify(filters));
    filterTp.page = res;

    setFilters(filterTp);

    dispatch(reset_news());
    setTimeout(() => {
      dispatch(news_action(filterTp));
    }, 100);
  };

  const __applySortByFilter = (res) => {
    let filterTp = JSON.parse(JSON.stringify(filters));
    filterTp.page = 1;

    console.log(res);
    if (res === "alphaDesc") {
      filterTp.orderByCol = "title";
      filterTp.orderByDir = "desc";
    } else if (res === "alphaAsc") {
      filterTp.orderByCol = "title";
      filterTp.orderByDir = "asc";
    } else if (res === "dateDesc") {
      filterTp.orderByCol = "deadline";
      filterTp.orderByDir = "desc";
    } else if (res === "dateAsc") {
      filterTp.orderByCol = "deadline";
      filterTp.orderByDir = "asc";
    }

    setFilters(filterTp);

    dispatch(reset_news());
    setTimeout(() => {
      dispatch(news_action(filterTp));
    }, 100);
  };

  const __renderProposalLoader = () => {
    return (
      <div className="px-4 pt-3 pb-2 project-config loading-div loading-div-praposal">
        <div className="main-div">
          <div className="first-div width-30">
            <ReactangleLoaderView
              imageheight={150}
              imagewidth={"100%"}
            ></ReactangleLoaderView>
          </div>
          <div className="second-div width-70">
            <Loader type={"view"} row={6}>
              {" "}
            </Loader>
          </div>
        </div>
      </div>
    );
  };

  const __search = (res) => {
    let filterTp = JSON.parse(JSON.stringify(filters));
    filterTp.q = res.q;
    filterTp.catId = res.id;
    filterTp.page = 1;
    setFilters(filterTp);

    dispatch(reset_news());
    setTimeout(() => {
      dispatch(news_action(filterTp));
    }, 100);
  }

  return (
    <>
      <div>
        <div className="container container-top">
          <Filters
            categories={categoriesData}
            placeholder="Search Posts"
            onSubmit={(res) => __search(res)}
          />

          <div className="d-flex">
            <div className="flex-grow-1 news-say-text">
              <h5 className="pb-2">{StringConstants.news_events}</h5>
            </div>
            <div className="sortBy">
              <SortByFilter
                onSubmit={(res) => __applySortByFilter(res)}
              />
            </div>
          </div>

          {!newsData.isSuccess
            ? __renderProposalLoader()
            : __featuredListItem()}
          {SocialHandles.facebook.length > 0 ||
            SocialHandles.twitter.length > 0 ? (
            <div className="row pt-1">
              <div className="col-md-3 m-none">
                {!newsData.isSuccess ? (
                  <div className="pt-3">
                    <ListWrapper key="twitter">
                      <div style={{ padding: 10 }}>
                        <Loader></Loader>
                      </div>
                    </ListWrapper>
                  </div>
                ) : (
                  <div>
                    <h5 className="pb-2">{StringConstants.social}</h5>
                    {!isMobile ? __socialFeed() : null}
                  </div>
                )}
              </div>
              <div className={"col-md-9"}>
                {!newsData.isSuccess ? __renderProposalLoader() : __listItem()}

                {/* {__featuredListItem()}
                        {__listItem()} */}
                {__pagination()}
              </div>
            </div>
          ) : (
            <div className="row pt-1">
              <div className={"col-md-12"}>{__pagination()}</div>
            </div>
          )}
        </div>
        <div className="social-parent">
          <div className="col-md-3 pm-none">
            {newsData.isFetching ? (
              <div className="pt-3">
                <ListWrapper key="twitter">
                  <div style={{ padding: 10 }}>
                    <Loader></Loader>
                  </div>
                </ListWrapper>
              </div>
            ) : (
              <div className="main-div">
                {SocialHandles.facebook.length > 0 && (
                  <div className="inside-col1">
                    <ListWrapper key="fb">
                      <iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fusekanda%2F&tabs=timeline&width=320&height=420&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=160520457313106"
                        width="100%"
                        height="340"
                        style={{
                          border: "none",
                          overflow: "auto",
                          width: "100%",
                        }}
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="false"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      ></iframe>
                    </ListWrapper>
                  </div>
                )}
                {SocialHandles.twitter.length > 0 && (
                  <div className=" inside-col">
                    <ListWrapper key="twitter">
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="useKanda"
                        options={{ height: 420 }}
                        borderColor={false}
                        noHeader={true}
                        noFooter={true}
                        noBorders={true}
                        noScrollbar={true}
                        transparent={true}
                      />
                    </ListWrapper>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default News;
