import * as types from "../types/index";
import { setInitialState } from "../InitialState";

const INITIAL_STATE = setInitialState({
    data: null,
});


export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.SUBSCRIBE_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.SUBSCRIBE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                isError: false,
                data: action.payload.success
            };
        case types.SUBSCRIBE_FAILURE:
            if (action.error && action.payload.name === "ApiError") {
                return {
                    ...state,
                    isError: true,
                    isFetching: false,
                    isSuccess: false,
                    data: action.payload.response.error
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    isError: true,
                    isSuccess: false,
                    data: action.payload.response.error,
                };
            }
        case types.RESET_SUBSCRIBE:
            return {
                ...state,
                isFetching: false,
                isSuccess: false,
                isError: false,
                data: null,
            };
        default:
            return {
                ...state
            }
    }
}