export default {
    home : {
        title:process.env.REACT_APP_HOME_TITLE,
        url:"/",
    },
    our_proposals : {
        title:process.env.REACT_APP_OURPROPOSAL_TITLE,
        url:process.env.REACT_APP_OURPROPOSAL_URL,
    },
    background : {
        title:process.env.REACT_APP_BACKGROUND_TITLE,
        url:process.env.REACT_APP_BACKGROUND_URL,
    },
    share_your_views: {
        title:process.env.REACT_APP_SHAREYOURVIEWS_TITLE,
        url:process.env.REACT_APP_SHAREYOURVIEWS_URL,
    },
    timeline: {
        title:process.env.REACT_APP_TIMELINE_TITLE,
        url:process.env.REACT_APP_TIMELINE_URL,
    },
    news_events: {
        title:process.env.REACT_APP_NEWSEVENTS_TITLE,
        url:process.env.REACT_APP_NEWSEVENTS_URL,
    },
    archive: {
        title:process.env.REACT_APP_ARCHIVE_TITLE,
        url:process.env.REACT_APP_ARCHIVE_URL,
    },
}
