import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NavLink, Link, useLocation } from "react-router-dom";
import { Config, NavigationConstants } from "../../config";

// redux
import { project_details_action, keepMeUpdatedModalAction } from "../../stores/actions";

const Header = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [projectData, setProjectData] = useState();
  const [isHamOpen, setHamOpen] = useState(0);
  const [toggleMenu, setToggleMenu] = useState(0);
  const projectConfigData = useSelector((state) => state.projectconfig);
  const [projectLogoImg, setProjectLogoImg] = useState();

  useEffect(() => {
    window.scroll(0, 0);
    try {
      if (
        (projectConfigData.isFetching === false) &
        (projectConfigData.data === null)
      ) {
        dispatch(project_details_action());
      }
    } catch (e) {
      console.log("archives categories error", e);
    }
  }, []);

  useEffect(() => {
    if (
      projectConfigData.data !== null &&
      projectConfigData.isSuccess === true
    ) {
      setProjectData(projectConfigData.data.data);
    }
  }, [projectConfigData]);

  useEffect(() => {
    if (
      projectConfigData.isSuccess === true &&
      projectConfigData.data !== null
    ) {
      setProjectLogoImg(projectConfigData.data.data.projectConfig.projectLogo);
    }
  }, [projectConfigData]);

  const closeMenu = () => {
    if (isHamOpen) {
      setHamOpen(false);
    }
  };

  return (
    <>
      <header className="fixed-top">
        <div
          className="navbar navbar-expand-lg  navbar-dark bg-primary align-items-stretch"
          style={{ minHeight: "75px" }}
        >
          <Link to="/" className="navbar-brand p-3 kanda-logo">
            {/* <img src={Images.Logo} alt=""  /> */}
            {projectLogoImg && (
              <img src={projectLogoImg} alt="logo" style={{ height: 49 }} />
            )}
          </Link>
          <button
            className={
              isHamOpen ? "navbar-toggler" : "navbar-toggler collapsed"
            }
            type="button"
            onClick={() => {
              setHamOpen(!isHamOpen);
            }}
          >
            {!isHamOpen ? (
              <span className="navbar-toggler-icon"></span>
            ) : (
              <span className="navbar-toggler-icon-close"></span>
            )}
          </button>
          <div
            className={
              !isHamOpen
                ? "navbar-collapse collapse align-items-stretch"
                : "navbar-collapse collapse show p-0"
            }
          >
            <ul className="navbar-nav ml-auto navbar-mobile" style={{ maxHeight: `${window.innerHeight - 63}px` }}>
              <li className="nav-item d-flex align-items-center mr-2 ml-2">
                <NavLink
                  activeClassName={location.pathname === "/" ? "active" : ""}
                  className="nav-link align-self-stretch text-light"
                  to={NavigationConstants.home.url}
                  onClick={() => {
                    closeMenu();
                  }}
                >


                  <span>{NavigationConstants.home.title}</span>
                </NavLink>
              </li>
              <li className="nav-item d-flex align-items-center mr-2 ml-2">
                <NavLink
                  exact
                  activeClassName="active"
                  className="nav-link align-self-stretch text-light"
                  to={NavigationConstants.background.url}
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  <span>{NavigationConstants.background.title}</span>
                </NavLink>
              </li>
              {Config.menu_ourproposals && (
              <li className="nav-item d-flex align-items-center mr-2 ml-2">
                <NavLink
                  exact
                  activeClassName="active"
                  className="nav-link align-self-stretch text-light"
                  to={NavigationConstants.our_proposals.url}
                  onClick={() => {
                    closeMenu();
                  }}
                >
                  <span>{NavigationConstants.our_proposals.title}</span>
                </NavLink>
              </li>
              )}
              {Config.menu_proposals && (
                <li className="nav-item d-flex align-items-center  mr-2 ml-2">
                  <NavLink
                    activeClassName="active"
                    className="nav-link align-self-stretch text-light"
                    to={NavigationConstants.share_your_views.url}
                    onClick={() => {
                      closeMenu();
                    }}
                  >

                    <span>{NavigationConstants.share_your_views.title}</span>
                  </NavLink>
                </li>
              )}
              {Config.menu_timeline && (
                <li className="nav-item d-flex align-items-center  mr-2 ml-2">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link align-self-stretch text-light"
                    to={NavigationConstants.timeline.url}
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    <span>{NavigationConstants.timeline.title}</span>
                  </NavLink>
                </li>
              )}
              {Config.menu_news && (
                <li className="nav-item d-flex align-items-center  mr-2 ml-2">
                  <NavLink
                    activeClassName="active"
                    className="nav-link align-self-stretch text-light"
                    to={NavigationConstants.news_events.url}
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    <span>{NavigationConstants.news_events.title}</span>
                  </NavLink>
                </li>
              )}
              {Config.menu_archive && (
                <li className="nav-item d-flex align-items-center  mr-2 ml-2">
                  <NavLink
                    exact
                    activeClassName="active"
                    className="nav-link align-self-stretch text-light"
                    to={NavigationConstants.archive.url}
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    <span>{NavigationConstants.archive.title}</span>
                  </NavLink>
                </li>
              )}
              <li className="mobile-box mt-4">
                <div className="footer1 f-bottom">
                  <div className="flex-grow-1 ">
                    <div className="legal-box mobile-only ">
                      
                      
                            <NavLink to=""
                              onClick={() => {
                                closeMenu();
                                dispatch(keepMeUpdatedModalAction({ status: true }))
                              }}
                              className="link-tc p-2">
                              Keep me updated
                            </NavLink>
                            <br />
                            <br />
                      {projectData &&
                        projectData.projectConfig.projectDevelopmentSupportEnabled ===
                        true ? <>
                            <NavLink to="/support-survey"
                              onClick={() => {
                                closeMenu();
                              }}
                              className="link-tc p-2">
                              Opinion Poll
                            </NavLink>
                            <br />
                            <br />
                        </> : null}


                      {
                        toggleMenu ?
                          <>
                            <NavLink to="/privacy-policy"
                              onClick={() => {
                                closeMenu();
                              }}
                              className="link-tc p-2">
                              Privacy Policy
                            </NavLink>
                            <br />
                            <br />
                            <NavLink to="/cookies"
                              onClick={() => {
                                closeMenu();
                              }}
                              className="link-tc p-2">
                              Cookie Policy
                            </NavLink>
                            <br />
                            <br />
                            <NavLink to="/contact"
                              onClick={() => {
                                closeMenu();
                              }}
                              className="link-tc p-2" >
                              Get in contact
                            </NavLink>
                            <br />
                            <br />
                          </>
                          : null
                      }

                      <button
                        onClick={() => {
                          setToggleMenu(!toggleMenu)
                        }}
                        className="btn btn-primary btn-sm">
                        {
                          !toggleMenu ? "See More" : "See Less"
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
