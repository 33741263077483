import React, { useState } from 'react';

const QTADT = props => {

    const [adtEnabled, setAdtEnabled] = useState(props.adtEnabled);
    // const [answers, setAnswers] = useState();
    const [showADT, setShowADT] = useState(props.additional_thoughts !== null && props.additional_thoughts !== "" ? true : false);
    if (!adtEnabled) {
        return null
    }


    return (<div key={props.questionId} className="pb-2 pt-4">
        <div className="container-one">
            <div className="customeRadio-one">
                <input id="addinal_though" type="checkbox" name={"adt"} value={""} className="mr-2"
                    onChange={() => {
                        setShowADT(!showADT);
                    }}
                    checked={showADT}
                />
                <span className="checkmark"></span>
            </div>
            Click if you have any additional thoughts on this question
        </div>

        {showADT ?
            <textarea className="text-area" rows="4" placeholder="Type your comment here" onChange={(event) => {
                props.onSubmit(event.target.value);
            }} value={props.additional_thoughts}></textarea> : null

        }
    </div>);
};

export default QTADT;