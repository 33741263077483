export const NEWS_REQUEST = "NEWS_REQUEST";
export const NEWS_RECEIVE = "NEWS_RECEIVE";
export const NEWS_FAILURE = "NEWS_FAILURE";
export const RESET_NEWS = "RESET_NEWS";

export const NEWS_CAT_REQUEST = "NEWS_CAT_REQUEST";
export const NEWS_CAT_RECEIVE = "NEWS_CAT_RECEIVE";
export const NEWS_CAT_FAILURE = "NEWS_CAT_FAILURE";
export const RESET_NEWS_CAT = "RESET_NEWS_CAT";

export const NEWS_DETAIL_REQUEST = "NEWS_DETAIL_REQUEST";
export const NEWS_DETAIL_RECEIVE = "NEWS_DETAIL_RECEIVE";
export const NEWS_DETAIL_FAILURE = "NEWS_DETAIL_FAILURE";
export const RESET_NEWS_DETAIL = "RESET_NEWS_DETAIL";