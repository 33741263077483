import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';

export function our_proposal_action() {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/our-proposal`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.OUR_PROPOSAL_REQUEST,
                types.OUR_PROPOSAL_RECEIVE, {
                    type: types.OUR_PROPOSAL_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const reset_our_proposal = () => {
    return {
        type: types.RESET_OUR_PROPOSAL,
        payload: null
    }
};