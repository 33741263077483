import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';

export function hub_action() {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/project/hub`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.HUB_REQUEST,
                types.HUB_RECEIVE, {
                    type: types.HUB_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const reset_hub = () => {
    return {
        type: types.RESET_HUB,
        payload: null
    }
};


export function project_details_action() {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/project/config`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.PROJECT_DETAILS_REQUEST,
                types.PROJECT_DETAILS_RECEIVE, {
                    type: types.PROJECT_DETAILS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}

export const reset_project_details = () => {
    return {
        type: types.RESET_PROJECT_DETAILS,
        payload: null
    }
};