import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { QTADT } from './index';

const QT04 = props => {

    

    let list = [];
    const [answers, setAnswers] = useState([]);
    const [adtData, setAdtData] = useState('');

    let ans_list;
    if(props.data.hasOwnProperty('answer')){
        if(props.data.answer !== ''){
            ans_list = props.data.answer.answer.split(',');
        }
    }   

    useEffect(() => {
        if (answers.length > 0) {
            props.onSubmit({ "answer": answers.join(','), "questionId": props.data.questionId, "additional_thoughts": adtData });
        }
    }, [answers, adtData]);


    const __Answers = (res) => {
        let tempArray = JSON.parse(JSON.stringify(answers));

        let index = tempArray.indexOf(res);

        if (index === -1) {
            tempArray.push(res);
        } else {
            _.remove(tempArray, (n) => {
                return n === res;
            });
        }

        setAnswers(tempArray);
    };


    _.each(props.data.options, (item, index) => {

       let check = false;

       let checkIndex =  _.findIndex(ans_list, function(o) { return o === item.optionId; });
       if(checkIndex >= 0){
        check = true;
       }

        list.push(<div key={item.optionId} className="py-2 col-md-6">
            <div className="container-one">
                <div className="customeRadio-one">
                    <input type="checkbox" name={props.data.questionId} value={item.optionId} className="mr-2"
                        onChange={() => {
                            __Answers(item.optionId)
                        }}
                        checked={check} 

                    />
                    <span className="checkmark"></span>
                </div>
                {item.option}</div>
        </div>)
    });

    return (<div>
        <div className="row">
            {list}
        </div>

        <QTADT adtEnabled={props.data.questionAdditionalThoughtsEnabled} questionId={props.data.questionId} onSubmit={(res) => {
            setAdtData(res);
        }} additional_thoughts={props.data.hasOwnProperty('answer') ? props.data.answer.additional_thoughts : null}/>
    </div>);
};

export default QT04;