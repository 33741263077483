import React from 'react';

const ListWrapper = props => {
    let className = "card mt-2 mb-4 kanda-card kanda-card-mobile";

    if (props.prime) {
        className = className + "prime ";
    }

    return (
        <div className={className} style={{ '--animation-order': props.key }}> {
            props.children
        }</div>
    );
};

export default ListWrapper;