import { RSAA } from 'redux-api-middleware';
import * as types from "../types/index";
import { API_ENDPOINT, AUTH_HEADERS } from '../../config/ApiConstants';


export function news_action(filters = '') {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/news/all?category_id=${filters.catId}&q=${filters.q}&orderByCol=${filters.orderByCol}&orderByDir=${filters.orderByDir}&page=${filters.page}`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.NEWS_REQUEST,
                types.NEWS_RECEIVE, {
                    type: types.NEWS_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reset_news = () => {
    return {
        type: types.RESET_NEWS,
        payload: null
    }
};



export function news_categories_action() {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/news/categories`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.NEWS_CAT_REQUEST,
                types.NEWS_CAT_RECEIVE, {
                    type: types.NEWS_CAT_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reset_news_categories = () => {
    return {
        type: types.RESET_NEWS_CAT,
        payload: null
    }
};





export function news_detail_action(newsSlug) {
    return {
        [RSAA]: {
            endpoint: `${API_ENDPOINT}/news/${newsSlug}/show`,
            method: 'GET',
            headers: {
                ...AUTH_HEADERS
            },
            body: '',
            types: [
                types.NEWS_DETAIL_REQUEST,
                types.NEWS_DETAIL_RECEIVE, {
                    type: types.NEWS_DETAIL_FAILURE,
                    meta: (action, state, res) => {
                        return (action, state, res);
                    }
                }]
        }
    }
}


export const reset_news_detail = () => {
    return {
        type: types.RESET_NEWS_DETAIL,
        payload: null
    }
};

