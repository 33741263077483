export const SUPPORT_SURVEY_START_REQUEST = "SUPPORT_SURVEY_START_REQUEST";
export const SUPPORT_SURVEY_START_RECEIVE = "SUPPORT_SURVEY_START_RECEIVE";
export const SUPPORT_SURVEY_START_FAILURE = "SUPPORT_SURVEY_START_FAILURE";
export const RESET_SUPPORT_SURVEY_START = "RESET_SUPPORT_SURVEY_START";

export const SUPPORT_SURVEY_END_REQUEST = "SUPPORT_SURVEY_END_REQUEST";
export const SUPPORT_SURVEY_END_RECEIVE = "SUPPORT_SURVEY_END_RECEIVE";
export const SUPPORT_SURVEY_END_FAILURE = "SUPPORT_SURVEY_END_FAILURE";
export const RESET_SUPPORT_SURVEY_END = "RESET_SUPPORT_SURVEY_END";

export const SUPPORT_SURVEY_RESULT_REQUEST = "SUPPORT_SURVEY_RESULT_REQUEST";
export const SUPPORT_SURVEY_RESULT_RECEIVE = "SUPPORT_SURVEY_RESULT_RECEIVE";
export const SUPPORT_SURVEY_RESULT_FAILURE = "SUPPORT_SURVEY_RESULT_FAILURE";
export const RESET_SUPPORT_SURVEY_RESULT = "RESET_SUPPORT_SURVEY_RESULT";

export const SUPPORT_ANSWERS_REQUEST = "SUPPORT_ANSWERS_REQUEST";
export const SUPPORT_ANSWERS_RECEIVE = "SUPPORT_ANSWERS_RECEIVE";
export const SUPPORT_ANSWERS_FAILURE = "SUPPORT_ANSWERS_FAILURE";
export const RESET_SUPPORT_ANSWERS = "RESET_SUPPORT_ANSWERS";

export const SUPPORT_SURVEY_SUPPORT_REQUEST = "SUPPORT_SURVEY_SUPPORT_REQUEST";
export const SUPPORT_SURVEY_SUPPORT_RECEIVE = "SUPPORT_SURVEY_SUPPORT_RECEIVE";
export const SUPPORT_SURVEY_SUPPORT_FAILURE = "SUPPORT_SURVEY_SUPPORT_FAILURE";
export const RESET_SUPPORT_SURVEY_SUPPORT = "RESET_SUPPORT_SURVEY_SUPPORT";