import React from 'react';
import {TextBlock, MediaBlock, TextRow, RectShape, RoundShape,RealComponent} from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

const Loader = props => {
    return (
        <div >
            {
                props.type=== 'view'?
                <ReactPlaceholder  type='text' rows={props.row}   ready={false}  color='#E0E0E0' showLoadingAnimation={true}  >                                                
                </ReactPlaceholder>     :
                 <ReactPlaceholder  type='textRow' rows={props.row}   ready={false}  color='#E0E0E0' showLoadingAnimation={true}  >                                                
                 </ReactPlaceholder> 
            }
         
        </div> 
        

        );
};

export default Loader;