import React from 'react'
import CommonFooter from './CommonFooter'
const Tnc = props => {
    return (
        <>
            <div className="common_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 m-auto">
                            <div className="card mt-3 mb-3 kanda-card">
                                <div className="legal-content p-4">
                                    <h3 className="title">Term and condition</h3>
                                    <p>We are determined to make every consultation fully accessible to all. This starts by recognising that a proportion of the population is digitally excluded because they lack internet access and/or have low levels of digital literacy. Equally, some people might have a preference to receive information in the post or speak to a member of the team.
                                    </p>
                                    <p>We open a variety of communication channels in every consultation and continue to deliver the more traditional means of engaging with communities – hosting physical events (when it is safe to do so) and distributed newsletter updates will provide other opportunities for communities to help shape plans for their area. One of our first engagements with the local community is advertising the launch of Kanda Shape. In this correspondence (a printed flyer via post) local people have the opportunity to request information through other means (email, post, or over the phone) through a freepost return form.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
         <CommonFooter />
        </>
    )
}


export default Tnc
