import React, { useState } from 'react';

import _ from 'lodash';

const SortByFilter = (props) => {
    const [toggle, setToggle] = useState(false);
    const [activeFilter, setActiveFilter] = useState("Most Recent");

    const responses = props.responses === true ? true : false;

    const FilterArray = [
        { title: 'A-Z', code: 'alphaAsc' },
        { title: 'Z-A', code: 'alphaDesc' },
        { title: 'Date (ascend)', code: 'dateAsc' },
        { title: 'Date (descend)', code: 'dateDesc' },
    ];

    if (responses === true) {
        FilterArray.push({ title: 'Responses (ascend)', code: 'responsesAsc' });
        FilterArray.push({ title: 'Responses (descend)', code: 'responsesDesc' });
    }

    const __action = (res, title) => {
        setToggle(!toggle);
        setActiveFilter(title);

        props.onSubmit(res);
    }

    const __DisplayFilters = () => {
        let arr = [];

        _.each(FilterArray, (res) => {
            arr.push(<button className="dropdown-item" onClick={() => {
                __action(res.code, res.title);
            }} key={res.code}>{res.title}</button>);
        });

        return arr;
    }

    return (
        <div className="btn-group sortByFilterWpr">
            <p className="mb-0 mt-1 pr-3"><span className="font-weight-bold text-primary">Sort by </span></p>
            <button type="button" className="btn btn-default btn-sm dropdown-toggle" onClick={() => {
                setToggle(!toggle);
            }}>{activeFilter}</button>
            <div className={toggle ? "dropdown-menu  dropdown-menu-right show" : "dropdown-menu  dropdown-menu-right"}>
                {
                    __DisplayFilters()
                }
            </div>
        </div>)
}


export default SortByFilter;

