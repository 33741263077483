import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import GoogleMapReact, { fitBounds, overlay } from "google-map-react";

import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// redux
import {
  survey_answers_action,
  resetSurvey_answers_action,
  resetSurvey_start_action,
  survey_end_action,
  resetSurvey_end_action,
  survey_support_action,
  resetSurvey_support_action,
} from "../../stores/actions";
import {
  ListWrapper,
  ShareVia,
  TextInput,
  QT01,
  QT02,
  QT03,
  QT04,
  QT05,
  QT06,
  QT07,
  QT08,
  BackButton,
  SupportQuestion,
} from "../../components";
import * as _ from "lodash";
import { Images, StringConstants, SocialHandles, Colors,NavigationConstants } from "../../config";
import { isMobile } from "react-device-detect";
import CommonFooter from "../Legals/CommonFooter";

const Survey = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const proposalId = props.match.params.proposalId;
  const token = props.location.state.data.surveySessionToken;
  // const isproposalId = props.location.state.data;
  const [baseProposal, setBaseProposal] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(1);
  const [finishSurvey, setFinishSurvey] = useState(0);
  const [answer, setAnswer] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [email, setEmail] = useState(null);
  const [isTNC, setIsTNC] = useState(null);
  const [isError, setIsError] = useState(null);
  const [nextButtonText, setNextButtonText] = useState("Next");
  const [noOfQustions, setNoOfQustions] = useState(0);
  const [supportModule, setSupportModule] = useState(0);
  const [supportAnswer, setSupportAnswer] = useState(null);
  const [zoom, setZoom] = useState(11);
  const [location, setLocation] = useState({ lat: 59.95, lng: 30.33 });
  const [pointer, setPointer] = useState({ lat: 0, lng: 0 });
  const [pointerXY, setPointerXY] = useState({ x: -100, y: -100 });
  const [isMendatory, setIsMendatory] = useState(false);
  const [mapPath, setMapPath] = useState();
  const [backButton, setBackButton] = useState(true);

  const [interactiveContent, setInteractiveContent] = useState(null);

  const [doneButtonText, setDoneButtonText] = useState("Done");
  const [doneScoll, setDoneScroll] = useState(false);

  const [questype, setQuestionType] = useState();

  const [map, setMap] = useState();
  const [maps, setMaps] = useState();

  const [mpOverlay, setMpOverlay] = useState();

  const imageMapRef = useRef();

  const answersData = useSelector((state) => state.answers);
  const supportAnswerData = useSelector((state) => state.survey_support);
  const surveyEndData = useSelector((state) => state.survey_end);

  useEffect(() => {
    window.scroll(0, 0);
    resetSurvey_answers_action(); 

    if (props.location.state === undefined) {
      history.replace(NavigationConstants.share_your_views.url +"/" + proposalId);
    } else {
      setBaseProposal(props.location.state.data);
      setSupportModule(
        props.location.state.data.proposal.isProposalPromptSupportEnabled
      );
      setNoOfQustions(props.location.state.data.proposalQuestions.length);

      if (props.location.state.data.proposal.proposalType === "map") {
        let cords = props.location.state.data.proposal.mapCoordinates;

        let bounds = {
          ne: {
            lat: cords.north,
            lng: cords.east,
          },
          sw: {
            lat: cords.south,
            lng: cords.west,
          },
        };

        let size = {
          width: window.innerWidth * 0.8, // Map width in pixels
          height: window.innerHeight * 0.8, // Map height in pixels
        };

        if (window.innerWidth < 980) {
          size = {
            width: window.innerWidth, // Map width in pixels
            height: window.innerHeight / 2, // Map height in pixels
          };
        }

        const { center, zoom } = fitBounds(bounds, size);
        setZoom(zoom);
        setLocation(center);
        setMapPath(props.location.state.data.proposal.mapCoordinates);
      }
    }

    return () => {
      __resetSurvey();
    };
  }, []);

  useEffect(() => {
    if ((answersData.isSuccess || answersData.isError) && answer !== null) {
      resetSurvey_answers_action();
      __nextQuestion();
    }
    if (
      (supportAnswerData.isSuccess || supportAnswerData.isError) &&
      supportAnswer !== null
    ) {
      resetSurvey_support_action();
      __nextQuestion();
    }
    if (surveyEndData.isSuccess && surveyEndData.data !== null) {
      window.scroll(0, 0);
      history.push(NavigationConstants.share_your_views.url +"/summary/" + proposalId, {
        data: surveyEndData.data.data,
        token: token,
        supportAnswer: supportAnswer,
        baseProposal: baseProposal
      });
      resetSurvey_end_action();
    }
  }, [answersData, surveyEndData]);

  const __resetSurvey = () => {
    try {
      dispatch(resetSurvey_start_action());
      dispatch(resetSurvey_answers_action());
      dispatch(resetSurvey_support_action());
      dispatch(resetSurvey_end_action());
      setActiveQuestion(0);
      setFinishSurvey(0);
      setSupportModule(0);
    } catch (e) {
      console.log(e);
    }
  };
  const __summary = () => {
    return (
      <ListWrapper prime={true}>
        <div className="p-4 m-2">
          <div className="d-flex flex-mobile-column">
            <div className="flex-grow-1 ">
              <h3 className="title">{baseProposal.proposal.proposalTitle}</h3>
            </div>
            <ShareVia
              url={window.location.origin + '/surveys/' + baseProposal.proposal.proposalSlug}
              title={baseProposal.proposal.proposalTitle}
              white={false}
            />
          </div>

          <div className="d-flex ">
            <div className="">
              <p className="mb-1 post-details">
                <span className="font-weight-bold pr-5 capitalize">
                  {baseProposal.proposal.proposalType}
                </span>
              </p>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: baseProposal.proposal.proposalDesc,
            }}
            className="mt-2"
          />
          <div className="mb-1 mt-2 post-details">
            <p>
              <span className="mt-2">
                <b>Disclaimer</b>: I agree that Kanda may anonymise my data and
                share my responses and/or such data, along with other
                respondents’ anonymised data, in the public domain.
              </span>
            </p>
            {/* I agree that any answers I input into the
              survey will be processed, even where I do not complete the entire
              survey. I agree that Kanda may post and share this information on
              it’s social media sites. */}
          </div>
        </div>
      </ListWrapper>
    );
  };

  const __renderAnswerType = (question, index) => {
    let type = question.questionType;

    switch (type) {
      case "QT01":
        return (
          <QT01
            data={question}
            onSubmit={(res) => {
              let tempAnswer = Object.assign({}, answer);
              tempAnswer.additional_thoughts = res.additional_thoughts;
              setAnswer(tempAnswer);

              if (
                tempAnswer.answer === "" &&
                baseProposal.proposalQuestions[index].answer !== ""
              ) {
                setAnswer(baseProposal.proposalQuestions[index].answer);
              } else {
                let setAns = baseProposal;
                setAns.proposalQuestions[index].answer = res;
                setBaseProposal(setAns);
              }
            }}
          />
        );
        break;
      case "QT02":
        return (
          <QT02
            data={question}
            onSubmit={(res) => {
              let tempAnswer = Object.assign({}, answer);
              tempAnswer.additional_thoughts = res.additional_thoughts;
              setAnswer(tempAnswer);

              if (
                tempAnswer.answer === "" &&
                baseProposal.proposalQuestions[index].answer !== ""
              ) {
                setAnswer(baseProposal.proposalQuestions[index].answer);
              } else {
                let setAns = baseProposal;
                setAns.proposalQuestions[index].answer = tempAnswer;
                setBaseProposal(setAns);
              }
            }}
          />
        );
        break;
      case "QT03":
        return (
          <QT03
            data={question}
            onSubmit={(res) => {
              setAnswer(res);
              if (
                res.answer === "" &&
                baseProposal.proposalQuestions[index].answer !== ""
              ) {
                setAnswer(baseProposal.proposalQuestions[index].answer);

              } else {
                let setAns = baseProposal;
                setAns.proposalQuestions[index].answer = res;
                setBaseProposal(setAns);
              }
            }}
          />
        );
        break;
      case "QT04":
        return (
          <QT04
            data={question}
            onSubmit={(res) => {
              setAnswer(res);

              if (
                res.answer === "" &&
                baseProposal.proposalQuestions[index].answer !== ""
              ) {
                setAnswer(baseProposal.proposalQuestions[index].answer);
              } else {
                let setAns = baseProposal;
                setAns.proposalQuestions[index].answer = res;
                setBaseProposal(setAns);
              }
            }}
            firsttime={question.hasOwnProperty("answer") === true ? 1 : 0}
          />
        );
        break;
      case "QT05":
        return (
          <QT05
            data={question}
            onSubmit={(res) => {
              setAnswer(res);

              if (
                res.answer === "" &&
                baseProposal.proposalQuestions[index].answer !== ""
              ) {
                setAnswer(baseProposal.proposalQuestions[index].answer);
              } else {
                let setAns = baseProposal;
                setAns.proposalQuestions[index].answer = res;
                setBaseProposal(setAns);
              }
            }}
          />
        );
        break;
      case "QT06":
        return (
          <QT06
            data={question}
            onSubmit={(res) => {
              setAnswer(res);
              if (
                res.answer === "" &&
                baseProposal.proposalQuestions[index].answer !== ""
              ) {
                setAnswer(baseProposal.proposalQuestions[index].answer);
              } else {
                let setAns = baseProposal;
                setAns.proposalQuestions[index].answer = res;
                setBaseProposal(setAns);
              }
            }}
          />
        );
        break;
      case "QT07":
        return (
          <QT07
            data={question}
            onSubmit={(res) => {
              setAnswer(res);
              if (
                res.answer === "" &&
                baseProposal.proposalQuestions[index].answer !== ""
              ) {
                setAnswer(baseProposal.proposalQuestions[index].answer);
              } else {
                let setAns = baseProposal;
                setAns.proposalQuestions[index].answer = res;
                setBaseProposal(setAns);
              }
            }}
          />
        );
        break;
      case "QT08":
        return (
          <QT08
            data={question}
            onSubmit={(res) => {
              setAnswer(res);

              let setAns = baseProposal;
              setAns.proposalQuestions[index].answer = res;
              setBaseProposal(setAns);
            }}
          />
        );
        break;
    }
  };

  const __backStep = () => {
    setIsMendatory(false);
    setActiveQuestion(activeQuestion - 1);
  };

  const __backButtonUI = () => {
    return activeQuestion > 1 ? (
      <button
        className="btn btn-outline-primary d-flex greenchevron rotate-left"
        onClick={() => {
          __backStep();
        }}
      >
        <span className="icon mr-2 mt-2"></span> Back
      </button>
    ) : null;
  };

  const __saveSupportAnswer = () => {
    if (supportAnswer !== null) {
      setNextButtonText("Saving...");
      setSupportModule(0);
      try {
        dispatch(survey_support_action(token, { thought: supportAnswer }));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const __saveAnswer = (answer) => {
    if (answer !== null) {
      setNextButtonText("Saving...");
      setIsMendatory(false);
      try {
        dispatch(survey_answers_action(token, answer));
      } catch (e) {
        console.log(e);
      }
    } else if (answer === null) {
      // __nextQuestion();
    }
  };

  const _skipAnswer = (skipAns) => {
    const skip_answer = {
      questionId: skipAns.questionId,
      skip: 1,
    };
    if (skip_answer !== null) {
      // setNextButtonText('Saving...');
      setIsMendatory(false);
      try {
        dispatch(survey_answers_action(token, skip_answer));
      } catch (e) {
        console.log(e);
      }
    } else if (skip_answer === null) {
      // __nextQuestion();
    }
  };

  const __setMapAnswers = (mpAnswer, type) => {
    setAnswer({
      answer: JSON.stringify(mpAnswer),
      questionId: baseProposal.proposalQuestions[activeQuestion - 1].questionId,
      additional_thoughts: answer !== null ? answer.additional_thoughts : "",
    });
    setInteractiveContent({
      answer: JSON.stringify(mpAnswer),
      questionId: baseProposal.proposalQuestions[activeQuestion - 1].questionId,
      additional_thoughts: answer !== null ? answer.additional_thoughts : "",
    });
  };

  const __nextQuestion = () => {
    if (activeQuestion === noOfQustions) {
      setFinishSurvey(1);
    }

    setNextButtonText("Next");
    setAnswer(null);
    setActiveQuestion(activeQuestion + 1);
    setDoneScroll(false);
  };

  const __questions = () => {
    let questions = [];


    _.each(baseProposal.proposalQuestions, (item, id) => {
      let currentId = id + 1;

      if (activeQuestion === currentId) {
        questions.push(
          <div id={id} className={"survey-questions p-3 mb-3 active"} key={id}>
            <div className="questionIndex">{currentId}</div>

            <p className="text-primary font-weight-bold">
              {StringConstants.question} {currentId}/{noOfQustions}
            </p>
            <h6 className="question">{item.questionTitle}</h6>

            {__renderAnswerType(item, id)}

            {isMendatory && (
              <div className="alert alert-danger" role="alert">
                {StringConstants.select_answer}{" "}
              </div>
            )}
            <div className="d-flex justify-content-end">
              {item.questionSkippable && (
                <button
                  className="btn btn-outline-secondary mr-2 d-flex graychevron"
                  onClick={() => {
                    if (currentId === noOfQustions) {
                      setFinishSurvey(1);
                    }
                    setActiveQuestion(currentId + 1);
                    setIsMendatory(false);
                    _skipAnswer(baseProposal.proposalQuestions[id]);
                  }}
                >
                  {StringConstants.skip}{" "}
                  <span className="icon ml-3 mt-2"></span>
                </button>
              )}

              <button
                className="btn btn-outline-primary d-flex greenchevron do-support-btn"
                onClick={() => {
                  if (answer === null || answer === undefined) {
                    if (baseProposal.proposalQuestions[id].hasOwnProperty("answer")) {
                      if (
                        baseProposal.proposalQuestions[id].answer.answer !== ""
                      ) {
                        if (
                          baseProposal.proposalQuestions[id].answer ===
                          undefined
                        ) {
                          setIsMendatory(true);
                        } else {
                          setAnswer(baseProposal.proposalQuestions[id].answer);
                          __saveAnswer(
                            baseProposal.proposalQuestions[id].answer
                          );
                        }
                      } else {
                        setIsMendatory(true);
                      }
                    } else {
                      if (
                        baseProposal.proposalQuestions[id].questionType ===
                        "QT02" ||
                        baseProposal.proposalQuestions[id].questionType ===
                        "QT01"
                      ) {
                        if (interactiveContent !== null) {
                          baseProposal.proposalQuestions[id].answer =
                            interactiveContent;
                          setAnswer(baseProposal.proposalQuestions[id].answer);
                          __saveAnswer(
                            baseProposal.proposalQuestions[id].answer
                          );
                        }
                      } else if (
                        baseProposal.proposalQuestions[id].questionType ===
                        "QT07"
                      ) {
                        baseProposal.proposalQuestions[id].answer = {
                          answer:
                            baseProposal.proposalQuestions[id].options[2]
                              .optionId,
                          additional_thoughts: "",
                          questionId:
                            baseProposal.proposalQuestions[id].questionId,
                        };

                        setAnswer(baseProposal.proposalQuestions[id].answer);
                        __saveAnswer(baseProposal.proposalQuestions[id].answer);
                      } else {
                        setIsMendatory(true);
                      }
                    }
                  } else {
                    __saveAnswer(answer);
                  }


                  if (
                    baseProposal.proposalQuestions.length === activeQuestion
                  ) {
                    setBackButton(false);
                  }
                }}
              >
                {nextButtonText} <span className="icon ml-3 mt-2"></span>
              </button>
            </div>
          </div>
        );
      } else if (currentId < activeQuestion) {
        let classNm =
          currentId === activeQuestion - 1 ? "previous" : "previousNegative1";

        questions.push(
          <div
            id={id}
            className={`survey-questions ${classNm} p-3 mb-3`}
            key={id}
          >
            <div className="questionIndex">{currentId}</div>
            <h6 className="question">{item.questionTitle}</h6>
          </div>
        );
      } else {
        questions.push(
          <div id={id} className={"survey-questions p-3 mb-3"} key={id}>
            <div className="questionIndex">{currentId}</div>
            <h6 className="question">{item.questionTitle}</h6>
          </div>
        );
      }
    });
    return questions;
  };

  const __submitForm = () => {
    try {
      if (!surveyEndData.isFeching || surveyEndData.isFeching === undefined) {
        setDoneButtonText("Saving...");
      }

      if (isTNC) {
        let body = {
          name: fullname,
          email: email,
          postcode: postcode,
          type: "submit",
        };

        dispatch(survey_end_action(token, body));
      } else {
      }
    } catch (exception) {
      console.log(exception);
    }
  };

  const __skipSurveyForm = () => {
    try {
      let body = {
        name: "",
        email: "",
        postcode: "",
        type: "skip",
      };
      dispatch(survey_end_action(token, body));
    } catch (exception) {
      console.log(exception);
    }
  };

  const __userDemoGraphics = () => {
    return (
      <div className="thank-you p-2">
        <h3 className="thankyoutext">
          {StringConstants.user_demo_graphic_heading}
        </h3>
        <p>{StringConstants.user_demo_graphic_decs}</p>
        <form
          type="post"
          onSubmit={(e) => {
            e.preventDefault();

            if (isTNC === null) {
              setIsTNC(false);
            }

            if (
              (fullname !== null || email !== null || postcode !== null) &&
              isTNC === null
            ) {
              setIsTNC(false);
            }

            if (
              (fullname === null || email === null || postcode === null) &&
              isTNC
            ) {
              !surveyEndData.isFeching && __skipSurveyForm();
            }

            if (
              (fullname !== null || email !== null || postcode !== null) &&
              isTNC
            ) {
              !surveyEndData.isFeching && __submitForm();
            }
          }}
        >
          <div className="pt-3">
            <TextInput
              label="Full Name"
              id="fullname"
              type="text"
              onSubmit={(res) => {
                setFullname(res);
              }}
            />

            <TextInput
              label="Post Code"
              id="postcode"
              type="text"
              onSubmit={(res) => {
                setPostcode(res);
              }}
            />

            <TextInput
              label="Email Address"
              id="email"
              type="email"
              onSubmit={(res) => {
                setEmail(res);
              }}
            />

            <p className={isError ? "text-danger" : "d-none"}>
              {StringConstants.all_fields_mandatory}
            </p>

            <div className="tnc container-one">
              <div className="customeRadio-one">
                <input
                  type="checkbox"
                  id="tnc"
                  className="mr-3"
                  onChange={(event) => {
                    setIsTNC(event.target.checked);
                  }}
                />
                <span className="checkmark"></span>
              </div>
              By ticking this box I agree that I have read the{" "}
              <Link to="/privacy-policy" target="_blank">
                {" "}
                {StringConstants.tc}{" "}
              </Link>{" "}
              and consent to the given information being processed and used to
              contact me.
              {/* {StringConstants.survay_agree_tc}  */}
              {/* <Link to="/privacy-policy" target="_blank"> {StringConstants.tc} </Link>  */}
            </div>
            {isTNC !== null && (
              <p className={isTNC === false ? "text-danger" : "d-none"}>
                {StringConstants.survay_tc}
              </p>
            )}
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-outline-secondary mr-2 d-flex graychevron"
                type="reset"
                onClick={() => {
                  !surveyEndData.isFeching && __skipSurveyForm();
                }}
              >
                {StringConstants.skip} <span className="icon ml-3 mt-2"></span>
              </button>
              <button className="btn btn-outline-primary d-flex greenchevron">
                {doneButtonText} <span className="icon ml-3 mt-2"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const __renderCarousel = () => {
    const arr = [];

    _.each(baseProposal.proposal.carouselData, (item, id) => {
      arr.push(
        <div key={id} className="carouselCnt px-4">
          {item.carouselImage !== "" ? (
            <img
              src={item.carouselImage}
              alt=""
              style={{ width: "100%" }}
              className="pb-2"
            />
          ) : null}
          <h4 className="title">{item.carouselTitle}</h4>
          <p className="desc pt-1 mb-0">{item.carouselDesc}</p>
        </div>
      );
    });

    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      initialSlide: 0,
    };
    return (
      <div className="carouselWrapper carouselWrapper-mobile">
        <ListWrapper>
          <div className="py-4 px-0 pb-5 carouselContent">
            <h6 className="pb-2 pl-4">
              {baseProposal.proposal.carouselHeader}
            </h6>

            <Slider {...settings}>{arr}</Slider>
          </div>
        </ListWrapper>
      </div>
    );
  };
  const renderPolylines = (map, maps) => {
    let geodesicPolyline = new maps.Rectangle({
      bounds: mapPath,
      strokeColor: Colors.themeColor,
      strokeOpacity: 0.9,
      strokeWeight: 4,
      fillColor: Colors.themeColor,
      fillOpacity: 0.1,
    });
    geodesicPolyline.setMap(map);
    return geodesicPolyline;
  };

  // const renderOverlay = (map, maps) => {
  //   let overlay = new maps.OverlayView({
  //     bounds: mapPath,
  //     fillColor: Colors.themeColor,
  //     fillOpacity: 0.5,
  //   })

  //   overlay.setMap(map);
  //   return overlay
  // }

  const __renderSurveyType = () => {
    if (baseProposal.proposal.proposalType === "map") {
      return (
        <div className="mediaWrapper overlay">
          <GoogleMapReact
            bootstrapURLKeys={{ key: SocialHandles.googleMap }}
            defaultCenter={location}
            defaultZoom={zoom}
            options={{
              fullscreenControl: false,
              zoomControl: false,
            }}
            onClick={({ x, y, lat, lng, event }) => {
              if (
                (mapPath.south < lat && mapPath.north > lat) === true &&
                (mapPath.west < lng && mapPath.east > lng) === true
              ) {
                if (
                  baseProposal.proposalQuestions[activeQuestion - 1] &&
                  baseProposal.proposalQuestions[activeQuestion - 1]
                    .questionType === "QT01"
                ) {
                  setPointer({
                    lat: lat,
                    lng: lng,
                  });
                  __setMapAnswers({ lat: lat, lng: lng }, "map");
                }
              }
            }}
            onGoogleApiLoaded={({ map, maps }) => {
              renderPolylines(map, maps);
              // renderOverlay(map, maps)
              if (window.innerWidth > 980) {
                map.panBy((window.innerWidth * 0.45) / 2, 0);
              }
            }}
          >
            {pointer && pointer.lat !== 0 && pointer.lng !== 0 ? (
              <div
                lat={pointer.lat}
                lng={pointer.lng}
                // lat={location.lat}
                // lng={location.lng}
                className="mapPointerIcon"
              >
                <img src={Images.mappin} alt="" />
              </div>
            ) : null}
            {/* <Polyline
                            coordinates={props.location.state.data.proposal.mapCoordinates}
                            style="5px solid orange"
                            closed={true}>

                        </Polyline> */}
          </GoogleMapReact>
        </div>
      );
    } else if (baseProposal.proposal.proposalType === "interactive") {
      return (
        <div className="mediaWrapper" ref={imageMapRef}>
          <div
            style={{ width: "1920px", height: "1080px", position: "absolute" }}
          >
            <div
              className="imagePointerIcon"
              style={{ marginTop: pointerXY.y, marginLeft: pointerXY.x }}
            >
              <img src={Images.mappin} alt="" />
            </div>
            <img
              src={baseProposal.proposal.interactiveImage.src}
              alt=""
              onClick={(event) => {
                if (
                  baseProposal.proposalQuestions[activeQuestion - 1] &&
                  baseProposal.proposalQuestions[activeQuestion - 1]
                    .questionType === "QT02"
                ) {
                  let x = "",
                    y = "";

                  x = event.clientX;
                  y = event.clientY - 80;

                  if (imageMapRef.current.scrollLeft !== 0) {
                    x = imageMapRef.current.scrollLeft + event.clientX;
                  }
                  if (imageMapRef.current.scrollTop !== 0) {
                    y = imageMapRef.current.scrollTop + event.clientY - 80;
                  }

                  setPointerXY({ x: x, y: y });
                  __setMapAnswers(
                    {
                      x: x.toString(),
                      y: y.toString(),
                    },
                    "image"
                  );
                }
              }}
            />
          </div>
        </div>
      );
    } else if (
      baseProposal.proposal.proposalType === "survey" &&
      baseProposal.proposal.carouselData.length > 0
    ) {
      return <div className="carouselWrapper-main">{__renderCarousel()}</div>;
    }
  };

  const __hasMedia = () => {
    let className = "survey-container container p-5 pt-4";

    if (baseProposal) {
      switch (baseProposal.proposal.proposalType) {
        case "map":
          className = "hasMedia";
          break;
        case "interactive":
          className = "hasMedia";
          break;
        case "survey":
          if (baseProposal.proposal.carouselData.length > 0) {
            className = "hasMedia hasMedia-mobile";
            //
          }
          break;
      }
    }

    return className;
  };

  const __displayModules = () => {
    if (!finishSurvey) {
      return (
        <div className="pl-5 question-wpr">{baseProposal && __questions()}</div>
      );
    } else if (finishSurvey && supportModule) {
      return (
        <div className=" pb-3">
          <h4 className="pb-3">
            {baseProposal.proposal.proposalPromptSupportQuestion}
          </h4>
          <SupportQuestion
            onSubmit={(res) => {
              setSupportAnswer(res);
            }}
          />

          <div className="d-flex justify-content-end pt-4 btn-support-question-border">
            <button
              className="btn btn-outline-primary d-flex greenchevron do-support-btn"
              onClick={() => {
                __saveSupportAnswer();
              }}
            >
              {nextButtonText} <span className="icon ml-3 mt-2"></span>
            </button>
          </div>
        </div>
      );
    } else {
      return __userDemoGraphics();
    }
  };

  const isMobileView = () => {
    if (isMobile) {
      window.scroll(0, 0);
    }
  };

  return (
    <>
      <div style={{ height: "100vh", width: "100%" }} className="image-map">
        <BackButton basepage="Your Say" />
        {baseProposal && __renderSurveyType()}

        <div className={__hasMedia()}>
          <div className="surveyDetails surveyDetails-mobile">
            {baseProposal && __summary()}

            {baseProposal && (
              <ListWrapper>
                <div className="p-4">
                  {backButton ? __backButtonUI() : null}
                  {baseProposal.proposal.proposalType === "map" && (
                    <div className="pb-2 pl-2 pt-4">
                      <p className="pl-3">
                        <strong>Note:</strong> You can drop a pin anywhere
                        within the highlighted area on the map.
                      </p>
                    </div>
                  )}
                  {__displayModules()}
                </div>
              </ListWrapper>
            )}
          </div>
        </div>
      </div>

      <div className="text-center postion-center">
        <CommonFooter />
      </div>
    </>
  );
};

export default Survey;
