import React, { useState, useEffect, useRef } from "react";
import { Config, Images, StringConstants, ApiConstants } from "../../config";

import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha"


import { useDispatch, useSelector } from "react-redux";

import { email_subscribe_action, keepMeUpdatedModalAction } from "../../stores/actions";
import {
  getDataLocalStorage,
  setDataLocalStorage,
  NOTIFICATIONSID,
} from "../../utils/storage";


const Footer = (props) => {
  const MAX_CHAR = 180;
  const captchaRef = useRef(null)

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [projectData, setProjectData] = useState();
  const [notificationData, setNotificationData] = useState();
  const [showNotification, setShowNotification] = useState(0);

  const [showSupportPrompt, setShowSupportPrompt] = useState(0);
  const [chChecked, setChChecked] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(true);
  const [iAcceptedErrorMessage, setIAcceptedErrorMessage] = useState(false);
  const [showKeepMeUpdatedForm, setShowKeepMeUpdatedForm] = useState(false);
  const [supportMobalisationData, setSupportMobalisationData] = useState({
    title: "Do you support this project?",
    description:
      "Take this short survey to help us gauge your opinion on the project.",
  });

  // const surveyStartData = useSelector(state => state.support_this_project);
  const subscribeEmailData = useSelector((state) => state.email_subscribe);
  const projectConfigData = useSelector((state) => state.projectconfig);
  const keepMeUpdatedModal = useSelector((state) => state.support_this_project.keepMeUpdatedModal);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      projectConfigData.data !== null &&
      projectConfigData.isSuccess === true
    ) {
      if (projectConfigData.data.data.support_mobilisation) {
        setSupportMobalisationData(
          projectConfigData.data.data?.support_mobilisation
        );
      }

      setProjectData(projectConfigData.data.data);
    }
  }, [projectConfigData]);

  // render notification
  useEffect(() => {
    if (
      projectConfigData.data !== null &&
      projectConfigData.isSuccess === true &&
      projectConfigData.data.data.announcementData !== ""
    ) {
      getDataLocalStorage(NOTIFICATIONSID).then((res) => {
        if (projectConfigData.data.data.announcementData.id !== res) {
          setNotificationData(projectConfigData.data.data.announcementData);
          setShowNotification(1);
        }
      });
    }
  }, [projectConfigData]);

  useEffect(() => {
    setShowKeepMeUpdatedForm(keepMeUpdatedModal)
  }, [keepMeUpdatedModal])

  const checkChange = () => {
    setChChecked(!chChecked);
  };

  const __submitForm = () => {
    try {
      if (email !== null && chChecked) {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();

        dispatch(
          email_subscribe_action({
            email: email,
            firstname: firstName,
            lastname: lastName,
            theme: ApiConstants.PRIMARY_COLOR,
            "g-recaptcha-response": token
          })
        );
      }

      if (chChecked) {
        setIAcceptedErrorMessage(false);
      } else {
        setIAcceptedErrorMessage(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const __toggleSubscriptionModal = () => {
    dispatch(keepMeUpdatedModalAction({ status: !showKeepMeUpdatedForm }))
  };

  const __renderEmailSubscription = () => {
    return (
      <Modal show={showKeepMeUpdatedForm} className={"notification-modal"}>
        <Modal.Header>
          <h5 className="modal-title" id="subscribeEmailLabel">
            Keep me updated
          </h5>
          <button
            type="button"
            className="close"
            onClick={() => __toggleSubscriptionModal()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            !subscribeEmailData.isFeching && __submitForm();
          }}
        >
          <Modal.Body className={"keepmeupdated keepmeupdated-mobile"}>
            <p>For news and updates on the project from the project team please complete the form below.</p>

            <div className="form-group">
              <label>First name</label>
              <input
                type="text"
                className="form-control"
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <label>Last name</label>
              <input
                type="text"
                className="form-control"
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <label>Email *</label>
              <input
                type="email"
                className="form-control"
                required 
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </div>

            <div className="form-group pr-10">
              <label>
                <div className="container-one">
                  <div className="customeRadio-one">
                    <input
                      type="checkbox"
                      className="mr-3"
                      checked={chChecked}
                      onChange={checkChange}
                    />
                    <span className="checkmark"></span>
                  </div>
                  I have read and agree to <Link to="/privacy-policy" target="_blank">Privacy</Link> &amp; <Link to="/cookies" target="_blank">Cookie Policy</Link>
                </div>
              </label>
            </div>

            {!chChecked && iAcceptedErrorMessage && (
              <>
                <div className="alert alert-danger" role="alert">
                  Please accept the Privacy &amp; Cookie Policy
                </div>
              </>
            )}
            
            <ReCAPTCHA 
              sitekey={ApiConstants.GOOGLE_RECAPTCH_SITE_KEY} 
              ref={captchaRef}
              onChange={(e) => {
                if(e.length > 0) {
                  setIsCaptchaVerified(false)
                }
              }}
              />

            {!subscribeEmailData.isFeching && subscribeEmailData.isSuccess && (
              <>
                <div className="alert alert-success  mt-3" role="alert">
                  {subscribeEmailData.data.message}
                </div>
              </>
            )}

            {!subscribeEmailData.isFeching && subscribeEmailData.isError && (
              <>
                <div className="alert alert-danger mt-3" role="alert">
                  {subscribeEmailData.data.message}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => __toggleSubscriptionModal()}
            >Close</button>

            {subscribeEmailData.isFetching ? (
              <button type="button" disabled className="btn btn-primary" >
                Saving...
              </button>
            ) : (
                <button type="submit" className="btn btn-primary" disabled={isCaptchaVerified}>
                Submit
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    );
  };

  const toggleNotifications = () => {
    setShowNotification(!showNotification);
    setDataLocalStorage(
      NOTIFICATIONSID,
      projectConfigData.data.data.announcementData.id
    );
  };

  const __renderNotification = () => {
    return (
      <Modal
        show={showNotification}
        className={"notification-modal"}
        centered={true}
      >
        <Modal.Body className="p-0">
          <button
            type="button"
            className="btn close"
            onClick={() => toggleNotifications()}
          >
            <img
              src={
                notificationData.announcementImage === ""
                  ? Images.cross_black
                  : Images.cross_white
              }
              className="pr-1"
              style={{ width: 18 }}
            />
          </button>

          <div className="d-flex flex-column flex-sm-column flex-md-row">
            <div className="text-center order-1 order-md-0 flex-grow-1">
              <h3 className="title text-center pt-5 px-5">
                {notificationData.title}
              </h3>

              <div
                className="py-3 px-5 project-details-cnt pad-one text-center"
                dangerouslySetInnerHTML={{ __html: notificationData.body }}
              ></div>

              {notificationData.additionalCopy !== "" && (
                <div className="py-3 px-5 project-details-cnt pad-one text-center additional-copy">
                  {notificationData.additionalCopy}
                </div>
              )}

              <div className="py-4 px-3 mb-4">
                <button
                  className="btn btn-lg btn-outline-primary btn-close greenchevron"
                  onClick={() => toggleNotifications()}
                >
                  <div className="d-flex justify-content-between  align-items-center">
                    Okay <span className="icon ml-3"></span>
                  </div>
                </button>
              </div>
            </div>
            {notificationData.announcementImage !== "" && (
              <div
                className="flex-shrink-1 order-0  order-md-1"
                style={{
                  background: `url('${notificationData.announcementImage}') no-repeat`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <img
                  src={Images.spacer}
                  className="support-mobilisation-image"
                />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <footer className="footer font-small ">
        <div className="d-sm-flex">
          <div className="flex-grow-1">
            <button
              className="btn btn-primary keep-me-updated"
              onClick={() => __toggleSubscriptionModal()}
            >
              <img src={Images.mailinglist} alt="" className="mr-2" /> Keep me
              updated
            </button>
          </div>
          {projectData &&
            projectData.projectConfig.projectDevelopmentSupportEnabled ===
            true && (
              <div>
                <div className="support-wpr">
                  <div
                    className="supportPrompt p-3"
                    style={{ display: showSupportPrompt ? "block" : "none" }}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="flex-grow-1">
                        <h6 className="title">
                          {" "}
                          {supportMobalisationData.title}{" "}
                        </h6>
                      </div>
                      <div>
                        <button
                          className="btn btn-default btn-sm"
                          onClick={() => {
                            setShowSupportPrompt(!showSupportPrompt);
                          }}
                        >
                          <span className="text-muted">x</span>
                        </button>
                      </div>
                    </div>

                    <p className="description mb-0">
                    {supportMobalisationData.description.substring(0, MAX_CHAR)}
                    {supportMobalisationData.description.length > MAX_CHAR && "..."}
                    </p>

                  </div>

                  <button
                    className="btn btn-default support-developement"
                    onClick={() => {
                      setShowSupportPrompt(!showSupportPrompt);
                    }}
                    style={{ display: showSupportPrompt ? "none" : "block" }}
                  >
                    <img src={Images.greensurveyicon} alt="" className="mr-3" />
                    {supportMobalisationData.title}
                  </button>
                  <Link
                    className="btn btn-primary btn-block start-support"
                    to={"/support-survey"}
                    onClick={() => {
                      setShowSupportPrompt(!showSupportPrompt);
                    }}
                    style={{ display: !showSupportPrompt ? "none" : "block" }}
                  >
                    {StringConstants.get_started}
                    <img src={Images.whitechevron} alt="" className="ml-2" />
                  </Link>
                </div>
              </div>
            )}
        </div>
      </footer>

      {__renderEmailSubscription()}
      {notificationData && __renderNotification()}
    </>
  );
};

export default Footer;
