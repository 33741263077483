import React from "react";
import { Link } from "react-router-dom";
import * as Animi from "./Animations";
import { StringConstants, Images } from "../config";

const SupportMobalisationList = (props) => {
  const data = props.data;
  return (
    <div className="pb-2 pt-0">
      <Animi.FadeInAnimationDivSMDiv key={"support_mobilisation"}>
        <div className="card mt-2 mb-4 kanda-card kanda-card-mobile">
          <div className="d-md-flex listing card-listingHeight">
            <div>
              <Animi.ZoomInOutAnimationImage>
                <div
                  className="proposal-img-large"
                  style={{
                    backgroundImage:
                      data.image !== ""
                        ? `url(${data.image})`
                        : `url('https://s3.eu-west-2.amazonaws.com/kanda.assets/SideImage.jpg')`,
                  }}
                />
              </Animi.ZoomInOutAnimationImage>
            </div>

            <div className="flex-grow-1 d-flex flex-column">
              <div className="p-4 flex-grow-1">
                <div className="new-content  flex-grow-1">
                  <h4 className="title mb-0">{data.title}</h4>
                  <p className="mb-0">{data.description}</p>
                </div>
              </div>
              <div
                className={"d-lg-flex listing"}
                style={{ borderTop: "1px solid #DFDFDF" }}
              ></div>
              <div>
                <Link
                  className="btn btn-primary btn-block py-2 px-5 d-flex justify-content-between praposal-hsb-mobile"
                  to={{
                    pathname: "/support-survey/",
                  }}
                >
                  {StringConstants.get_started}{" "}
                  <img src={Images.whitechevron} alt="" className="pl-4" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Animi.FadeInAnimationDivSMDiv>
    </div>
  );
};

export default SupportMobalisationList;
