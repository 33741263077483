import React, { useState, useEffect } from 'react';

import ReactSlider from 'react-slider';
import { QTADT } from './index';

const QT07 = props => {
    const [answers, setAnswers] = useState('');
    const [answersID, setAnswersID] = useState('');
    const [adtData, setAdtData] = useState('');

    const copy = props.data.options;
    var page = props.page;

    //updated by veena
    let all_copy = props.data.options;

    let default_val = 2;
    if (props.data.hasOwnProperty('answer'))
        if (props.data.answer.answer !== '') {
            if (all_copy.length) {
                for (var i = 0; i < all_copy.length; i++) {
                    if (all_copy[i].optionId === props.data.answer.answer) {
                        default_val = i;
                    }
                }
            }
        }

    useEffect(() => {
        if (answers !== '') {
            props.onSubmit({ "answer": answersID, "questionId": props.data.questionId, "additional_thoughts": adtData });
        }
    }, [answers, adtData])

    useEffect(() => {
        setTimeout(() => {
            if (page === 1) {
                setAnswersID(copy[2].optionId);
                __setSelectedAnswer(copy[2].optionId, copy[2].optionId)
            }
        }, 1000)
    }, []);

    const __setSelectedAnswer = (res, id, name) => {
        setAnswers(id);
        setAnswersID(res);
    }

    return (<div key={props.questionId} className="pb-2 sliderWrapper">
        <div className="">
            <div className={'sliderBackgrounds'}>
                <ReactSlider
                    className="slider-question"
                    marks={[0, 1, 2, 3, 4]}
                    markClassName="options"
                    min={0}
                    max={4}
                    defaultValue={default_val}
                    thumbClassName="slide-selector"
                    trackClassName="slide-track"
                    pearling={true}
                    renderThumb={(props, state) => {
                        return <div {...props}>
                            <div className={"selected ans" + state.valueNow}>
                                <p className="copyText">{copy[state.valueNow].option}</p>
                            </div>
                        </div>
                    }}
                    onChange={(id) => {
                        __setSelectedAnswer(copy[id].optionId, id, copy[id].option)
                    }}

                />
            </div>
            <div className="d-flex justify-content-between hint-text pt-2">
                <p className="text-left">Strongly<br />Disagree</p>
                <p className="text-right">Strongly<br />Agree</p>
            </div>
        </div>

        <QTADT adtEnabled={props.data.questionAdditionalThoughtsEnabled} questionId={props.data.questionId} onSubmit={(res) => {
            setAdtData(res);
        }} additional_thoughts={props.data.hasOwnProperty('answer') ? props.data.answer.additional_thoughts : null} />
    </div>);
};

const style = {

}



export default QT07;