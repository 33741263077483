import React from 'react';
import { Images, StringConstants } from '../config';
const NoResults = props => {
  return (
    <div className="k-contact">
      
      {   
         <p>{props.isSurvey ? StringConstants.no_survey_result_found : StringConstants.no_result_found}</p>
      }

      
      {

        process.env.REACT_APP_EMAIL !== '' &&
        <>
          <div className="d-flex kanda-contact align-items-center pb-3">
            <div className="survey-icon mr-3 text-center">
              <img src={Images.Cemail} alt="" className="icon-circle" />
            </div>
            <div className="flex-grow-1">
              <p className="pb-0 pt-0 mb-0 d-flex project-info-text" > <b>{StringConstants.contact_Email} </b> </p>
              <a href={"mailto:" + process.env.REACT_APP_EMAIL}><span>{process.env.REACT_APP_EMAIL} </span></a>
            </div>
          </div>
        </>
  
      }

      {
        process.env.REACT_APP_MOBILE !== '' &&
        <>
          <div className="d-flex kanda-contact align-items-center pb-3">
            <div className="survey-icon mr-3 text-center" >
              <img src={Images.Cphone} alt="" className="icon-circle" />
            </div>
            <div className="flex-grow-1">
              <p className="pb-0 pt-0 mb-0 d-flex project-info-text" > <b>{StringConstants.contact_phone}</b> </p>
              <span>{process.env.REACT_APP_MOBILE}</span>
            </div>
          </div>
        </>
        
      }
    </div>
  );
};

export default NoResults;