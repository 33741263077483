import * as types from "../types/index";

export const showProjectSupport = () => {
    return {
        type: types.PROJECT_SUPPORT_SHOW,
        payload: null
    }
}

export const hideProjectSupport = () => {
    return {
        type: types.PROJECT_SUPPORT_HIDE,
        payload: null
    }
}


export const keepMeUpdatedModalAction = (value) => {
    return {
        type: types.KEEP_ME_UPDATED,
        payload: value
    }
}